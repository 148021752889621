import React, { Suspense, lazy, useContext, useEffect, useLayoutEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MobileHeader from "./components/headers/MobileHeader";
import MenuLeft from "./components/headers/MenuLeft";
import HeaderTop from "./components/headers/HeaderTop";
import NotFound from "./views/NotFound";
// import Home from "./views/Home";
// import Contact from "./views/Contact";
import Flux from "./views/Flux";
import Consultants from "./views/Consultant";
import Consultant from "./views/consultant/Startup";
import Pilote from "./views/pilote/Startup";
import Manager from "./views/Manager";
import MyStartup from "./views/MyStartup";
import Startups from "./views/Startup";
import Profile from "./views/reglages/Profile";
import MyMissions from "./views/MyMissions";
import MyBudget from "./views/MyBudget";
import Utilisateur from "./views/reglages/Utilisateur";
import Interface from "./views/reglages/Interface";
import Billing from "./views/reglages/Billing";
// import Category from "./views/reglages/Category";
import FollowUP from "./views/outils/FollowUp";
import NewFollowUp from "./views/outils/NewFollowUp";
// import Programme from "./views/reglages/Programme";
// import NewProject from "./views/projet/NewProject";
// import JoinProject from "./views/projet/JoinProject";
import CreatePrograme from "./components/project/Create";
import {UserContext, ProgrammeContext, DeviseContext} from './contexts/UserContext';
import Dashboard from "./modules/Dashboard/Dashboard";
import Export from "./modules/Export/ExportPage";
// import { Programme } from "./modules/Auth";
import { AuthPage } from "./modules/Auth";
// import { MyBusiness } from "./modules/Startup/pages";
// import StartupPage from "./modules/Startup/StartupPage";
// import ProjetPage from "./modules/Projet/ProjetPage";

const ProjetPage = lazy(() =>
  import("./modules/Projet/ProjetPage")
);

const UserPage = lazy(() =>
  import("./modules/User/UserPage")
);

const CategoriePage = lazy(() =>
  import("./modules/Categorie/CategoriePage")
);

const StartupPage = lazy(() =>
  import("./modules/Startup/StartupPage")
);

export function UiProvider({children, programData}) {
  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = "/assets/js/scripts.js";
    const timerId = setTimeout(() => {
      document.body.appendChild(script);
    }, 1000);
    return () => clearTimeout(timerId);
  }, []);
  return (
    <ProgrammeContext.Provider value={programData}>
      {children}
    </ProgrammeContext.Provider>
  )
}

export default function BasePage() {
  const [user, , isAuthenticated] = useContext(UserContext);
  // const {user, setUser, isAuthenticated} = useContext(AuthContext)
  const [projet, setProjet] = useState(null);
  const [hasProgramme, setHasProgramme] = useState(false)
  const [devise, setDevise] = useState(null);
  const [loading, setLoading] = useState(true)
  // const axiosInstance = useAxiosInterceptors()


  // useEffect(() => {
  //   // const findUser = async () => {
  //   //   try {
  //   //     const res = await axiosInstance.get(`profile`)
  //   //     if (res) {
  //   //       setUser(res?.data)
  //   //     }
  //   //   } catch (error) {
  //   //     return;
  //   //   }
  //   // }
  //   if(isAuthenticated && token) {
  //     setUser(user)
  //   }else {
  //     localStorage.removeItem('tokens');
  //     localStorage.clear();
  //   }
  // }, [isAuthenticated, token])

  useLayoutEffect(() => {
    if (user) {
      const {programmes} = user;
      setHasProgramme(programmes && programmes.length > 0)
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    console.log("!loading && isAuthenticated && !hasProgramme ", {loading, isAuthenticated , hasProgramme});
  },  [hasProgramme, isAuthenticated, loading])

  // if(loading) {
  //   return <Loader />
  // }

  if(!isAuthenticated) {
    return <Redirect to="/login" />
  }

  if(!loading && isAuthenticated && !hasProgramme) {
    return (
      <UiProvider programData={[projet, setProjet]}>
        <AuthPage hasNoprogram={isAuthenticated && !hasProgramme}/>
      </UiProvider>
    )
  }

  return (
    <UiProvider programData={[projet, setProjet]}>
      <DeviseContext.Provider value={[devise, setDevise]}>
        <div className="container-fluid">
          <div className="row">

            {/* MOBILE HEADER */}
            <MobileHeader />
            {/* LEFT SIDE */}
            <MenuLeft />
            {/* END LEFT SIDE */}

            {/* CONTENT SIDE */}
            <div className="Content-Side">
              <HeaderTop />
              <Suspense fallback={<div></div>}>
                <Switch>
                  <Route exact path={["/", "/dashboard"]} component={Dashboard} />
                  {/* <Route path="/contact" component={Home} /> */}
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/flux" component={Flux} />
                  <Route path="/startups" component={Startups} />
                  <Route path="/consultants" component={Consultants} />
                  <Route path="/consultant/:id" component={Consultant} />
                  <Route path="/managers" component={Manager} />
                  <Route path="/bailleur/:id" component={Pilote} />
                  <Route path="/my-startup" component={MyStartup} />
                  <Route path="/my-missions" component={MyMissions} />
                  <Route path="/my-budget" component={MyBudget} />
                  <Route path="/interfaces" component={Interface} />
                  <Route path="/billing" component={Billing} />
                  <Route path="/categories" component={CategoriePage} />
                  <Route path="/follow-up" component={FollowUP} />
                  <Route path="/new-follow-up" component={NewFollowUp} />
                  <Route path="/exporter" component={Export} />
                  <Route path="/profile" component={Profile} />
                  <Route path="/utilisateurs" component={Utilisateur} />
                  <Route path="/program" component={ProjetPage} />
                  <Route path="/user" component={UserPage} />
                  <Route path="/startup" component={StartupPage} />
                  <Route path="/create-program" component={CreatePrograme} />
                  <Route component={NotFound} />
                </Switch>
              </Suspense>
            </div>
            {/* END CONTENT SIDE */}

          </div>
        </div>
      </DeviseContext.Provider>
    </UiProvider>
    // <UserContext.Provider value={[user, setUser]}>
    //   <ProgrammeContext.Provider value={[projet, setProjet]}>
    //     <DeviseContext.Provider value={[devise, setDevise]}>
    //       <div className="container-fluid">
    //         <div className="row">

    //           {/* MOBILE HEADER */}
    //           <MobileHeader />
    //           {/* LEFT SIDE */}
    //           <MenuLeft />
    //           {/* END LEFT SIDE */}

    //           {/* CONTENT SIDE */}
    //           <div className="Content-Side">
    //             <HeaderTop />
    //             <Suspense fallback={<div></div>}>
    //               <Switch>
    //                 <Route exact path={["/", "/dashboard"]} component={Dashboard} />
    //                 {/* <Route path="/contact" component={Home} /> */}
    //                 <Route path="/dashboard" component={Dashboard} />
    //                 <Route path="/flux" component={Flux} />
    //                 <Route path="/startups" component={Startups} />
    //                 <Route path="/consultants" component={Consultants} />
    //                 <Route path="/consultant/:id" component={Consultant} />
    //                 <Route path="/managers" component={Manager} />
    //                 <Route path="/bailleur/:id" component={Pilote} />
    //                 <Route path="/my-startup" component={MyStartup} />
    //                 <Route path="/my-missions" component={MyMissions} />
    //                 <Route path="/my-budget" component={MyBudget} />
    //                 <Route path="/interfaces" component={Interface} />
    //                 <Route path="/billing" component={Billing} />
    //                 <Route path="/categories" component={CategoriePage} />
    //                 <Route path="/follow-up" component={FollowUP} />
    //                 <Route path="/new-follow-up" component={NewFollowUp} />
    //                 <Route path="/exporter" component={Export} />
    //                 <Route path="/profile" component={Profile} />
    //                 <Route path="/utilisateurs" component={Utilisateur} />
    //                 <Route path="/program" component={ProjetPage} />
    //                 <Route path="/user" component={UserPage} />
    //                 <Route path="/startup" component={StartupPage} />
    //                 <Route path="/create-program" component={CreatePrograme} />
    //                 <Route component={NotFound} />
    //               </Switch>
    //             </Suspense>
    //           </div>
    //           {/* END CONTENT SIDE */}

    //         </div>
    //       </div>
    //       <div className="modal fade" id="modal-programme" tabIndex="-1" role="dialog" aria-labelledby="modal-programme" aria-hidden="true">
    //         <div className="DigiReprting-Modal modal-dialog modal-dialog-centered" role="document">
    //           <div className="modal-content">

    //             <div className="modal-body">
    //               <div className="SettingCol-Header">
    //                 <h4 className="SettingCol-HeaderTitle">Nouveau Éléments</h4>
    //                 <button type="button" className="close" data-dismiss="modal" aria-label="Close">
    //                   <i className="uil uil-times"></i>
    //                 </button>
    //               </div>
    //               <form onSubmit={handleSubmit}>
    //                 <div className="SettingCol-Row">
                    
    //                     <div className="Settings-Field Digi-Col-12">
    //                       <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
    //                         <input type="text" className="FieldText" placeholder="Nom" name="Nom" onChange={handleChange}/>
    //                       </div>
    //                     </div>
    //                     <div className="Settings-Field Digi-Col-12">
    //                       <div className="Settings-FieldRow Settings-FieldRow_Select LightBG">
    //                         <select className="FieldRow-Select" defaultValue={"DEFAULT"} name="" onChange={handleChange}>
    //                           <option value="DEFAULT">Type</option>
    //                           <option value="Produit">Produit</option>
    //                           <option value="Service">Service</option>
    //                         </select>
    //                       </div>
    //                     </div>
    //                     <div className="Settings-Field Digi-Col-6">
    //                       <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
    //                         <input type="text" className="FieldText" placeholder="Prix d’achat" name="periode" onChange={handleChange}/>
    //                       </div>
    //                     </div>
    //                     <div className="Settings-Field Digi-Col-6">
    //                       <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
    //                         <input type="text" className="FieldText" placeholder="Prix de vente" name="periode" onChange={handleChange}/>
    //                       </div>
    //                     </div>
    //                   </div>          
    //                 </form>
    //             </div>
    //             <div className="modal-footer">
    //               <div className="ButtonCancel"><button type="button" className="Cancel-BTN" data-dismiss="modal">Annuler</button></div>
    //               <div className="ButtonSave"><button type="submit" className="Save-BTN">Enregistrer</button></div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </DeviseContext.Provider>
    //   </ProgrammeContext.Provider>
    // </UserContext.Provider>
  );
}
