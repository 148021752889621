import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class NewFollowUp extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      user: {
        id: "1",
        nom: "Houdane Consulting",
        couverture: "Houdane_Consulting-Preview.jpg",
        secteur: "Education",
        ca: "300 000$",
        status: true,
        annee: "2010"
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('Le nom a été soumis : ' + this.state.value);
    event.preventDefault();
  }

  render () {
    return (
      <>
        <div className="Settings-Container">
             <div className="row">
               <div className="SettingsRow">
                 <div className="SettingsContent">
                   <form id="FollowUp-Form" className="FollowUp-Form" onSubmit={this.handleSubmit}>
                     <div className="PageHeader">
                       <div className="row">
                         <div className="col-md-4">
                           <h3 className="SettingsContent-Title">Nouveau Follow Up</h3>
                         </div>
                         <div className="col-md-8">
                            <div className="FilterForm">

                              <div className="FilterRow">
                                <div className="Input-Row Input-Select">
                                  <select className="SelectFilter" name="" defaultValue={"DEFAULT"}>
                                    <option vlaue="DEFAULT" disabled>Status</option>
                                    <option vlaue="">Publier</option>
                                    <option vlaue="">Brouillon</option>
                                    <option vlaue="">Annuler</option>
                                  </select>
                                </div>

                                <div className="Input-Row">
                                  <button type="button" className="Done-BTN" name="button"> Valider</button>
                                </div>

                              </div>
                            </div>
                          </div>
                       </div>

                     </div>
                     <div className="SettingsCols-Wrap">
                       <div className="SettingsCols-Row">
                         <div className="SettingCol-8">
                           <div className="Settings-Field Settings-Field-1">
                             <CKEditor
                                editor={ ClassicEditor }
                                data='<p>Bonjour ...</p>'
                                onReady={ editor => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log( 'Editor is ready to use!', editor );
                                } }
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    console.log( { event, editor, data } );
                                } }
                                onBlur={ ( event, editor ) => {
                                    console.log( 'Blur.', editor );
                                } }
                                onFocus={ ( event, editor ) => {
                                    console.log( 'Focus.', editor );
                                } }
                              />
                           </div>
                           <div className="Settings-Field Settings-Field-1">
                             <div className="Settings-FieldRow Settings-FieldRow_Text Settings-FieldRow_Tags WhiteBG">
                               <input type="text" className="FieldText FieldTags" placeholder="Choisir l’utilisateur" name="periode" onChange={this.handleChange} value=""/>
                             </div>
                           </div>
                           <div className="Settings-Field Settings-Field-1">
                             <div className="Settings-FieldRow Settings-FieldRow_Text Settings-FieldRow_Tags WhiteBG">
                               <input type="text" className="FieldText FieldTags" placeholder="Choisir l’utilisateur" name="periode" onChange={this.handleChange} value=""/>
                             </div>
                           </div>
                         </div>
                         <div className="SettingCol-4 MarginTop-15">
                           <div className="Settings-Field Settings-Field-1 Col-Padding_15 WhiteBG">

                             <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                               <input type="text" className="FieldText" placeholder="Entête" name="entete" onChange={this.handleChange} value=""/>
                             </div>

                             <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                               <input type="text" className="FieldText" placeholder="Sujet" name="entete" onChange={this.handleChange} value=""/>
                             </div>

                             <div id="DatePicker" className="Settings-FieldRow Settings-FieldRow_Text Settings-FieldRow_Date LightBG date">
                               <input type="text" className="FieldText" placeholder="Période" name="periode" onChange={this.handleChange} value=""/>
                               <span className="input-group-addon"><i className="uil uil-calendar-alt"></i></span>
                             </div>

                             <div className="Settings-FieldRow Settings-FieldRow_Select LightBG">
                               <select className="FieldRow-Select" name="" defaultValue={"DEFAULT"}>
                                 <option value="DEFAULT" disabled>Type</option>
                                 <option value="Type 1">Type 1</option>
                                 <option value="Type 2">Type 2</option>
                               </select>
                             </div>

                             <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                               <input type="text" className="FieldText" placeholder="Destinataire" name="entete" onChange={this.handleChange} value=""/>
                             </div>

                             <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                               <input type="text" className="FieldText" placeholder="Signature" name="entete" onChange={this.handleChange} value=""/>
                             </div>

                           </div>
                         </div>
                      </div>
                     </div>
                   </form>
                 </div>
               </div>
             </div>
           </div>
      </>
    )
  }
}

export default NewFollowUp;