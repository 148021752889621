import React from "react";
class Interface extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      user: {
        id: "1",
        nom: "Houdane Consulting",
        couverture: "Houdane_Consulting-Preview.jpg",
        secteur: "Education",
        ca: "300 000$",
        status: true,
        annee: "2010"
      }
    };
  }

  componentDidMount(){

  }

  render () {
    return (
      <>
        <div className="Settings-Container">
             <div className="row">
               <div className="SettingsRow">

                 <div className="SettingsContent">
                   <div className="PageHeader">
                     <h3 className="SettingsContent-Title">Facturation</h3>
                   </div>

                   <div className="SettingsCols-Wrap">
                     <div className="SettingsCols-Row">
                       <div className="SettingCol-4">
                         <div className="BillingItem NextBilling">
                           <label className="BillingItem-Label">Prochaine facture</label>
                           <div className="BillingItem-Value">$300</div>
                           <span className="BillingItem-Info">10.02.2020</span>
                           <div className="BillingItem-Actions">
                             <button type="button" name="button" className="BillingItem-Action UpdateAction">Modifier</button>
                           </div>
                         </div>
                       </div>
                       <div className="SettingCol-4">
                         <div className="BillingItem BillingHistory">
                           <label className="BillingItem-Label">Historique</label>
                           <div className="BillingItem-Value">$4 000</div>
                           <span className="BillingItem-Info">15 paiements</span>
                           <div className="BillingItem-Actions">
                             <button type="button" name="button" className="BillingItem-Action UpdateAction">Modifier</button>
                           </div>
                         </div>
                       </div>
                       <div className="SettingCol-4">
                         <div className="BillingItem PackBox">
                           <label className="BillingItem-Label">Forfait</label>
                           <div className="BillingItem-Value">$300<span>/mois</span></div>
                           <span className="BillingItem-Info">10 Utilisateurs maxi</span>
                           <div className="BillingItem-Actions">
                             <button type="button" name="button" className="BillingItem-Action UpdateAction">Modifier</button>
                           </div>
                         </div>
                       </div>
                       <div className="SettingCol-8">
                         <div className="BillingItem BillingMethod">
                           <label className="BillingItem-Label">Moyens de paiement</label>
                           <div className="BillingItem-Value">2432……………….18</div>
                           <div className="BillingItem-Actions">
                             <button type="button" name="button" className="BillingItem-Action CardTypeAction">Visa</button>
                             <button type="button" name="button" className="BillingItem-Action EditCardAction">Modifier</button>
                             <button type="button" name="button" className="BillingItem-Action AddCardAction">Ajouter</button>
                           </div>
                         </div>
                       </div>
                       <div className="SettingCol-4">
                         <div className="BillingItem CurrencyBox">
                           <label className="BillingItem-Label">Devise</label>
                           <div className="BillingItem-Value">$</div>
                           <span className="BillingItem-Info">Dollar Américain</span>
                           <div className="BillingItem-Actions">
                             <button type="button" name="button" className="BillingItem-Action UpdateAction">Modifier</button>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>

                 </div>
               </div>
             </div>
           </div>
      </>
    )
  }
}

export default Interface;