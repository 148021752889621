import React from "react";

class MobileHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {value: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    console.log('Le nom a été soumis : ' + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <div className="Mobile-Header">
        {/* LEFT SIDE */}
        <div className="LeftSide">
          <div className="ToggleMenu">
            <button type="button" className="ToggleMenu-BTN"><i className="uil uil-subject"></i></button>
          </div>
        </div>

        {/* CENTER SIDE */}
        <div className="CenterSide">
          <div className="DigiReporing-BrandResponsive">
            <a href="dashboard.html"><img src="assets/media/brand/DigiReporting-Brand.svg" alt=""/></a>
          </div>
        </div>

        {/* RIGHT SIDE */}
        <div className="RightSide">
          <div className="ToggleUser">
            <button type="button" className="ToggleUser-BTN"><div className="UserBox-Thumb"><img src="assets/media/images/abbass-iya-profile.jpg" alt="Abbass IYA" /></div></button>
          </div>
          <div className="ToggleAlerts">
            <button type="button" className="ToggleAlerts-BTN"><i className="uil uil-bell"></i></button>
          </div>
          <div className="CloseToggleAlerts">
            <button type="button" className="CloseToggleAlerts-BTN"><i className="uil uil-times"></i></button>
          </div>
        </div>

        {/* <div className="Search-Box">
          <form className="Search-Form" method="post">
            <input type="search" name="" value="" placeholder="Search..."/>
            <button type="submit" name="button"><i className="uil uil-search"></i></button>
          </form>
        </div> */}
      </div>
    )
  }
}

export default MobileHeader;