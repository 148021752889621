/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useContext } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
import BasePage from "./BasePage";
import { AuthPage } from "./modules/Auth";
// import { Logout, AuthPage } from "./modules/Auth";
import NotFound from "./components/NotFound";
import { UserContext } from "./contexts/UserContext";
// import { ROLES, USER_ROLE } from "./modules/components/utils/contantHelpers";
// import {UserContext} from './contexts/UserContext';
// import http from "./httpConfig";

export function Routes () {
  // const [user, setUser] = useState("");
  const [, , isAuthenticated] = useContext(UserContext);
  // const { isAuthenticated, setIsAuthenticated} = useContext(AuthContext)
  // const token = JSON.parse(localStorage.getItem("tokens"))?.accessToken;

  // useEffect(() => {
  //   // const fetchData = async () => {
  //   //   try {
  //   //     axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
  //   //     const res = axiosInstance.get(`isAuthenticated`);
  //   //     if (res) {
  //   //       setIsAuthenticated(res?.data);
  //   //       setLoading(false);
  //   //       console.log(`authenticated => `, isAuthenticated);
  //   //     }
  //   //   } catch (error) {
  //   //     return;
  //   //   }
  //   // };
  //   // async function fetchData() {
  //   //   const response =  checkAuth();
  //   //   const data = await response?.data;
  //   //   setIsAuthenticated(data || false);
  //   //   setLoading(false);
  //   //   // console.log('isAuthenticated', data || false);
  //   // }
  //   if (token) {
  //     console.log(token);
  //     // setIsAuthenticated(!!token)
  //     // setLoading(false);
  //     // fetchData();
  //     setLoading(false)
  //     console.log('isAuthenticated ', isAuthenticated);

  //   }
  // }, [token]);

  // useEffect(() => {
  //   apiInstance.get(`profile`)
  //     .then(({data}) => {
  //       setUser(data);
  //     })
  // }, [apiInstance, setUser])


  // useEffect(() => {
  //   // console.log("isAuthenticated ", isAuthenticated);
  //   if (!token && !isAuthenticated) {
  //     setLoading(false);
  //   }
  // }, [token, isAuthenticated]);

  // useEffect(() => {
  //   console.log("ROLES ", ROLES, " : USER_ROLE ", USER_ROLE);
  // }, []);

  // if (loading) {
  //   return <Loader />;
  // }
  return (
    <Switch>
      {!isAuthenticated ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/login" to="/" />
      )}

      <Route path="/error" component={NotFound} />

      {!isAuthenticated ? (
        <Redirect to="/login" />
      ) : (
        <Route>
          <BasePage />
        </Route>
      )}
      {/* <Route>
        <BasePage />
      </Route> */}
    </Switch>
  );
}
