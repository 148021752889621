import React, { useContext, useEffect, useState } from "react";
import { StartupList } from "../components/StartupList";
import { ProgrammeContext } from "../contexts/UserContext";
import { USER_ROLE } from "../modules/components/utils/contantHelpers";
import useAxiosInterceptors from "../hooks/httpConfig";
import { Loader } from "../modules/components";

function Consultant () {
  const apiInstance = useAxiosInterceptors();
  const [projet] = useContext(ProgrammeContext)
  const [startups, setStartups] = useState([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const programme = projet?.programme?._id
    const role = USER_ROLE?.CONSULTANT
    if(role && programme) {
      apiInstance.post(`/startup/adhesions`, {role, programme}).then(({data}) => {
        setStartups(data)
        setLoading(false)
      }).catch(() => setLoading(false))
    }
  }, [apiInstance, projet?.programme?._id])

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="Content-Container">
      <div className="PageHeader">
        <div className="row">
          <div className="col-8 col-md-4">
            <h3 className="PageHeader-Title">Startups</h3>
          </div>
          <div className="col-4 col-md-8">
            <div className="FilterForm">
              <button className="MobileFilter" type="button" name="button"><i className="uil uil-filter"></i> Filter</button>
              <div className="FilterRow">
                <button className="CloseMobileFilter" type="button" name="button"><i className="uil uil-times"></i></button>
                {/* START INPUT ROW */}
                <div className="Input-Row">
                  <input type="text" name="" onChange={() => { console.log("name");
                  }} className="InputField" placeholder="Nom du Consultant" />
                </div>
                {/* END INPUT ROW */}

                {/* START INPUT ROW */}
                <div className="Input-Row Input-Select">
                  <select className="SelectFilter" name="" defaultValue={'DEFAULT'}>
                    <option value="DEFAULT" disabled>Secteur d’activité</option>
                    <option vlaue="">Secteur 1</option>
                    <option vlaue="">Secteur 2</option>
                    <option vlaue="">Secteur 3</option>
                  </select>
                </div>
                {/* END INPUT ROW */}

              </div>
            </div>
          </div>
        </div>
      </div>

      <StartupList startups={startups} role={USER_ROLE?.CONSULTANT}/>

    </div>
  )
}

export default Consultant;



