import React from "react";
import { Datatable } from "../../modules/components";

class Operation extends React.Component
{
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      operations:[
        {
          "date": "2020-01-15",
          "categorie": "Restaurant",
          "type": "Dépense",
          "note": "Restauration réunion de pilotage",
          "montant": "$50 000",
          "preuve": "Oui"
        },
        {
          "date": "2020-01-20",
          "categorie": "Dadupa",
          "type": "Dépense",
          "note": "Libération Budget missions",
          "montant": "$25 000",
          "preuve": ""
        },
        {
          "date": "2020-01-30",
          "categorie": "Startup Houdane",
          "type": "Dépense",
          "note": "Libération financement", 
          "montant": "$40 000",
          "preuve": ""
        },
        {
          "date": "2020-01-15",
          "categorie": "Bailleur de fonds Ab",
          "type": "Entrée",
          "note": "Signature contrats sur fonds",
          "montant": "$50 000",
          "preuve": ""
        }
      ],
      columns: [
        {
          name: "Date",
          selector: "date",
          sortable: true
        },
        {
          name: "Categorie",
          selector: "categorie",
          sortable: true
        },
        {
          name: "Type",
          selector: "type",
          sortable: true
        },
        {
          name: "Note",
          selector: "note",
          sortable: true
        },
        {
          name: "Montant",
          selector: "montant",
          sortable: true,
          right: true
        },
        {
          name: "Preuve",
          selector: "preuve",
          sortable: true,
          right: true
        }
      ]
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){    
    const { operations } = this.state;
    console.log(operations);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('Le nom a été soumis : ' + this.state.value);
    event.preventDefault();
  }

  render() {
    const { operations, columns } = this.state;
    return (
      <>
        <div className="TableWrap">
          <div className="table-responsive">
            <Datatable rows={operations} columns={columns} />
          </div>
        </div>

        <div className="modal fade" id="AddNewElement" tabIndex="-1" role="dialog" aria-labelledby="AddNewElementTitle" aria-hidden="true">
          <div className="DigiReprting-Modal modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">

              <div className="modal-body">
                <div className="SettingCol-Header">
                  <h4 className="SettingCol-HeaderTitle">Nouveau Éléments</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i className="uil uil-times"></i>
                  </button>
                </div>

                <div className="SettingCol-Row">
                  <form onSubmit={this.handleSubmit}>
                    <label>
                      Nom :
                      <input type="text" value={this.state.value} onChange={this.handleChange} />
                    </label>
                    <input type="submit" value="Envoyer" />
                  
                    <div className="Settings-Field Digi-Col-12">
                      <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                        <input type="text" className="FieldText" placeholder="Nom" name="Nom" onChange={this.handleChange}/>
                      </div>
                    </div>
                    <div className="Settings-Field Digi-Col-12">
                      <div className="Settings-FieldRow Settings-FieldRow_Select LightBG">
                        <select className="FieldRow-Select" defaultValue={"DEFAULT"} name="" onChange={this.handleChange}>
                          <option value="DEFAULT" disabled>Type</option>
                          <option value="Produit">Produit</option>
                          <option value="Service">Service</option>
                        </select>
                      </div>
                    </div>
                    <div className="Settings-Field Digi-Col-6">
                      <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                        <input type="text" className="FieldText" placeholder="Prix d’achat" name="periode" onChange={this.handleChange}/>
                      </div>
                    </div>
                    <div className="Settings-Field Digi-Col-6">
                      <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                        <input type="text" className="FieldText" placeholder="Prix de vente" name="periode" onChange={this.handleChange}/>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <div className="ButtonCancel"><button type="button" className="Cancel-BTN" data-dismiss="modal">Annuler</button></div>
                <div className="ButtonSave"><button type="submit" className="Save-BTN">Enregistrer</button></div>
              </div>
            </div>
          </div>
        </div>

        
        <div className="modal fade" id="EditElement" tabIndex="-1" role="dialog" aria-labelledby="EditElementTitle" aria-hidden="true">
          <div className="DigiReprting-Modal modal-dialog modal-dialog-centered" role="document">
            {/* <div className="modal-content">

              <div className="modal-body">
                <div className="SettingCol-Header">
                  <h4 className="SettingCol-HeaderTitle">Nouveau Éléments</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i className="uil uil-times"></i>
                  </button>
                </div>

                <div className="SettingCol-Row">
                  <div className="Settings-Field Digi-Col-12">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Nom" name="Nom" value=""/>
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-12">
                    <div className="Settings-FieldRow Settings-FieldRow_Select LightBG">
                      <select className="FieldRow-Select" name="">
                        <option selected disabled>Type</option>
                        <option value="Produit">Produit</option>
                        <option value="Service">Service</option>
                      </select>
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Prix d’achat" name="periode" value=""/>
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Prix de vente" name="periode" value=""/>
                    </div>
                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <div className="ButtonCancel"><button type="button" className="Cancel-BTN" data-dismiss="modal">Annuler</button></div>
                <div className="ButtonSave"><button type="button" className="Save-BTN">Update</button></div>
              </div>
            </div> */}
          </div>
        </div>


      </>
    )
  }
}

export default Operation;