import React from "react";
import Slider from "react-slick";

export function CustomSlider () {
  const settings = {
    arrows: 'true',
    dots: 'true',
    infinite: 'true',
    speed: 300,
    autoPlay: true,
    autoplayspeed: '3000',
    fade: 'true',
    cssease: 'linear',
  };
  return (
    <>
      <div className="FeaturesCarousel-Slider" {...settings}>
        <div className="Feature-Thumb">
          <img src="assets/media/images/Feature-Thumb.svg" alt=""/>
        </div>
        <Slider className="FeaturesCarousel-Items">
          <div className="Feature-Content">
            <h2 className="Feature-Title">E-Reporting et éducation financière</h2>
            <p className="Feature-Text">Digi Reporting est une solution hybride, permettant d’effectuer le reporting en temps réel et l'éducation financière des entrepreneurs.</p>
          </div>
          <div className="Feature-Content">
            <h2 className="Feature-Title">Gain de temps et d’argent</h2>
            <p className="Feature-Text">En regroupant l’ensemble de ses fonctionnalités au même endroit, Digi Reporting vous permet de gagner en temps et en argent.</p>
          </div>
          <div className="Feature-Content">
            <h2 className="Feature-Title">Une solution Multirôle</h2>
            <p className="Feature-Text">Digi Reporting permet aux consultants, accompagnateurs, incubateurs et bailleurs de fonds d'assister les startups avec plus de précision.</p>
          </div>
          <div className="Feature-Content">
            <h2 className="Feature-Title">Simplicité d’utilisation</h2>
            <p className="Feature-Text">Digi Reporting facilite l’apprentissage de la gestion de projets startup et permet à tout acteur novice ou expert de s’y retrouver.</p>
          </div>
        </Slider>
      </div>
    </>
  )
}
