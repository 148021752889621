import React from "react";
import { Datatable, PieChart } from "../modules/components";

class MyBudget extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      rows:[
        {
          user: "Armand Corp",
          categorie: "Financement",
          budget: "$50 000",
          startDate: "2020-01-20",
          endDate: "2020-11-20",
          tranches: "2",
          depense: "$25 000",
          restant: ""
        },
        {
          user: "Houdane.C",
          categorie: "Financement",
          budget: "$25 000",
          startDate: "2020-01-20",
          endDate: "2020-11-20",
          tranches: "2",
          depense: "",
          restant: ""
        },
        {
          user: "Dadupa.C",
          categorie: "Restaurant",
          budget: "$40 000",
          startDate: "2020-01-20",
          endDate: "2020-11-20",
          tranches: "10",
          depense: "$4 000",
          restant: ""
        },
        {
          user: "Yang Consulting",
          categorie: "Global",
          budget: "$2 000 000",
          startDate: "2020-01-20",
          endDate: "2020-11-20",
          tranches: "",
          depense: "$1 800 000",
          restant: ""
        }
      ],
      columns: [
        {
          name: "Utilisateur",
          selector: "user",
          sortable: true
        },
        {
          name: "Categorie",
          selector: "categorie",
          sortable: true
        },
        {
          name: "Budget",
          selector: "budget",
          sortable: true
        },
        {
          name: "Tranches",
          selector: "tranches",
          sortable: true
        },
        {
          name: "Dépensé",
          selector: "depense",
          sortable: true
        },
        {
          name: "Restant",
          selector: "restant",
          sortable: true
        }
      ]
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
  }

  handleChange(event){
    console.log("date clicked");
  }

  render () {
    const { rows, columns } = this.state;
    return (
      <div className="Settings-Container">
        <div className="row">
          <div className="SettingsRow">

            <div className="SettingsContent">
              <div className="PageHeader">

                <div className="row">
                  <div className="col-8 col-md-4">
                    <h3 className="SettingsContent-Title">Mon Budget</h3>
                  </div>
                  <div className="col-4 col-md-8">
                    <div className="FilterForm">
                      <button className="MobileFilter" type="button" name="button"><i className="uil uil-filter"></i> Filter</button>
                      <div className="FilterRow">
                        <button className="CloseMobileFilter" type="button" name="button"><i className="uil uil-times"></i></button>

                        <div id="datepicker" className="Input-Row Input-Date">
                            <input className="InputField" type="text" name="daterange" placeholder="Période" value="" />
                        </div>

                      </div>
                    </div>


                  </div>
                </div>
              </div>

              <div className="SettingsCols-Wrap">
                <div className="SettingsCols-Row">
                  <div className="SettingCol-4 DisplayFlex">
                    <div className="Purple-BOX FinancingBOX DashboardStats-BOX FlexView">
                      <div className="BOX-Content">
                        <div className="BOX-Side-1">
                          <div className="BOX-Side-Content">
                            <label>Budget</label>
                            <span>$90 000</span>
                          </div>
                        </div>
                        <div className="BOX-Side-1">
                          <div className="BOX-Side-Content">
                            <label>Investi</label>
                            <span>$300 000</span>
                          </div>
                        </div>
                        <div className="BOX-Side-1">
                          <div className="BOX-Side-Content Budget">
                            <label>Budget</label>
                            <span>$700 000</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="SettingCol-8">
                    <div className="ReportBOX Participations">
                      <div className="ReportBOX-Body">
                        <h3 className="ReportBOX-BodyTitle">Les catégories <br/>des activités</h3>
                        <div id="ReportPie" style={{ width: "100%" }} >
                          <PieChart />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="Budget-TableSection" id="Budget-TableSection">

                <div className="TableActions">
                  <div className="TableActions-Form">
                    <div className="TableActions-FormRowLeft">
                      <button type="button" className="Porpal-BTN" data-toggle="modal" data-target="#AddOperation">Nouveau Budget</button>
                      <button type="button" className="White-BTN" data-toggle="modal" data-target="#EditOperation"><i className="uil uil-pen"></i></button>
                      <button type="button" className="Red-BTN" data-toggle="modal" data-target="#DeleteAlert"><i className="uil uil-trash-alt"></i></button>
                    </div>
                    <div className="TableActions-FormRowRight">

                    </div>
                  </div>
                </div>
                <div className="TableWrap">
                  <div className="table-responsive">

                    <Datatable rows={rows} columns={columns} />

                </div>

                </div>

              </div>



            </div>
          </div>
        </div>
      </div>
      )
    }
}

export default MyBudget;