import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import "https://unicons.iconscout.com/release/v3.0.0/css/line.css";
// import "rsuite/dist/styles/rsuite-default.css"; // or 'rsuite/dist/styles/rsuite-default.cs
import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/css/slick.css";
import "./assets/css/daterangepicker.css";
import "./assets/css/style.css";
 
// import 'bootstrap/dist/css/bootstrap.min.css';
// import axios from "axios";
// import * as _redux from "./redux";
// import store from './redux/store';
// _redux.setupAxios(axios, store);
ReactDOM.render(
  <React.Fragment>
      <App/>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
