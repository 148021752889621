import React, { useCallback, useContext, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import useAxiosInterceptors from "../../../../hooks/httpConfig";
import { UserContext } from "../../../../contexts/UserContext";
const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email invalide")
    .required("L'adresse e-mail est obligatoire"),
  password: Yup.string().required("Le mot de passe est obligatoire"),
});

export default function LoginForm() {
  const [error, setError] = useState("");
  const axiosInstance = useAxiosInterceptors();
  const [user, setUser] = useContext(UserContext);
  // console.log(setUser);
  const handleSubmit = useCallback(
    async (values) => {
      const login = async (userData) => {
        try {
          const res = await axiosInstance.post(`/connexion`, userData);
          if (res) {
            const {user, accessToken, refreshToken} = res?.data;
            if (!!user) {
              localStorage.setItem(
                "tokens",
                JSON.stringify({
                  accessToken,
                  refreshToken,
                })
              );
            }
            
            setUser(user)
            console.log({user});
            // setIsAuthenticated(true);
          } else {
            
            if (!!res.data?.message) {
              setError(res.data.message);
              // console.log(`ERROR => `, res);
            }
            setUser(null);
          }
        } catch (error) {
          console.log(error);
          setError("Une erreur s'est produite");
        }
      };

      login(values);
    },
    [axiosInstance, setUser]
  );

  useEffect(() => {
    console.log('user : ', user?.nom);
  }, [user?.nom])

  return (
    <>
      {/* LOGIN TITLE */}
      <h3 className="LoginForm-Title">Bon retour !</h3>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={SigninSchema}
        onSubmit={handleSubmit}
        render={({ errors, touched }) => (
          <Form id="LoginForm" className="LoginForm">
            {/* LOGIN INPUTS */}
            <div className="Input-Row">
              <i className="uil uil-envelope"></i>
              <Field
                type="text"
                name="email"
                id="email"
                placeholder="Adresse e-mail"
                className={
                  "DRInput-Class" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              {touched.email && errors.email ? (
                <div className="text-danger text-right">{errors.email}</div>
              ) : null}
            </div>
            <div className="Input-Row">
              <i className="uil uil-lock-alt"></i>
              <Field
                type="password"
                name="password"
                id="password"
                placeholder="Mot de passe"
                className={
                  "DRInput-Class" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              {touched.password && errors.password ? (
                <div className="text-danger text-right">{errors.password}</div>
              ) : null}
            </div>
            {/* LOGIN ACTIONS */}
            <div className="LoginForm-Actions">
              <div className="LoginForm-Action Action-Left">
                <div className="CheckBox-Field">
                  <label className="container-checkbox">
                    <input type="checkbox" />
                    <span className="checkmark"></span>
                    <span>Se souvenir de moi</span>
                  </label>
                </div>
              </div>
              <div className="LoginForm-Action Action-Right">
                <a href="/forgot-password" className="forgot-password">
                  Mot de passe oublié ?
                </a>
              </div>
            </div>
            {/* LOGIN SUBMIT */}
            <button
              type="submit"
              name="FormLogin-Submit"
              className="Form-Submit"
            >
              <i className="uil uil-user-check"></i> Se connecter
            </button>
            {error && (
              <div className="alert alert-danger mt-2" role="alert">
                {error}
              </div>
            )}
          </Form>
        )}
      />
    </>
  );
}
