import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';
import InputMask from "react-input-mask";
registerLocale('fr', fr)
export const CustomDate = ({selected, onChange, minDate, placeholder, className=""}) => {
  return (
    <>
      <DatePicker
        customInput={
          <InputMask
            readOnly
            className={`DateField DRInput-Class ${className}`}
            type="text" mask="99/99/9999" />
        }
        dateFormat="dd/MM/yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        selected={selected}
        onChange={onChange}
        minDate={minDate}
        placeholderText={placeholder}
      />
    </>
  );
};
