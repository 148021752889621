import React, {useCallback, useRef} from "react";
import { Link, useHistory } from "react-router-dom";

export const ItemLink = ({ to, children, className="" }) => {
    const history = useHistory();
  
    const handleItemClick = useCallback((event, url) => {
      event.preventDefault();
      history.push(`/${url}`);
    }, [history])
  
    return (
      <Link className={`${className}`} onClick={(event) => handleItemClick(event, to)} to={to}
      >{children}
      </Link>
    )
};

export const MenuLink = ({ to, children, className="" }) => {
    const history = useHistory();
    const itemRef = useRef(null)
  
    const reloadMenuLinksActiveClassName = useCallback((item) => {
      const menuLinksActive = document.getElementsByClassName('Menu-Link-Active');
      if (menuLinksActive && menuLinksActive.length > 0) {
        menuLinksActive[0].className = "Menu-Link"
      }
      if (item) {
        item.className = "Menu-Link Menu-Link-Active"
      }
    }, [])
  
    const handleItemClick = useCallback((event, url) => {
      event.preventDefault();
      const item = itemRef?.current
      history.push(`/${url}`);
      reloadMenuLinksActiveClassName(item)
    }, [history, reloadMenuLinksActiveClassName])
  
    return (
      <Link ref={itemRef} className={`Menu-Link ${className}`}
        onClick={(event) => handleItemClick(event, to)} to={to}
      >{children}
      </Link>
    )
};

export function TableModalActions ({selectedRows, addActionTitle, to = '', onDelete= () => {}, onEdit= () => {}}) {
    return (
        <div className="TableActions-FormRowLeft">
            {!!to ? 
                <ItemLink to={to} className="dt-button buttons-create Porpal-BTN">
                    <span>{addActionTitle}</span>
                </ItemLink> : 
                <button type="button" className="Porpal-BTN" onClick={()=> onEdit(null)}> {addActionTitle} </button>
            }
            {
                selectedRows && selectedRows.length === 1 ?
                <>
                    {!!to
                        ? <ItemLink to={to} className="White-BTN"><i className="uil uil-pen"></i></ItemLink> 
                        : <button type="button" className="White-BTN" onClick={()=> onEdit(selectedRows[0])}><i className="uil uil-pen"></i></button>
                    }
                    <button type="button" className="Red-BTN" onClick={()=> onDelete(selectedRows[0])}><i className="uil uil-trash-alt"></i></button>
                </> : null
            }
        </div>
    )
}

export function TableLinkActions ({rows, children, toAdd = '', toEdit = '', onDelete= () => {}}) {
    return (
        <div className="TableActions-FormRowLeft">
            <ItemLink to={toAdd} className="Porpal-BTN mr-2"><span>{children}</span></ItemLink>
            {
                rows && rows.length === 1 ?
                <>
                    <ItemLink to={toEdit} className="White-BTN EditLink-BTN"><i className="uil uil-pen"></i></ItemLink>
                    <button type="button" className="Red-BTN" onClick={()=> onDelete(rows[0])}><i className="uil uil-trash-alt"></i></button>
                </> : null
            }
        </div>
    )
}