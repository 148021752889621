import React, { useEffect, useState } from 'react';
import { StartupDetails } from './StartupDetails';
import useAxiosInterceptors from '../../hooks/httpConfig';

export default function Startup (props) {
  const apiInstance = useAxiosInterceptors();
  const { id } = props.match.params;
  const [startup, setStartup] = useState(undefined)
  const [identifiant, setIdentifiant] = useState('')

  useEffect(() => {
    console.log('startup ', startup);
  }, [startup])

  useEffect(() => {
    if(id) {
      apiInstance.get(`startup/${id}`).then(({data}) => {
        data.teams = []
        setStartup(data);
        setIdentifiant(data?.nom.split(" ").join("").toLowerCase())
      })
    }
  }, [apiInstance, id])

  return (
    <>
      { startup &&
        <StartupDetails startup={startup} identifiant={identifiant} />
      }
    </>
  )
}
