import React from "react";
class Startup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      startups: [
        {
          Id: "60241760f336870e265560b0",
          Nom: "Lobe Incub",
          Activite: "Incubation",
          Secteur: "Service",
          Lieu: "Casablanca",
          Creation: "18/02/2016",
          NumRC: "11000003070",
          NumIF: "1000085363",
          Telephone: "(212) 681992210",
          Email: "lobe.incub@gmail.com",
          Reseaux: "",
          Site: "https://lobe.incub.ma",          
          Logo: "lobe-logo.jpg",
          Couverture: "lobe.jpg",
          description: "Lobe Incub est un incubateur dont la mission est de sélectionner et d’accompagner les meilleures start-up dans leur déploiement en Afrique.",
          teams: [
            {
              id: 1,
              name: "Lobe Jonathan",
              role: "Gerant",
              email: "lobe.incub@gmail.com",
              phone: "(212) 657794432",
            },
            {
              id: 2,
              name: "Xaxier",
              role: "COO",
              email: "iya.consulting@gmail.com",
              phone: "(212) 657794432",
            }
          ]
        },
        {
            Id: "60241760f336870e265560a8",
            Nom: "MHS ",
            Activite: "Developpement / cooperation ",
            Secteur: "Service ",
            lieu: "Rabat",
            Creation: "01/06/0202",
            NumRC: "2000422",
            NumIF: "354000",
            Telephone: "(212) 681992210",
            Email: "mhs@gmail.com",
            Reseaux: "Facebook/LinkedIn ",
            Site: "mhs.com",         
            Logo: "MHS.jpg",
            Couverture: "MHS COVER.jpg",
            status: true,
            description: "MHS Mets les startups en relations avec des structures de financement basée à l’international afin de leur faciliter l’accès au capital de développement.",
            teams: [
              {
                  "id": "1",
                  "name": "Franck",
                  "role": "President ",
                  "entite": "MHS",
                  "email": "mhs@gmail.com",
                  "phone": "(212) 681992210"
              },
              {
                  "id": "2",
                  "name": "Daniel",
                  "role": "Representant ",
                  "entite": "MHS",
                  "email": "mhs@gmail.com",
                  "phone": "(212) 681992210"
              }
            ]
        },
        {
            Id: "60241760f336870e26556h30",
            Nom: "Houdane Invest ",
            Activite: "Investissement / accompagnement",
            Secteur: "Service ",
            lieu: "France ",
            Creation: "26/03/2010",
            NumRC: "1100025",
            NumIF: "300045",
            Telephone: "(212) 669124912",
            Email: "houdane.invest@gmail.com",
            Reseaux: "Facebook/LinkedIn ",
            Site: "houdane.invest.fr",                     
            Logo: "HOUDANE INVEST.jpg",
            Couverture: "HOUDANE INVEST COVER.jpg",
            status: true,
            description: "Houdane invest investit dans les startups en phase de croissance et s’implique dans la gouvernance de ces entreprise en accompagnant l’entrepreneur dans les différent volets opérationnels et stratégiques de son entreprise.",
            teams: [
              {
                  "id": "1",
                  "name": "Jean Pière",
                  "role": "Gerant ",
                  "entite": "Houdane Invest ",
                  "email": "houdane.invest@gmail.com",
                  "phone": "212) 669124912"
              },
              {
                  "id": "2",
                  "name": "Sagno",
                  "role": "COO",
                  "entite": "Houdane Invest ",
                  "email": "houdane.invest@gmail.com",
                  "phone": "212) 669124913"
              }
            ]
        }
      ],
      startup: {},
      missions: [
        {
          id: 1,
          date: "10/02/2021",
          title: "Business Model",
          participantMemeber: "20",
          done: false
        },
        {
          id: 2,
          date: "23/01/2021",
          title: "Business Model",
          participantMemeber: "10",
          done: true
        },
        {
          id: 3,
          date: "16/01/2021",
          title: "Business Model",
          participantMemeber: "15",
          done: false
        },
        {
          id: 4,
          date: "03/01/2021",
          title: "Business Model",
          participantMemeber: "5",
          done: true
        }
      ],
    };
  }

  componentDidMount() {

  }

  render () {
    const { startups, missions } = this.state;
    const { id } = this.props.match.params;
    const startup = startups.find(element => element.Id === id);
    console.log(window.location.origin);
    const identifiant = startup !== undefined ? startup.Nom.split(" ").join("").toLowerCase(): "";
    return (
        <>
          { startup &&
            <div className="row">
              <div className="DetailsPage-Container">
                <div className="DetailsPage-Cover" style={{ "backgroundImage": "url('/assets/media/images/managers/" + startup.Couverture + "')"}}>

                  <div className="StartUPInfos-Header">
                    <img  id="imageProfile" className="StartUPInfos-Thumb" src={window.location.origin + "/assets/media/images/managers/" + startup.Logo} alt={startup.Nom}/>
                    <div className="StartUPInfos-Name">{ startup.Nom }</div>
                    <div className="StartUPInfos-Identiant">@{ identifiant }</div>
                    <button className="ToggleProfile-Infos" type="button" name="button">Plus d'infos</button>
                  </div>
                </div>
                <div className="DetailsPage-Content">
                  <div className="DetailsPage-Desc">
                    <h3>{ startup.Nom }</h3>
                    <p>{ startup.description }</p>
                  </div>

                  <div className="MessionsBOX">
                    <div className="MessionsBOX-Header">
                      <h4>Missions</h4>
                      <div className="FilterForm">
                        <div className="FilterRow">

                          {/* START INPUT ROW */}
                          <div className="Input-Row Input-Select">
                            <select className="SelectFilter LightedColor" name="" DefaultValue={"DEFAULT"}>
                              <option Value="DEFAULT" selected>Chiffre d’affaires</option>
                              <option value="Janvier">Janvier 2021</option>
                              <option value="Fevrier">Fevrier 2021</option>
                              <option value="Mars">Mars 2021</option>
                            </select>
                          </div>
                          {/* END INPUT ROW */}
                        </div>
                      </div>
                    </div>
                    <div className="MissionsContainer">
                      {missions.map((mission) => (
                        <div key={mission.id} className="MissionBOX" data-toggle="tooltip" data-placement="bottom" title={`Mission ${mission.done ? "" : "non"} effectué`}>
                          <label className={`MissionBOX-${mission.done ? "Done" : "NotDone"}`}><i className={`uil uil-${mission.done ? "check" : "multiply"}`}></i></label>
                          <span className="MissionBOX-Date">{mission.date}</span>
                          <h5 className="MissionBOX-Title">{mission.title}</h5>
                          <div className="ParticipantsMembers">
                            <span className="ParticipantMemeber"></span>
                            <span className="ParticipantMemeber"></span>
                            <span className="ParticipantMemeber"></span>
                            <span className="ParticipantMemeber">{"+" + mission.participantMemeber}</span>
                          </div>
                        </div>                        
                      ))}
                    </div>
                  </div>

                  <div className="StartUP-BOXES">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="FinancingBOX DashboardStats-BOX">
                          <div className="BOX-Header">
                            <h4 className="BOX-Title"><i className="uil uil-coins"></i> Financement</h4>
                            <button type="button" name="button" className="ReportView">Plus de détails</button>
                          </div>
                          <div className="BOX-Content">
                            <div className="BOX-Side-1">
                              <div className="BOX-Side-Content">
                                <label>Budget investi</label>
                                <span style={{ marginRight: "2rem" }}>$300 000</span>
                              </div>
                            </div>
                            <div className="BOX-Side-1">
                              <div className="BOX-Side-Content Budget">
                                <label>Budget Global</label>
                                <span>$700 000</span>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="TeamBOX">
                          <div className="TeamBOX-Header">
                            <h4 className="TeamBOX-HeaderTitle"><i className="uil uil-users-alt"></i> Equipe</h4>
                            <button type="button" name="button" className="MembersList" data-toggle="modal" data-target="#ModalMembers">Tous les membres</button>
                          </div>
                          <ul className="TeamBOX-List">
                            {startup.teams.map((team) => (
                              <li key={team.id} className="TeamBOX-ListItem">
                                <div className="TeamBOX-MemberAvatar"></div>
                                <div className="TeamBOX-MemberDetails">
                                  <div className="MemberDetails-Name">{team.name}</div>
                                  <span className="MemberDetails-Email">{team.email}</span>
                                  <span className="MemberDetails-Function">{team.role}</span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="DetailsPage-Sidebar">
                <div className="CloseToggleProfile">
                  <button type="button" className="CloseToggleProfile-BTN"><i className="uil uil-times"></i></button>
                </div>
                <div className="StartUPInfos-Header">
                  <img  id="imageProfile" className="StartUPInfos-Thumb" src={window.location.origin + "/assets/media/images/managers/" + startup.Logo} alt={startup.Nom}/>
                  <div className="StartUPInfos-Name">{ startup.Nom }</div>
                  <div className="StartUPInfos-Identiant">@{ identifiant }</div>
                </div>
                <div className="StartUPInfos-Body">
                  <ul className="StartUPInfos-List">
                    <li className="StartUPInfos-Item">
                      <div className="StartUPInfos-ItemIcon"><i className="uil uil-cog"></i></div>
                      <div className="StartUPInfos-ItemContent">
                        <label className="StartUPInfos-ItemLabel">Activité</label>
                        <span className="StartUPInfos-ItemValue">{ startup.Activite }</span>
                      </div>
                    </li>
                    <li className="StartUPInfos-Item">
                      <div className="StartUPInfos-ItemIcon"><i className="uil uil-crosshair-alt"></i></div>
                      <div className="StartUPInfos-ItemContent">
                        <label className="StartUPInfos-ItemLabel">Secteur d’activité</label>
                        <span className="StartUPInfos-ItemValue">{ startup.Secteur } </span>
                      </div>
                    </li>
                    <li className="StartUPInfos-Item">
                      <div className="StartUPInfos-ItemIcon"><i className="uil uil-map-marker"></i></div>
                      <div className="StartUPInfos-ItemContent">
                        <label className="StartUPInfos-ItemLabel">Lieu de création</label>
                        <span className="StartUPInfos-ItemValue">{ startup.Lieu }</span>
                      </div>
                    </li>
                    <li className="StartUPInfos-Item">
                      <div className="StartUPInfos-ItemIcon"><i className="uil uil-calendar-alt"></i></div>
                      <div className="StartUPInfos-ItemContent">
                        <label className="StartUPInfos-ItemLabel">Date de création</label>
                        <span className="StartUPInfos-ItemValue">{ startup.Creation }</span>
                      </div>
                    </li>
                  </ul>

                  <ul className="StartUPInfos-Contact">
                    <h4>Contacts</h4>
                    <li className="StartUPInfos-ContactItem"><i className="uil uil-globe"></i> <a href="https://houdaneconsulting.ma">{ startup.Site }</a></li>
                    <li className="StartUPInfos-ContactItem"><i className="uil uil-envelope"></i> <a href="mailto:contact@fundcorp.ma">{ startup.Email }</a></li>
                    <li className="StartUPInfos-ContactItem GrayLink"><i className="uil uil-phone-alt"></i> <a href="tel:06 79 76 23 98">{ startup.Telephone }</a></li>
                    <li className="StartUPInfos-ContactItem GrayLink"><i className="uil uil-facebook-f"></i> <a href="#!">facebook.com/{ identifiant }</a></li>
                    <li className="StartUPInfos-ContactItem GrayLink"><i className="uil uil-twitter"></i> <a href="#!">twitter.com/{ identifiant }</a></li>
                  </ul>
                </div>

                <div className="StartUPInfos-Footer">
                  <label>Infos légales</label><span><br/>RC: { startup.NumRC } - IF { startup.NumIF }</span>
                </div>
              </div>
            </div>
          }
        </>
      )
    }
}

export default Startup;