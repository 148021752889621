import React, { useCallback } from 'react'
import { Formik, ErrorMessage, Form, Field } from 'formik';
import * as Yup from 'yup';
import { CustomDatePicker, PhoneField } from '../../../modules/components';
import useAxiosInterceptors from '../../../hooks/httpConfig';
const validationSchema = Yup.object().shape({
  nom: Yup.string().required('Le nom est obligatoire'),
});
export function AccountForm({user, onHide, alert, setAlert}) {
  const apiInstance = useAxiosInterceptors();
  const handleUpdate = useCallback((values) => {
    if(user?._id) {
      console.log('values ', values);
      apiInstance.put(`/utilisateurs/${user._id}`, values).then(({data}) => {
        setAlert({
          variant: 'success',
          message: "modifié avec succès"
        });
        setTimeout(function(){
          setAlert(null);
          onHide()
        }, 1000);
      }).catch(() => {
        setAlert({
          message: "Impossible de modifier le profile",
          variant: "danger"
        })
      })
    }
  }, [apiInstance, onHide, setAlert, user?._id])
  return (
    <Formik
      initialValues={{
        id: user?._id || '',
        nom: user?.nom || '',
        prenom: user?.prenom || '',
        email: user?.email || '',
        telephone: user?.telephone || '',
        birth: (user?.birth && new Date(user.birth)) || new Date(),
        pays: user?.pays || ''
      }}
      validationSchema={validationSchema}
      onSubmit={handleUpdate}
    >
      {({ values, isValid, touched, setFieldValue }) => (
        <Form className="FollowUp-Form">
          <div className="SettingCol-Row">
            <div className="Settings-Field Settings-Field-6">
              <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                <Field name="nom"
                  placeholder="Nom"
                  className="FieldText"
                />
              </div>
              <ErrorMessage component="div" className="text-danger" name="nom" />
            </div>
            <div className="Settings-Field Settings-Field-6">
              <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                <Field name="prenom"
                  placeholder="Prénom"
                  className="FieldText"
                />
              </div>
            </div>
            <div className="Settings-Field Settings-Field-1">
              <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                <Field name="email"
                  placeholder="Identifiant unique"
                  className="FieldText"
                />
              </div>
            </div>
            <div className="Settings-Field Settings-Field-1">
              <PhoneField
                name="telephone"
                value={user?.telephone}
                onChange={(telephone) => setFieldValue('telephone', telephone)}
                placeholder="Identifiant unique"
                className="FieldText"
                label="Numéro de téléphone"
              />
            </div>
            <div className="Settings-Field Settings-Field-1">
              <label htmlFor="birth">Date de naissance</label>
              <div id="StartDate" className="Input-Row Input-Date date" data-date-format="dd-mm-yyyy">
                <i style={{zIndex: 1}} className="uil uil-calender"></i>
                <CustomDatePicker
                  wrapperClassName="datepicker"
                  className="DateField DRInput-Class"
                  dateFormat="dd/MM/yyyy"
                  placeholder="Date de naissance"
                  name="birth"
                  maxDate={new Date()}
                  selected={values.birth}
                  onChange={(birth) => setFieldValue('birth', birth)}/>
                  <span className="input-group-addon"><i className="uil uil-calendar-alt"></i></span>
              </div>
            </div>
            <div className="Settings-Field Settings-Field-1">
              <label htmlFor="pays">Pays</label>
              <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                <Field name="pays"
                  placeholder="Pays"
                  className="FieldText"
                />
              </div>
            </div>
            <div className="Settings-Field Settings-Field-1">
              <div className="row">
                <div className="col-md-12 text-right">
                  <button onClick={onHide} type="button" className="btn Cancel-BTN">Annuler</button>
                  <button disabled={!isValid} type="submit" className="btn Save-BTN">Sauvegarder</button>
                </div>
              </div>
            </div>
            {
              alert &&
              <div className="Settings-Field Settings-Field-1"> 
                <div className={`alert alert-${alert.variant}`} role="alert">
                  {alert.message}
                </div>
              </div>
            }
          </div>
        </Form>
      )}
    </Formik>
  )
}