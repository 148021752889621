import React from "react";

import Bien from "./startup/Bien";
import Operation from "./startup/Operation";
import Tresorerie from "./startup/Tresorerie";
import Rentabilite from "./startup/Rentabilite";

class MyStartup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      startups:[
        {
          id: "1",
          nom: "Houdane Consulting",
          couverture: "Houdane_Consulting-Preview.jpg",
          secteur: "Education",
          ca: "300 000$",
          status: true,
          annee: "2010"
        },
        {
          id: "2",
          nom: "Dadupa Consulting",
          couverture: "Houdane_Consulting-Preview.jpg",
          secteur: "Education",
          ca: "300 000$",
          status: false,
          annee: "2010"
        }
      ]
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){    
    const { startups } = this.state;
    console.log(startups);
  }

  handleChange(event){
    console.log("date clicked");
  }

  render () {
    return (
      <div className="Settings-Container">
        <div className="row">
          <div className="SettingsRow">
            <div className="SettingsContent">
              <div className="PageHeader">
                <h3 className="SettingsContent-Title">Ma Startup</h3>
              </div>

              <div className="DetailsPage-Statistics">
                <div className="DetailsPage-StatisticsBOX BlueBOX">
                  <div className="StatisticsBOX-Content">
                    <i className="uil uil-parcel"></i>
                    <span className="StatisticsBOX-Numbers">15</span>
                    <label className="StatisticsBOX-Label">Biens</label>
                  </div>
                </div>
                <div className="DetailsPage-StatisticsBOX RedBOX">
                  <div className="StatisticsBOX-Content">
                    <i className="uil uil-shopping-bag"></i>
                    <span className="StatisticsBOX-Numbers">$17 000</span>
                    <label className="StatisticsBOX-Label">Achats</label>
                  </div>
                </div>
                <div className="DetailsPage-StatisticsBOX GreenBOX">
                  <div className="StatisticsBOX-Content">
                    <i className="uil uil-tag"></i>
                    <span className="StatisticsBOX-Numbers">$20 000</span>
                    <label className="StatisticsBOX-Label">Ventes</label>
                  </div>
                </div>
                <div className="DetailsPage-StatisticsBOX OrangeBOX">
                  <div className="StatisticsBOX-Content">
                    <i className="uil uil-university"></i>
                    <span className="StatisticsBOX-Numbers">$5 000</span>
                    <label className="StatisticsBOX-Label">Trésorerie</label>
                  </div>
                </div>
              </div>
              <div className="Tabs-Wraps">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="BiensTab" data-toggle="tab" href="#Biens" role="tab" aria-controls="Biens" aria-selected="true"><i className="uil uil-package"></i>Éléments</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="OperationsTab" data-toggle="tab" href="#Operations" role="tab" aria-controls="Operations" aria-selected="false"><i className="uil uil-exchange-alt"></i> Opérations</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="TresorerieTab" data-toggle="tab" href="#Tresorerie" role="tab" aria-controls="Tresorerie" aria-selected="false"><i className="uil uil-university"></i> Trésorerie</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="BilanTab" data-toggle="tab" href="#Bilan" role="tab" aria-controls="Bilan" aria-selected="false"><i className="uil uil-balance-scale"></i> Bilan</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="RentabiliteTab" data-toggle="tab" href="#Rentabilite" role="tab" aria-controls="Rentabilite" aria-selected="false"><i className="uil uil-bill"></i> Rentabilité</a>
                </li>
              </ul>
              <div className="tab-content TabContent" id="myTabContent">
                <div className="tab-pane fade show active" id="Biens" role="tabpanel" aria-labelledby="BiensTab">

                  <div className="TableActions">
                    <div className="TableActions-Form">
                      <div className="TableActions-FormRowLeft">
                        <button type="button" className="Porpal-BTN" data-toggle="modal" data-target="#AddNewElement">Nouveau bien</button>
                        <button type="button" className="White-BTN" data-toggle="modal" data-target="#EditElement"><i className="uil uil-pen"></i></button>
                        <button type="button" className="Red-BTN" data-toggle="modal" data-target="#DeleteAlert"><i className="uil uil-trash-alt"></i></button>
                      </div>
                      <div className="TableActions-FormRowRight">
                        <div className="TableActions-FormRow">

                        </div>
                      </div>
                    </div>
                  </div>

                  <Bien/>

                </div>

                <div className="tab-pane fade" id="Operations" role="tabpanel" aria-labelledby="OperationsTab">

                  <div className="TableActions">
                    <div className="TableActions-Form">
                      <div className="TableActions-FormRowLeft">
                        <div className="TableActions-FormRow">
                          <button type="button" className="Porpal-BTN" data-toggle="modal" data-target="#AddOperation">Nouvelle Opération</button>
                          <button type="button" className="White-BTN" data-toggle="modal" data-target="#EditOperation"><i className="uil uil-pen"></i></button>
                          <button type="button" className="Red-BTN" data-toggle="modal" data-target="#DeleteAlert"><i className="uil uil-trash-alt"></i></button>
                        </div>
                      </div>
                      <div className="TableActions-FormRowRight">

                      </div>
                    </div>
                  </div>

                  <Operation />
                </div>
                <div className="tab-pane fade" id="Tresorerie" role="tabpanel" aria-labelledby="TresorerieTab">
                  <div className="TableActions">
                    <div className="">
                      <div className="TableActions-FormRowLeft">
                        <button type="button" className="Porpal-BTN" data-toggle="modal" data-target="#AddTresorerie">Nouveau Trésorerie</button>
                        <button type="button" className="White-BTN" data-toggle="modal" data-target="#EditTresorerie"><i className="uil uil-pen"></i></button>
                        <button type="button" className="Red-BTN" data-toggle="modal" data-target="#DeleteAlert"><i className="uil uil-trash-alt"></i></button>
                      </div>
                      <div className="TableActions-FormRowRight">

                      </div>
                    </div>
                  </div>

                  <Tresorerie />
                </div>


                <div className="tab-pane fade" id="Tresorerie" role="tabpanel" aria-labelledby="TresorerieTab">
                  <div className="TableActions">
                    <div className="TableActions-Form">
                      <div className="TableActions-FormRowLeft">

                      </div>
                      <div className="TableActions-FormRowRight">

                      </div>
                    </div>
                  </div>

                  <div className="table-responsive">

                    <table id="MonBusiness-Tresorerie" className="table table-striped table-bordered dt-responsive nowrap" style = {{ "width": "100%"  }}>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Flux de trésorerie</th>
                          <th>Exploitation</th>
                          <th>Investissement</th>
                          <th>Financement</th>
                        </tr>
                      </thead>
                      <tfoot>
                        <tr>
                          <th></th>
                          <th>Flux de trésorerie</th>
                          <th>Exploitation</th>
                          <th>Investissement</th>
                          <th>Financement</th>
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                </div>

                <div className="tab-pane fade" id="Bilan" role="tabpanel" aria-labelledby="BilanTab">

                  <div className="BilanWrap">
                    <div className="BilanRow">
                      <h3 className="BilanRow-Title">Actif</h3>
                      <div className="BilanRow-Items">
                        <div className="BilanRow-Item">
                          <div className="BilanItem-Title">Actif à long terme</div>
                          <div className="BilanItem-Value">25 000 <span className="Currency">MAD</span></div>
                        </div>
                        <div className="BilanRow-Item">
                          <div className="BilanItem-Title">Actif à court terme</div>
                          <div className="BilanItem-Value">45 000 <span className="Currency">MAD</span></div>
                        </div>
                        <div className="BilanRow-Item">
                          <div className="BilanItem-Title">Trésorerie (caisse ou banque)</div>
                          <div className="BilanItem-Value">30 000 <span className="Currency">MAD</span></div>
                        </div>
                        <div className="BilanRow-Item">
                          <div className="BilanItem-Title">Actif Total</div>
                          <div className="BilanItem-Value">100 000 <span className="Currency">MAD</span></div>
                        </div>
                      </div>
                    </div>

                    <div className="BilanRow">
                      <h3 className="BilanRow-Title">Passif</h3>
                      <div className="BilanRow-Items">
                        <div className="BilanRow-Item">
                          <div className="BilanItem-Title">Capitaux propres</div>
                          <div className="BilanItem-Value">75 000 <span className="Currency">MAD</span></div>
                        </div>
                        <div className="BilanRow-Item">
                          <div className="BilanItem-Title">Dettes</div>
                          <div className="BilanItem-Value">20 000 <span className="Currency">MAD</span></div>
                        </div>
                        <div className="BilanRow-Item">
                          <div className="BilanItem-Title">Passif Total</div>
                          <div className="BilanItem-Value">90 000 <span className="Currency">MAD</span></div>
                        </div>
                      </div>
                    </div>

                    <div className="BilanRow">
                      <h3 className="BilanRow-Title">Rentabilité du bilan</h3>
                      <div className="BilanRow-Items">
                        <div className="BilanRow-Item">
                          <div className="BilanItem-Title">Bénéfices</div>
                          <div className="BilanItem-Value">10 000 <span className="Currency">MAD</span></div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="tab-pane fade" id="Rentabilite" role="tabpanel" aria-labelledby="RentabiliteTab">
                  <div className="TableActions">
                    <div className="TableActions-Form">
                      <div className="TableActions-FormRowLeft">

                      </div>
                      <div className="TableActions-FormRowRight">
                        <div className="TableActions-FormRow">
                          <div className="Settings-FieldRow Settings-FieldRow_Text Settings-FieldRow_Date LightBG date">
                            <input onChange={this.handleChange} type="text" name="daterange" className="FieldText" placeholder="Période" value="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Rentabilite/>

                </div>

              </div>
              </div>

            </div>
          </div>
        </div>

      </div>
      )
    }
}

export default MyStartup;