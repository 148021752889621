import React from 'react'
import { Alert } from 'react-bootstrap'

export function ConfirmAlert({ alert }) {
  return (
    alert &&
      <div className="SettingCol-Row row">
        <div className="col-md-12">
          <Alert mt={3} variant={alert.variant}>
            <div className="text-center">{alert.message}</div>                    
          </Alert>
        </div>
      </div>
  )
}