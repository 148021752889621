import React, { useCallback, useContext, useEffect } from 'react'
import { UserContext } from '../../../contexts/UserContext';

export function LogoutButton({hasIcon = false}) {
	const [user, setUser] = useContext(UserContext);

	useEffect(() => {
		console.log('apiInstance USER : ', user);
	}, [user])
	
	// const handleLogout = useCallback(async() => {
	// 	const logout = async (refreshToken) => {
	// 		debugger
	// 		if(!!refreshToken) {
	// 			try {
	// 				const response = await apiInstance.post(`logout`, {refreshToken});
	// 				console.log("LogoutButton response ", response);
	// 				if(response) {
	// 					debugger
	// 					localStorage.removeItem('tokens');
	// 					localStorage.clear();
	// 					setUser({});
	// 				}
	// 			} catch (error) {
	// 				debugger
	// 				console.log(error);
	// 			}
	// 		}
	// 	}
	// 	const refreshToken = JSON.parse(localStorage.getItem("tokens"))?.refreshToken;
	// 	logout(refreshToken);
	// }, [apiInstance, setUser])

	const clearLocalStorage = useCallback(() => {
		localStorage.removeItem('tokens');
		localStorage.clear();
		setUser({});
	}, [setUser])

	const handleLogout = useCallback(() => {
		const refreshToken = JSON.parse(localStorage.getItem("tokens"))?.refreshToken;
		if(refreshToken) {
			// apiInstance.post("logout", {refreshToken});
			clearLocalStorage();
		}
	}, [clearLocalStorage])

	return (
		<a href='/login' onClick={handleLogout}>{hasIcon && <i className="uil uil-sign-out-alt"></i>} <span>Se déconnecter</span></a>
	)
}