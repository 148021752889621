import React, { useState, useEffect } from "react";
import { Toast, Card } from "react-bootstrap";

import { useFormik } from "formik";
import * as Yup from "yup";
import useAxiosInterceptors from "../../../../hooks/httpConfig";
const SignupSchema = Yup.object().shape({
  nom: Yup.string().required("Le nom est obligatoire"),
  prenom: Yup.string().required("Le prenom est obligatoire"),
  // email: Yup.string().email("Email invalide").required("L'adresse e-mail est obligatoire")
  // 	.test('unique email', 'already using', async (value) => {
  // 	try {
  // 	  const res = await axios.post('/accounts/emailcheck', { email: value });
  // 	  return res.data.possible;
  // 	} catch (err) {
  // 	  console.error(err);
  // 	}
  //   }),
  email: Yup.string()
    .email("Email invalide")
    .required("L'adresse e-mail est obligatoire")
    // .test("email", "Cet email a deja été utilisé", (email) => {
    //   return new Promise((resolve, _reject) => {
    //     checkUserExists({ email }).then(({ data }) => {
    //       resolve(!data);
    //     });
    //   });
    // })
    ,
  password: Yup.string().required("Le mot de passe est obligatoire"),
  telephone: Yup.string().required("Le mot de passe est obligatoire"),
});

export function RegisterConfirm() {
  const [show, setShow] = useState(true);
  return (
    <Toast
      style={{
        position: "absolute",
        bottom: "1rem",
        right: "1rem",
      }}
      onClose={() => setShow(false)}
      show={show}
      delay={5000}
      bg="success"
    >
      <Toast.Header>
        <strong className="mr-auto">Bootstrap</strong>
        <small>11 mins ago</small>
      </Toast.Header>
      <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
    </Toast>
  );
}

const RegisterForm = () => {
  const [email, setEmail] = useState(null);
  const [registerConfirm, setRegisterConfirm] = useState(false);
  const axiosInstance = useAxiosInterceptors();
  useEffect(() => {}, [setEmail]);
  const formik = useFormik({
    initialValues: {
      nom: "",
      prenom: "",
      email: "",
      password: "",
      telephone: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      const register = async (user) => {
        try {
          const res = await axiosInstance.post(`inscription`, user);
          if (res) {
            return res?.data;
          }
        } catch (error) {
          return;
        }
      };
      register(values).then((response) => {
        console.log(response);
        // const { email } = response.data;
        localStorage.setItem(
          "tokens",
          JSON.stringify({
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
          })
        );
        setRegisterConfirm(true);
        //  sendMail(email, "Validation d'inscription a digireporting").then((res) => {
        // 	 console.log(res);
        //  })
      });
    },
  });
  const handleConfirm = (event) => {
    console.log(event);
    setRegisterConfirm(false);
  };
  const handleCancel = (event) => {
    console.log(event);
    setRegisterConfirm(false);
  };
  return (
    <>
      {/* LOGIN TITLE */}
      {!registerConfirm ? (
        <>
          <h3 className="LoginForm-Title">Inscrivez-vous !</h3>
          <form
            id="LoginForm"
            className="LoginForm"
            onSubmit={formik.handleSubmit}
          >
            {/* LOGIN INPUTS */}
            <div className="Input-Row">
              <i className="uil uil-user"></i>
              <input
                type="text"
                name="nom"
                id="nom"
                placeholder="Nom"
                className="DRInput-Class"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nom}
              />
              {formik.touched.nom && formik.errors.nom ? (
                <div className="text-danger text-right">
                  {formik.errors.nom}
                </div>
              ) : null}
            </div>
            <div className="Input-Row">
              <i className="uil uil-user"></i>
              <input
                type="text"
                name="prenom"
                id="prenom"
                placeholder="Prenom"
                className="DRInput-Class"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.prenom}
              />
              {formik.touched.prenom && formik.errors.prenom ? (
                <div className="text-danger text-right">
                  {formik.errors.prenom}
                </div>
              ) : null}
            </div>
            <div className="Input-Row">
              <i className="uil uil-envelope"></i>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Adresse e-mail"
                className="DRInput-Class"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger text-right">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
            <div className="Input-Row">
              <i className="uil uil-lock-alt"></i>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Mot de passe"
                className="DRInput-Class"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="text-danger text-right">
                  {formik.errors.password}
                </div>
              ) : null}
            </div>
            <div className="Input-Row">
              <i className="uil uil-phone"></i>
              <input
                type="text"
                name="telephone"
                id="telephone"
                placeholder="Numeoro de telephone"
                className="DRInput-Class"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.telephone}
              />
              {formik.touched.telephone && formik.errors.telephone ? (
                <div className="text-danger text-right">
                  {formik.errors.telephone}
                </div>
              ) : null}
            </div>
            {/* LOGIN SUBMIT */}
            <button
              type="submit"
              name="FormLogin-Submit"
              className="Form-Submit"
            >
              <i className="uil uil-user-check"></i> S'inscrire
            </button>
          </form>
        </>
      ) : (
        <Card>
          <Card.Body>
            <Card.Title className="LoginForm-Title">
              Votre inscription est bien enregistree
            </Card.Title>
            <Card.Text>
              <p>
                {" "}
                Un e-mail automatique de confirmation vient d'etre envoye a
                votre adresse email : {email}{" "}
              </p>
              <p>
                L'adresse est incorrecte ? Corrigez-la en fermant cette fenetre
                et saisissait une nouvelle adresse.
              </p>
              <p>
                Vous ne retrouvez pas notre e-mail de confirmation? Merci de
                verifier si vous ne l'avez pas recu parmis vos "Spam".
              </p>
            </Card.Text>
            <Card.Link
              as="button"
              onClick={handleCancel}
              className="btn btn-danger"
            >
              Annuler
            </Card.Link>
            <Card.Link
              as="button"
              onClick={handleConfirm}
              className="btn btn-primary"
            >
              Confirmer
            </Card.Link>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default RegisterForm;
