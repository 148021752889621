import React from "react";
export class Thumb extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) { return; }

    const {file} = nextProps;
    if (typeof file === 'object') {
      this.setState({ loading: true }, () => {
        let reader = new FileReader();
  
        reader.onloadend = () => {
          this.setState({ loading: false, thumb: reader.result });
        };
  
        reader.readAsDataURL(nextProps.file);
      });
    } else {
      this.setState({ loading: false, thumb: file });
    }
  }

  render() {
    const { file, width, height, className="img-thumbnail mt-2" } = this.props;
    const { loading, thumb } = this.state;

    if (!file) { return null; }

    if (loading) { return <p>loading...</p>; }

    return (<img src={thumb}
      alt={file.name}
      className={className}
      height={height}
      width={width} />);
  }
}

export function Image({fileName, height=200, width=200, className="img-thumbnail mt-2"}) {
  return (
    <img src={fileName}
    alt={fileName}
    className={className}
    height={height}
    width={width} />
  )
}
