import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { PieChart } from "../../modules/components";
const digitalOceanStorageUrl = "https://storage.digireporting.app/";

export function StartupDetails ({ startup, identifiant }) {
  const [missions, setmissions] = useState([])

  useEffect(() => {
    setmissions([
      {
        id: 1,
        date: "10/02/2021",
        title: "Business Model",
        participantMemeber: "20",
        done: false
      },
      {
        id: 2,
        date: "23/01/2021",
        title: "Business Model",
        participantMemeber: "10",
        done: true
      },
      {
        id: 3,
        date: "16/01/2021",
        title: "Business Model",
        participantMemeber: "15",
        done: false
      },
      {
        id: 4,
        date: "03/01/2021",
        title: "Business Model",
        participantMemeber: "5",
        done: true
      }
    ])
  }, [])

  return (
    <>
      <div className="row">
          <div className="DetailsPage-Container">
            {startup && <div className="DetailsPage-Cover" style={{ backgroundImage: `url(${digitalOceanStorageUrl}${startup?.couverture})`}}>
              <div className="StartUPInfos-Header">
                <img  id="imageProfile" className="StartUPInfos-Thumb" src={digitalOceanStorageUrl + startup?.logo} alt={startup?.nom}/>
                <div className="StartUPInfos-Name">{ startup?.nom }</div>
                <div className="StartUPInfos-Identiant">@{ identifiant }</div>
                <button className="ToggleProfile-Infos" type="button" name="button">Plus d'infos</button>
              </div>
            </div>}
            <div className="DetailsPage-Content">
              <div className="DetailsPage-Desc">
                <h3>{ startup?.nom }</h3>
                <p>{ startup?.presentation }</p>
              </div>
              
              <div className="MessionsBOX">
                    <div className="MessionsBOX-Header">
                      <h4>Missions</h4>
                      <div className="FilterForm">
                        <div className="FilterRow">

                          {/* START INPUT ROW */}
                          <div className="Input-Row Input-Select">
                            <select className="SelectFilter LightedColor" name="" defaultValue={"DEFAULT"}>
                              <option value="DEFAULT">Chiffre d’affaires</option>
                              <option value="Janvier">Janvier 2021</option>
                              <option value="Fevrier">Fevrier 2021</option>
                              <option value="Mars">Mars 2021</option>
                            </select>
                          </div>
                          {/* END INPUT ROW */}
                        </div>
                      </div>
                    </div>
                    <div className="MissionsContainer">
                      {missions.map((mission) => (
                        <div key={mission.id} className="MissionBOX" data-toggle="tooltip" data-placement="bottom" title={`Mission ${mission.done ? "" : "non"} effectué`}>
                          <label className={`MissionBOX-${mission.done ? "Done" : "NotDone"}`}><i className={`uil uil-${mission.done ? "check" : "multiply"}`}></i></label>
                          <span className="MissionBOX-Date">{mission.date}</span>
                          <h5 className="MissionBOX-Title">{mission.title}</h5>
                          <div className="ParticipantsMembers">
                            <span className="ParticipantMemeber"></span>
                            <span className="ParticipantMemeber"></span>
                            <span className="ParticipantMemeber"></span>
                            <span className="ParticipantMemeber">{"+" + mission.participantMemeber}</span>
                          </div>
                        </div>                        
                      ))}
                    </div>
                  </div>

                  <div className="StartUP-BOXES">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="ReportBOX Participations">
                          <div className="ReportBOX-Header"><h4 className="ReportBOX-HeaderTitle"><i className="uil uil-presentation"></i> Participation aux atéliers</h4></div>
                          <div className="ReportBOX-Body">
                            <div id="ReportPie" style={{ width: "100%" }} >
                              <PieChart />
                            </div>
                            <div className="BudgetStatus">
                              <label className="BudgetStatus-Expend"><span className="BudgetStatus-ExpendValue">$6 500</span></label>
                              <label className="BudgetStatus-Rest"><span className="BudgetStatus-ExpendRest">$3 000</span></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="TeamBOX">
                          <div className="TeamBOX-Header">
                            <h4 className="TeamBOX-HeaderTitle"><i className="uil uil-users-alt"></i> Equipe</h4>
                            <button type="button" name="button" className="MembersList" data-toggle="modal" data-target="#ModalMembers">Tous les membres</button>
                          </div>
                          <ul className="TeamBOX-List">
                            {startup.teams.map((team) => (
                              <li key={team.id} className="TeamBOX-ListItem">
                                <div className="TeamBOX-MemberAvatar"></div>
                                <div className="TeamBOX-MemberDetails">
                                  <div className="MemberDetails-Name">{team.name}</div>
                                  <span className="MemberDetails-Email">{team.email}</span>
                                  <span className="MemberDetails-Function">{team.role}</span>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

            </div>
          </div>

          <div className="DetailsPage-Sidebar">
            <div className="CloseToggleProfile">
              <button type="button" className="CloseToggleProfile-BTN"><i className="uil uil-times"></i></button>
            </div>
            <div className="StartUPInfos-Header">
              <img  id="imageProfile" className="StartUPInfos-Thumb" src={digitalOceanStorageUrl + startup?.logo} alt={startup?.nom}/>
              <div className="StartUPInfos-Name">{ startup?.nom }</div>
              <div className="StartUPInfos-Identiant">@{ identifiant }</div>
            </div>
            <div className="StartUPInfos-Body">
              <ul className="StartUPInfos-List">
                <li className="StartUPInfos-Item">
                  <div className="StartUPInfos-ItemIcon"><i className="uil uil-cog"></i></div>
                  <div className="StartUPInfos-ItemContent">
                    <label className="StartUPInfos-ItemLabel">Activité</label>
                    <span className="StartUPInfos-ItemValue">{ startup?.activite }</span>
                  </div>
                </li>
                <li className="StartUPInfos-Item">
                  <div className="StartUPInfos-ItemIcon"><i className="uil uil-crosshair-alt"></i></div>
                  <div className="StartUPInfos-ItemContent">
                    <label className="StartUPInfos-ItemLabel">secteur d’activité</label>
                    <span className="StartUPInfos-ItemValue">{ startup?.secteur } </span>
                  </div>
                </li>
                <li className="StartUPInfos-Item">
                  <div className="StartUPInfos-ItemIcon"><i className="uil uil-map-marker"></i></div>
                  <div className="StartUPInfos-ItemContent">
                    <label className="StartUPInfos-ItemLabel">Lieu de création</label>
                    <span className="StartUPInfos-ItemValue">{ startup?.lieu }</span>
                  </div>
                </li>
                <li className="StartUPInfos-Item">
                  <div className="StartUPInfos-ItemIcon"><i className="uil uil-calendar-alt"></i></div>
                  <div className="StartUPInfos-ItemContent">
                    <label className="StartUPInfos-ItemLabel">Date de création</label>
                    <span className="StartUPInfos-ItemValue">{ moment(new Date(startup?.creation)).format("DD/MM/YYYY") }</span>
                  </div>
                </li>
              </ul>

              <ul className="StartUPInfos-Contact">
                <h4>Contacts</h4>
                <li className="StartUPInfos-ContactItem"><i className="uil uil-globe"></i> <a href={startup?.site}>{ startup?.site }</a></li>
                <li className="StartUPInfos-ContactItem"><i className="uil uil-envelope"></i> <a href="mailto:contact@fundcorp.ma">{ startup?.email }</a></li>
                <li className="StartUPInfos-ContactItem GrayLink"><i className="uil uil-phone-alt"></i> <a href="tel:06 79 76 23 98">{ startup?.telephone }</a></li>
                <li className="StartUPInfos-ContactItem GrayLink"><i className="uil uil-facebook-f"></i> <a href="#!">facebook.com/{ identifiant }</a></li>
                <li className="StartUPInfos-ContactItem GrayLink"><i className="uil uil-twitter"></i> <a href="#!">twitter.com/{ identifiant }</a></li>
              </ul>
            </div>

            <div className="StartUPInfos-Footer">
              <label>Infos légales</label><span><br/>RC: { startup?.numRC } - IF { startup?.numIF }</span>
            </div>
          </div>
        </div>
    </>
  )
}
