import React, {useState, useEffect} from "react";

const ConfirmationDialog = () => {
	const [email, setEmail] = useState('dialloimran@gmail.com');
	useEffect(() => {
  }, [setEmail]);
   return (
		 <div className="text-center">
				{/* Confirmation TITLE */}
				<h3 className="LoginForm-Title" style={{ fontSize: "20px"}}>
					<i className="uil uil-at"></i> Votre inscription est bien enregistree !
				</h3>
				<div>
					<p> Un e-mail automatique de confirmation vient d'etre envoye a votre adresse email : {email}  </p>
					<p>
						Si cela ne fonctionne pas, copiez et collez le lien suivant dans votre navigateur : https://same-link-as-button.url/xxx-xxx-xxxx
					</p>
				</div>
      </div>
   );
 };

export default ConfirmationDialog;