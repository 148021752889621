import React from 'react'
import { Modal } from 'react-bootstrap'

export function ModalContent ({children, title='', show, onHide, className="", fullscreen="false", size='lg'}) {
  return (
    <Modal backdrop="static" keyboard={false} fullscreen={fullscreen} show={show} onHide={onHide} size={size}>
      <div className="DigiReprting-Modal modal-dialog-centered" role="document">
        <div className={`modal-content ${className}`}>
          <div className="modal-body">
            <div className="SettingCol-Header">
              <div className="SettingCol-Row">
                <h4 className="SettingCol-HeaderTitle">{title}</h4>
                <button type="button" className="close" onClick={onHide}>
                  <i className="uil uil-times"></i>
                </button>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}