/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Suspense, useEffect } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import ConfirmationDialog from "./components/ConfirmationDialog";
import ForgotPasswordForm from "./components/ForgotPasswordForm";
import { LoginAside, LogoutAside, RegisterAside, SocialOption, } from './components/UIHepers';
import { CustomSlider } from "../../components";
import { Programme } from "./Programme";

function AuthPageRender({isAuth, children}) {
  return (
    <Suspense>
      <div id="LoginPage">
        <div className="container-fluid">
          <div className="row">
            {/* LOGIN FORM SIDE */}
            <div className="col-md-6">
              <div className="row">
                <div className="LoginForm-SideContent">
                  <div className="LoginForm-Side">
                    <div className="LoginForm-Content">
                      {/* DIGI REPORTING BRAND */}
                      <div className="DigiReporing-Brand">
                        <img src="assets/media/brand/DigiReporting-Brand.svg" alt=""/>
                      </div>
                      {children}
                      {/* COPY RIGHTS */}
                      <div className="CopyRight-Text">
                        <span>@2020 Tous droits réservés. DigiReporting</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* FEATURES SIDE */}
            <div className="d-none d-sm-block col-md-6">
              <div className="Side-NoMargin">
                <div className="Features-Side">
                  <div className="FeaturesSide-Content">
                    {/* CREATE AN ACCOUNT */}
                    { isAuth ?
                      <Switch>
                        <Route path="/login" component={LoginAside} />
                        <Route path={["/register", "/confirmation", "/forgot-password"]} component={RegisterAside}
                        />
                      </Switch> : <LogoutAside />
                    }
                    {/* FEATURES CAROUSEL */}
                    <CustomSlider />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export function AuthPage({hasNoprogram}) {
  // useEffect(() => {
  //   console.log("AuthPage isAuthenticated ", isAuthenticated);
  //   const script = document.createElement('script');
  //   if(!isAuthenticated) {
  //     script.src = "/assets/js/scripts.js";
  //     document.body.appendChild(script);
  //   }
  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, [isAuthenticated]);

  useEffect(() => {
    console.log('AuthPage rendering ...');
  }, [])


  if(hasNoprogram) {
    return (
      <AuthPageRender isAuth={false}>
        <Programme />
      </AuthPageRender>
    )
  }

  return (
    <AuthPageRender isAuth={true}>
      <Switch>
        <Route path="/login"
          render={(props) => <LoginForm {...props} />}
        />
        <Route
          path="/register"
          component={RegisterForm}
        />
        <Route
          path="/confirmation"
          component={ConfirmationDialog}
        />
        <Route
          path="/forgot-password"
          component={ForgotPasswordForm}
        />
        <Redirect to="/login" />
      </Switch>

      {/* LOGIN SOCIAL OPTIONS */}
      <Switch>
        <Route path={["/login"]} component={SocialOption} />
      </Switch>
    </AuthPageRender>
  );
}
