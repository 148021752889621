import React from "react";

class Rentabilite extends React.Component
{
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      donnees: [
        {
            "titre": "(70±71) Ventes - Chiffre d'affaires",
            "valeur": "13 000",
            isResult: false,
            resultats: [
              {
                "titre": "(60±603) Achats - Coûts des produits vendus",
                "valeur": "-4 900 ",
                isResult: false
              }
            ]
        },
        {
            "titre": "Marge brute",
            "valeur": "8 100 ",
            isResult: false,
            resultats: [
              {
                  "titre": "(61+62) Autres charges externes",
                  "valeur": "-1 850 "
              },
            ]
        },
        {
            "titre": "Valeur ajoutée",
            "valeur": "6 250 ",
            isResult: false,
            resultats: [
              {
                  "titre": "(74) Subventions d'exploitation",
                  "valeur": "800",
                  isResult: false
              },
              {
                  "titre": "(63) Impôts, taxes et versements assimilés",
                  "valeur": "-300",
                  isResult: false
              },
              {
                  "titre": "(64) Charges de personnel",
                  "valeur": "-1 000 ",
                  isResult: false
              },
            ]
        },
        {
            "titre": "Exédent brut d'exploitation",
            "valeur": "5 750 ",
            isResult: false,
            resultats: [
              {
                  "titre": "(75 sauf 755) Autres produits de gestion courante",
                  "valeur": "3 000 ",
                  isResult: false
              },
              {
                  "titre": "(781) Reprises sur amortissements, dépréciations et provisions",
                  "valeur": "0",
                  isResult: false
              },
              {
                  "titre": "(791) Transferts de charges d&apos;exploitation",
                  "valeur": "0",
                  isResult: false
              },
              {
                  "titre": "(65 sauf 655) Autres charges de gestion courante",
                  "valeur": "-800",
                  isResult: false
              },
              {
                  "titre": "(681) Dotations aux amortissements, dépréciations et provisions",
                  "valeur": "0",
                  isResult: false
              },
            ]
        },
        {
            "titre": "Résultat d'exploitation",
            "valeur": "7 950 ",
            isResult: false,
            resultats: [
              {
                  "titre": "(755) Quote-part de résultat sur opérations faites en commun",
                  "valeur": "450",
                  isResult: false
              },
              {
                  "titre": "(76) Produits financiers",
                  "valeur": "350",
                  isResult: false
              },
              {
                  "titre": "(786) Reprises sur dépréciations et provisions",
                  "valeur": "0",
                  isResult: false
              },
              {
                  "titre": "(796) Transferts de charges financières",
                  "valeur": "0",
                  isResult: false
              },
              {
                  "titre": "(66) Charges financières",
                  "valeur": "-250",
                  isResult: false
              },
              {
                  "titre": "(686) Dotations aux amortissements, dépréciations et provisions",
                  "valeur": "0",
                  isResult: false
              },
            ]
        },
        {
            "titre": "Résultat financier",
            "valeur": "550",
            isResult: false,
            resultats: [
                {
                    "titre": "(77) Produits exceptionnels",
                    "valeur": "1 500 ",
                    isResult: false
                },
                {
                    "titre": "(787) Reprises sur dépréciations et provisions",
                    "valeur": "0",
                    isResult: false
                },
                {
                    "titre": "(797) Transferts de charges exceptionnelles",
                    "valeur": "0",
                    isResult: false
                },
                {
                    "titre": "(67) Charges exceptionnelles",
                    "valeur": "-670",
                    isResult: false
                },
                {
                    "titre": "(687) Dotations aux amortissements, dépréciations et provisions",
                    "valeur": "0",
                    isResult: false
                },
            ]
        },
        {
            "titre": "Résultat exceptionnel",
            "valeur": "830",
            isResult: false,
            resultats: []
        },
        {
            "titre": "RESULTAT NET",
            "valeur": "9 330 ",
            isResult: false,
            resultats: []
        }
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('Le nom a été soumis : ' + this.state.value);
    event.preventDefault();
  }

  render() {
    const { donnees } = this.state;
    return (
      <>
        <div className="BilanWrap">
          <div className="BilanRow">
            <div className="BilanRow-Items">
              {
                donnees.map((item) => (
                  <div key={item.titre}>
                    <div className="BilanRow-Item BilanResualts-Row">
                      <div className="BilanItem-Title">
                        <h4>{item.titre}</h4>
                      </div>
                      <div className="BilanItem-Value">
                        <div>{item.valeur} {/*<span className="Currency">MAD</span> */}</div>
                      </div>
                    </div>
                    {
                      item.resultats.map((result) => (
                        <div className="BilanRow-Item" key={result.titre}>
                          <div className="BilanItem-Title">{result.titre}</div>
                           <div className="BilanItem-Value">{result.valeur} {/*<span className="Currency">MAD</span> */}</div>
                        </div>
                      ))
                    }
                    
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Rentabilite;