import React from "react";

export default function Export () {
  // const types = ['Type', 'Type 1', 'Type 2', 'Type 3']
  return (
    <div className="Content-Container">

      <div className="PageHeader">
        <div className="row">
          <div className="col-8 col-md-4">
            <h3 className="PageHeader-Title">Exporter</h3>
          </div>
          <div className="col-4 col-md-8">
            <div className="FilterForm ButtonAction">
              <div className="FilterRow">

                {/* <!-- START INPUT ROW --> */}
                <div className="Input-Row">
                  <button type="button" className="Done-BTN" name="button"><i className="uil uil-export"></i> <span>Exporter</span></button>
                </div>
                {/* <!-- END INPUT ROW --> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="SettingsContent-Wrap">
        <div className="Export-Section">
          <div className="SettingsContent-Section">
            <div className="SettingsContent-SectionFields FieldsRow">
              <div className="Settings-Field Settings-Field-1">
                <div className="Settings-FieldRow Settings-FieldRow_Select LightBG">
                  <select className="FieldRow-Select" name="">
                    <option selected disabled>Document à exporter</option>
                    <option value="Français">Français</option>
                    <option value="Anglais">Anglais</option>
                  </select>
                </div>
              </div>
              <div className="Settings-Field Settings-Field-1">
                <div className="Settings-FieldRow Settings-FieldRow_Text Settings-FieldRow_Date LightBG date">
                  <input type="text" name="daterange" className="FieldText" placeholder="Période" value="" />
                </div>
              </div>
              <div className="Settings-Field Settings-Field-1">
                <div className="Custom-FieldRow Settings-FieldRow Settings-FieldRow_Text Settings-FieldRow_Tags LightBG">
                  <input type="text" className="FieldText FieldTags" placeholder="Choisir l’utilisateur" name="periode" data-role="tagsinput" value=""/>
                </div>
              </div>
              <div className="Settings-Field Settings-Field-1">
                <div className="Custom-FieldRow Settings-FieldRow Settings-FieldRow_Radio Settings-FieldRow_Date Label-PlaceHolder LightBG">
                  <label for="">Type</label>
                  <div className="FieldRow_RadioGroup">
                    <div className="FieldRow_Radio">
                      <input type="radio" name="pdf" id="pdf" value=""/>
                      <label for="pdf">PDF</label>
                    </div>
                    <div className="FieldRow_Radio">
                      <input type="radio" name="excel" id="excel" value=""/>
                      <label for="excel">EXCEL</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  )
}
