import React from "react";
import { PieChart } from "../modules/components";

import Activite from "./mission/Activite";
import Operation from "./mission/Operation";

class MyMissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      startups:[
        {
          id: "1",
          nom: "Houdane Consulting",
          couverture: "Houdane_Consulting-Preview.jpg",
          secteur: "Education",
          ca: "300 000$",
          status: true,
          annee: "2010"
        },
        {
          id: "2",
          nom: "Dadupa Consulting",
          couverture: "Houdane_Consulting-Preview.jpg",
          secteur: "Education",
          ca: "300 000$",
          status: false,
          annee: "2010"
        }
      ]
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
  }

  handleChange(event){
    console.log("date clicked");
  }

  render () {
    return (
        <div className="Settings-Container">
          <div className="row">
            <div className="SettingsRow">

              <div className="SettingsContent">
                <div className="PageHeader">

                  <div className="row">
                    <div className="col-8 col-md-4">
                      <h3 className="SettingsContent-Title">Mes Missions</h3>
                    </div>
                    <div className="col-4 col-md-8">
                      <div className="FilterForm">
                        <button className="MobileFilter" type="button" name="button"><i className="uil uil-filter"></i> Filter</button>
                        <div className="FilterRow">
                          <button className="CloseMobileFilter" type="button" name="button"><i className="uil uil-times"></i></button>

                          <div id="datepicker" className="Input-Row Input-Date">
                              <input className="InputField" type="text" name="daterange" placeholder="Période" value="" />
                          </div>

                        </div>
                      </div>


                    </div>
                  </div>
                </div>

                <div className="SettingsCols-Wrap">
                  <div className="SettingsCols-Row">
                    <div className="SettingCol-4 DisplayFlex">
                      <div className="SettingsStats DashboardStats-BOX FlexView">
                        <div className="BOX-Content">
                          <div className="BOX-Side-1">
                            <div className="BOX-Side-Content">
                              <div className="BOX-Side-Icon"><i className="uil uil-briefcase-alt"></i></div>
                              <span>29</span>
                              <label>Activités</label>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="SettingCol-8">
                      <div className="ReportBOX Participations">
                        <div className="ReportBOX-Body">
                          <h3 className="ReportBOX-BodyTitle">Les catégories <br/>des activités</h3>
                          <div id="ReportPie" style={{"width" : "100%"}}>
                            {/* <canvas id="chart-area"></canvas> */}
                            <PieChart />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              <div className="Tabs-Wraps">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="ActivitesTab" data-toggle="tab" href="#Activites" role="tab" aria-controls="Activites" aria-selected="true"><i className="uil uil-package"></i>Activités</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="OperationsTab" data-toggle="tab" href="#Operations" role="tab" aria-controls="Operations" aria-selected="false"><i className="uil uil-exchange-alt"></i> Opérations</a>
                  </li>
                </ul>
                <div className="tab-content TabContent" id="myTabContent">
                  <div className="tab-pane fade show active" id="Activites" role="tabpanel" aria-labelledby="ActivitesTab">

                    <div className="TableActions">
                      <div className="TableActions-Form">
                        <div className="TableActions-FormRowLeft">
                          <button type="button" className="Porpal-BTN" data-toggle="modal" data-target="#AddNewElement">Nouvel Activité</button>
                          <button type="button" className="White-BTN" data-toggle="modal" data-target="#EditElement"><i className="uil uil-pen"></i></button>
                          <button type="button" className="Red-BTN" data-toggle="modal" data-target="#DeleteAlert"><i className="uil uil-trash-alt"></i></button>
                        </div>
                        <div className="TableActions-FormRowRight">
                          <div className="TableActions-FormRow">

                          </div>
                        </div>
                      </div>
                    </div>

                    <Activite/>

                  </div>

                  <div className="tab-pane fade" id="Operations" role="tabpanel" aria-labelledby="OperationsTab">

                    <div className="TableActions">
                      <div className="TableActions-Form">
                        <div className="TableActions-FormRowLeft">
                          <div className="TableActions-FormRow">
                            <button type="button" className="Porpal-BTN" data-toggle="modal" data-target="#AddOperation">Nouvelle Opération</button>
                            <button type="button" className="White-BTN" data-toggle="modal" data-target="#EditOperation"><i className="uil uil-pen"></i></button>
                            <button type="button" className="Red-BTN" data-toggle="modal" data-target="#DeleteAlert"><i className="uil uil-trash-alt"></i></button>
                          </div>
                        </div>
                        <div className="TableActions-FormRowRight">

                        </div>
                      </div>
                    </div>

                    <Operation />
                  </div>
                </div>
              </div>


              </div>
            </div>
          </div>
        </div>
      )
    }
}

export default MyMissions;