import React, { forwardRef } from 'react';
import DataTable from "react-data-table-component";

const BootyRadio = forwardRef(({ onClick, ...rest }, ref) => (
  <div className="custom-control custom-checkbox">
    <input
      type="radio"
      className="custom-control-input"
      ref={ref}
      {...rest}
    />
    <label className="custom-control-label" onClick={onClick} />
  </div>
));

export function Table ({
  rows,
  columns,
  onChange,
  toggleCleared,
  selectableRows=true,
  conditionalRowStyles = [],
  rowDisabledCriteria = () => {}
}) {
  return (
    <>
      <DataTable
        columns={columns}
        noHeader
        data={rows}
        highlightOnHover
        pointerOnHover
        pagination
        selectableRows={selectableRows}
        selectableRowsHighlight
        selectableRowsComponent={BootyRadio}
        onSelectedRowsChange={onChange}
        clearSelectedRows={toggleCleared}
        selectableRowsNoSelectAll
        conditionalRowStyles={conditionalRowStyles}
        selectableRowDisabled={rowDisabledCriteria}
        // data-tag="allowRowEvents"
      />
    </>
  )
}
