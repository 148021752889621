import React from "react";
import { CustomSlider } from "../../modules/components";

class Create extends React.Component
{
  render() {
    return (
      <>
        <div id="LoginPage">
          <div className="container-fluid">
            <div className="row">

              {/* LOGIN FORM SIDE */}
              <div className="col-md-6">
                <div className="row">
                  <div className="LoginForm-SideContent">
                    <div className="LoginForm-Side">
                      <div className="LoginForm-Content">
                        {/* DIGI REPORTING BRAND */}
                        <div className="DigiReporing-Brand">
                          <img src="assets/media/brand/DigiReporting-Brand.svg" alt=""/>
                        </div>


                        <form id="LoginForm" className="LoginForm" action="#" method="post">
                          {/* LOGIN INPUTS */}
                          <fieldset className="Create-Programme">
                            {/* FIELDSET TITLE */}
                            <h3 className="LoginForm-Title">Créer ou rejoindre un programme</h3>

                            {/* <div className="Choices-Row"> */}
                            <button className="Create-BTN Next-FieldSet" type="button" name="button">
                              <i className="uil uil-plus"></i>
                              <label>Créer <br/>un programme</label>
                            </button>
                            <button className="Create-BTN JoinProgramme-BTN" type="button" name="button">
                              <i className="uil uil-users-alt"></i>
                              <label>Rejoindre <br/>un programme</label>
                            </button>
                            {/* </div> */}

                            {/* <button type="button" className="Prev-BTN Prev-FieldSet">previous step</button> */}

                          </fieldset>

                          <fieldset className="Create-Programme">

                            {/* FIELDSET TITLE */}
                            <h3 className="LoginForm-Title">Créer un programme</h3>

                            <div className="Input-Row">
                              <i className="uil uil-subject"></i>
                              <input type="text" name="user-name" id="user-name" placeholder="Nom du programme" className="DRInput-Class" required/>
                            </div>
                            <div className="Input-Row">
                              <i className="uil uil-layers"></i>
                              <input type="text" name="user-name" id="user-name" placeholder="Type de programme" className="DRInput-Class" required/>
                            </div>
                            <div className="Input-Row">
                              <i className="uil uil-calender"></i>
                              <input type="text" name="user-name" id="user-name" placeholder="Date de lancement du programme" className="DRInput-Class" required/>
                            </div>
                            <div className="Input-Row">
                              <i className="uil uil-calendar-alt"></i>
                              <input type="text" name="user-name" id="user-name" placeholder="Date de fin du programme" className="DRInput-Class" required/>
                            </div>

                            <button type="button" className="Prev-BTN Prev-FieldSet">Etape précédente</button>
                            <button type="button" className="Next-BTN Next-FieldSet">Créer <i className="uil uil-arrow-right"></i></button>

                          </fieldset>

                          <fieldset className="Join-Programme">

                            {/* FIELDSET TITLE */}
                            <h3 className="LoginForm-Title">Rejoindre un programme</h3>

                            <div className="Input-Row">
                              <i className="uil uil-user"></i>
                              <input type="text" name="user-name" id="user-name" placeholder="Identifiant du programme" className="DRInput-Class" required/>
                            </div>
                            <div className="Input-Row">
                              <i className="uil uil-lock-alt"></i>
                              <input type="password" name="user-password" id="user-password" placeholder="Mot de passe" className="DRInput-Class" required/>
                              <span toggle="#user-password" className="uil uil-eye field-icon toggle-password"></span>
                            </div>

                            {/* LOGIN SUBMIT */}
                            <button type="submit" name="FormLogin-Submit" className="Form-Submit Next-FieldSet"><i className="uil uil-user-check"></i> Rejoindre</button>

                          </fieldset>

                        </form>

                        {/* COPY RIGHTS */}
                        <div className="CopyRight-Text">
                          <span>@2020 Tous droits réservés. DigiReporting</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* FEATURES SIDE */}
              <div className="d-none d-sm-block col-md-6">
                <div className="Side-NoMargin">
                  <div className="Features-Side">
                    <div className="FeaturesSide-Content">
                      {/* CREATE AN ACCOUNT */}
                      <div className="CreateAccount-BTN">
                        <label>Déja inscrit ? </label>
                        <a href="/connexion">Connectez-vous</a>
                      </div>
                      {/* FEATURES CAROUSEL */}
                     <CustomSlider />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Create;