import React from "react";

// reactstrap components

// core components
import { Link } from "react-router-dom";
import "./NotFound.css"
function NotFound() {
  return (
    <>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>Oops!</h1>
          </div>
          <h2>404</h2>
          <p>Cette page n'existe pas</p>
          <Link to="/connexion">Revenir a la page d'accueil</Link>
        </div>
      </div>
    </>
  );
}

export default NotFound;
