import React, { useCallback, useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { UserContext, ProgrammeContext } from "../../contexts/UserContext";
import { LoaderButton, ModalContent, Thumb } from "../../modules/components";
import { AccessForm, AccountForm } from "./profile/";
import useAxiosInterceptors from "../../hooks/httpConfig";

// import { DatePicker } from 'rsuite';

const UserSchema = Yup.object().shape({
  nom: Yup.string().required('Le nom est obligatoire'),
});
const ProfileSchema = Yup.object().shape({
  profile: Yup.mixed().required("L'image de profile est obligatoire"),
});
const Profile = () => {
  const apiInstance = useAxiosInterceptors();
  const [user, setUser] = useContext(UserContext);
  const [projet] = useContext(ProgrammeContext);
  const name = user ? `${user.nom} ${user.prenom}`: ``;
  const [readOnly] = useState(true)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showProfile, setShowProfile] = useState(false)
  const [showAccess, setShowAccess] = useState(false)
  const [alert, setAlert] = useState(null)
  const handleCloseEditModal = useCallback(() => setShowEdit(false), [])
  const handleShowEditModal = useCallback(() => setShowEdit(true), [])
  const handleShowProfileModal = useCallback(() => setShowProfile(true), [])
  const handleCloseProfileModal = useCallback(() => {
    setShowProfile(false)
  }, [])
  const handleCloseAccessModal = useCallback(() => setShowAccess(false), [])
  const handleShowAccessModal = useCallback(() => setShowAccess(true), [])

  const loadData = useCallback(() => {
    setIsSubmiting(false)
    setTimeout(function(){
      setAlert(null)
      handleCloseProfileModal()
    }, 1000);
  }, [handleCloseProfileModal])

  const handleProfileSubmit = useCallback((values) => {
    setIsSubmiting(true)
    const { profile } = values
    if(profile) {
      const reader = new FileReader();
      reader.readAsDataURL(profile);
      reader.onload = function () {
        if(reader?.result) {
          console.log('reader?.result :::: ', reader?.result);
          apiInstance.patch(`/utilisateur/editProfile/${user?._id}`, { profile: reader.result })
            .then(response => {
              setAlert({
                variant: 'success',
                message: "Votre photo de profil a été modifié avec succès"
              });
              setUser({
                ...user,
                profile: reader?.result
              })
              loadData()
            })
        }
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }, [apiInstance, loadData, setUser, user])
  // const [show, setShow] = useState(false)
  const updateUser = (values) => {
    console.log(values);
  }

  useEffect(() => {
    console.log('useEffect Profile ============ ', user);
    setAlert(null)
  }, [user]);

  // const close = () => {
  //   setShow(false);
  // }
  // const open = () => {
  //   setShow(true);
  // }

  return (
    <>
      { user && projet &&
       <>
        <div className="Settings-Container">
          <div className="row">
            <div className="SettingsRow">
              <div className="SettingsContent">
                  <Formik
                    initialValues={{
                      id: user._id,
                      nom: user.nom,
                      prenom: user.prenom,
                      email: user.email,
                      telephone: user.telephone,
                      dateNaissance: new Date(),
                      pays: user?.pays
                    }}
                    validationSchema={UserSchema}
                    onSubmit={updateUser}
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form id="FollowUp-Form" className="FollowUp-Form">
                        <Field name="id" type="hidden"/>
                        <div className="PageHeader">
                          <div className="UserProfile-Update cursorPointer" onClick={handleShowProfileModal}>
                            <div className="UserProfile-UpdateThumb" style={{ backgroundSize: 'cover', backgroundImage: `url(${user?.profile})`}}>
                              <button type="button" name="button"><i className="uil uil-camera"></i></button>
                            </div>
                            <div className="UserProfile-UpdateInfo">
                              <div className="UserProfile-UserName">{name}</div>
                              <div className="UserProfile-UserRole">{projet.programme.nom}</div>
                            </div>
                          </div>
                        </div>
                        {/* <Button onClick={open}> Open</Button> */}
                        <div className="SettingsCols-Wrap">
                          <div className="SettingsCols-Row">
                            <div className="SettingCol-6">
                              <div className="Col-Padding_15 WhiteBG">

                                <div className="SettingCol-Header">
                                  <h4 className="SettingCol-HeaderTitle">Mon Compte</h4>
                                  <button title="Modifier"
                                    className="ProfileEdit-BTN"
                                    type="button"
                                    name="button"
                                    onClick={handleShowEditModal}
                                  ><i className="uil uil-pen"></i></button>
                                </div>
                                <div className="SettingCol-Row">
                                  <div className="Settings-Field Settings-Field-6">
                                    <label htmlFor="nom">Nom</label>
                                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                                      <Field name="nom"
                                        placeholder="Nom"
                                        className="FieldText"
                                        disabled={readOnly}
                                      />
                                    </div>
                                  </div>
                                  <div className="Settings-Field Settings-Field-6">
                                    <label htmlFor="prenom">Prénom</label>
                                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                                      <Field name="prenom"
                                        placeholder="Prénom"
                                        className="FieldText"
                                        disabled={readOnly}
                                      />
                                    </div>
                                  </div>
                                  <div className="Settings-Field Settings-Field-1">
                                    <label htmlFor="email">Adresse e-mail</label>
                                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                                      {/* <label htmlFor="id">Identifiant unique</label> */}
                                      <Field name="email"
                                        placeholder="Identifiant unique"
                                        className="FieldText"
                                        disabled={readOnly}
                                      />
                                    </div>
                                  </div>
                                   <div className="Settings-Field Settings-Field-1">
                                    <label htmlFor="dateNaissance">Date de naissance</label>
                                    <div className="Settings-FieldRow Settings-FieldRow_Text Settings-FieldRow_Date LightBG date">
                                      <Field name="dateNaissance"
                                          placeholder="Date de naissance"
                                          className="FieldText"
                                          value="10/24/1984"
                                          disabled={readOnly}
                                        />
                                      <span className="input-group-addon"><i className="uil uil-calendar-alt"></i></span>
                                    </div>
                                  </div>
                                  <div className="Settings-Field Settings-Field-1">
                                    <label htmlFor="pays">Pays</label>
                                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                                      <Field name="pays"
                                        placeholder="Pays"
                                        className="FieldText"
                                        disabled={readOnly}
                                      />
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div className="SettingCol-6">
                              <div className="Col-Padding_15 WhiteBG Height-100">

                                <div className="SettingCol-Header">
                                  <h4 className="SettingCol-HeaderTitle">Mes Accès</h4>
                                  <button className="ProfileEdit-BTN" type="button" name="button" 
                                    onClick={handleShowAccessModal}
                                  ><i className="uil uil-pen"></i></button>
                                </div>

                                <div className="SettingCol-Row">
                                  <div className="Settings-Field Settings-Field-1">
                                    <label htmlFor="email">Adresse e-mail</label>
                                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                                      {/* <input type="email" className="FieldText" placeholder="Email" name="periode" value=""/> */}
                                      <Field name="email"
                                        placeholder="Email"
                                        className="FieldText"
                                        disabled={readOnly}
                                      />
                                    </div>
                                  </div>
                                  <div className="Settings-Field Settings-Field-1">
                                    <label htmlFor="password">Mot de passe</label>
                                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                                      <Field name="password"
                                        type="password"
                                        placeholder="Mon mot de passe"
                                        className="FieldText"
                                        disabled={readOnly}
                                      />
                                    </div>
                                  </div>

                                </div>
                                {
                                  !user?.active && 
                                  <div className="EmailAlert">
                                    <div className="EmailAlert-Text"><p><i className="uil uil-exclamation-triangle"></i> Votre email n’est pas confirmé</p></div>
                                    <div className="EmailAlert-Action"><button className="EmailConfirmation-BTN" type="button" name="button">Confirmer</button></div>
                                  </div>
                                }
                                
                              </div>
                            </div>

                        </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
              </div>
            </div>
          </div>
        </div>

        <ModalContent show={showProfile} onHide={handleCloseProfileModal} title="Ajouter une photo de profile">
          <Formik
            initialValues={{
              profile: null,
            }}
            validationSchema={ProfileSchema}
            onSubmit={handleProfileSubmit}>
            {({ values, handleSubmit, setFieldValue }) => {
              const handleLogoChange = (event) => {
                setFieldValue("profile", event.currentTarget.files[0]);
                console.log('Logo => ', event.currentTarget.files[0]);
              };

              return (
                <Form onSubmit={handleSubmit}>
                  <div className="SettingCol-Row">
                    <div className="Settings-Field Settings-Field-1" style={{cursor: 'pointer'}}>
                      <div className="Settings-FieldRow Settings-FieldRow_FileUpload Label-PlaceHolder LightBG">
                        <label>Ajoutez votre profile</label>
                        <div className="FieldRow_Upload">
                          <div className="FieldRow_UploadBTN">
                            <input id="profile" name="profile" type="file" onChange={handleLogoChange} className="form-control" />
                            <span style={{cursor: 'pointer'}}><i className="uil uil-camera"></i></span>
                          </div>
                        </div>
                      </div>
                      <ErrorMessage component="div" className="text-danger" name="profile" />
                      { values?.profile && <Thumb file={values?.profile} height={100} width={100} />}
                    </div>
                    <div className="Settings-Field Settings-Field-1">
                      <div className="row">
                        <div className="col-md-12 text-right">
                          <button onClick={handleCloseProfileModal} type="button" className="btn Cancel-BTN">Annuler</button>
                          {/* <button type="submit" className="btn Save-BTN">Sauvegarder</button> */}
                          <button type="submit" className="btn Save-BTN">{isSubmiting ? <LoaderButton width={100} height={30} type="bars" color="#fff" /> : 'Sauvegarder'}</button>
                        </div>
                      </div>
                    </div>
                    {
                      alert &&
                      <div className="Settings-Field Settings-Field-1"> 
                        <div className={`alert alert-${alert.variant}`} role="alert">
                          {alert.message}
                        </div>
                      </div>
                    }
                  </div>
                </Form>
              )
            }}
          </Formik>
        </ModalContent>
      {/* <!-- Modal EditAccount--> */}
      <ModalContent show={showEdit} onHide={handleCloseEditModal} title="Mon profil">
        <AccountForm user={user} onHide={handleCloseEditModal} alert={alert} setAlert={setAlert}/>
      </ModalContent>
      {/* <!-- Modal Edit Accès --> */}
      <ModalContent show={showAccess} onHide={handleCloseAccessModal} title="Mofifier mon mot de passe">
        <AccessForm user={user} onHide={handleCloseAccessModal} alert={alert} setAlert={setAlert} />
      </ModalContent>
        </>
      }
    </>
  )
}

export default Profile
