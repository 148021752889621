import React, { useCallback } from 'react'
import { Formik, ErrorMessage, Form, Field } from 'formik';
import * as Yup from 'yup';

import useAxiosInterceptors from '../../../hooks/httpConfig';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Le mot de passe est obligatoire"),
  password: Yup.string().required("Le nouveau mot de passe est obligatoire"),
  passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], "Les mot de passes doivent etre identique")
});
export function AccessForm({user, onHide, alert, setAlert}) {
  const apiInstance = useAxiosInterceptors();

  const handleUpdate = useCallback((values) => {
    console.log('values ', values);
    console.log('user => ', user);
    if(user?._id) {
      console.log('values ', values);
      apiInstance.post(`/utilisateurs/updatePassword`, {
        email: values?.email,
        password: values?.oldPassword,
        newPassword: values?.password
      }).then(({data}) => {
        setAlert({
          variant: 'success',
          message: "Mot de passe modifié avec succès"
        });
        setTimeout(function(){
          setAlert(null);
          onHide()
        }, 1000);
      }).catch(() => {
        setAlert({
          message: "Impossible de modifier le mot de passe",
          variant: "danger"
        })
      })
    }
  }, [apiInstance, onHide, setAlert, user])

  return (
    <Formik
      initialValues={{
        email: user?.email || '',
        oldPassword: '',
        password: '',
        passwordConfirm: ''
      }}
      validationSchema={validationSchema}
      onSubmit={handleUpdate}
    >
      {({ handleSubmit, isValid }) => (
        <Form onSubmit={handleSubmit}>
          <div className="SettingCol-Row">
            {/* <div className="Settings-Field Settings-Field-1">
              <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                <Field name="email"
                  placeholder="Email"
                  className="FieldText"
                />
              </div>
            </div>
            <div className="Settings-Field Settings-Field-1">
              <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                <Field name="emailConfirm"
                  placeholder="Confirmer nouvel email"
                  className="FieldText"
                />
              </div>
            </div> */}
            <div className="Settings-Field Settings-Field-1">
              <label>Ancien mot de passe</label>
              <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                <Field name="oldPassword" type="password"
                  placeholder="Saisir votre mot de passe actuel"
                  className="FieldText"
                />
              </div>
              <ErrorMessage component="div" className="text-danger" name="oldPassword" />
            </div>
            <div className="Settings-Field Settings-Field-1">
              <label>Nouveau mot de passe</label>
              <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                <Field name="password" type="password"
                  placeholder="Saisir un nouveau mot de passe"
                  className="FieldText"
                />
              </div>
              <ErrorMessage component="div" className="text-danger" name="password" />
            </div>
            <div className="Settings-Field Settings-Field-1">
              <label>Confirmation du nouveau mot de passe</label>
              <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                <Field name="passwordConfirm" type="password"
                  placeholder="Confirmer le mot de passe saisi"
                  className="FieldText"
                />
              </div>
              <ErrorMessage component="div" className="text-danger" name="passwordConfirm" />
            </div>
            <div className="Settings-Field Settings-Field-1">
              <div className="row">
                <div className="col-md-12 text-right">
                  <button onClick={onHide} type="button" className="btn Cancel-BTN">Annuler</button>
                  <button disabled={!isValid} type="submit" className="btn Save-BTN">Sauvegarder</button>
                </div>
              </div>
            </div>
            {
              alert &&
              <div className="Settings-Field Settings-Field-1"> 
                <div className={`alert alert-${alert.variant}`} role="alert">
                  {alert.message}
                </div>
              </div>
            }
          </div>
        </Form>
      )}
    </Formik>
  )
}