import React from 'react';
import DataTable from "react-data-table-component";

const BootyRadio = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="custom-control custom-checkbox">
    <input
      type="radio"
      className="custom-control-input"
      ref={ref}
      {...rest}
    />
    <label className="custom-control-label" onClick={onClick} />
  </div>
));

export class Datatable extends React.Component {

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    
  }

  handleChange(event) {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', event);
  };

  render() {
    const { rows, columns } = this.props;
    return (
      <>
        <DataTable
          columns={columns}
          // disabled
          noHeader
          data={rows}
          // fixedHeader
          highlightOnHover
          pointerOnHover
          pagination
          selectableRows
          selectableRowsHighlight
          selectableRowsComponent={BootyRadio}
          onSelectedRowsChange={this.handleChange}
          // onRowClicked={this.handleChange}
          selectableRowsNoSelectAll
          // data-tag="allowRowEvents"
        />
      </>
    )
  }
}
