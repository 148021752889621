import React, { Fragment } from 'react'

export function EditAndDeleteButton ({selectedRows, handleEditClick, handleDeleteClick}) {
  return (
    <Fragment>
      {
        selectedRows && selectedRows.length === 1 ?
        <>
          <button type="button" className="White-BTN" onClick={(event)=> handleEditClick(selectedRows[0])}><i className="uil uil-pen"></i></button>
          <button type="button" className="Red-BTN" onClick={(event)=> handleDeleteClick(selectedRows[0])}><i className="uil uil-trash-alt"></i></button>
        </> : null
      }
    </Fragment>
  )
}