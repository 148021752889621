import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export function PhoneField({name, label, value, onChange}) {
  return (
    <>
      {label && <label>{label}</label>}
      <div className="Settings-FieldRow Settings-FieldRow_Text LightBG" style={{ padding: '0.5em' }}>
        <PhoneInput
          name={name}
          value={value}
          placeholder={label}
          country={'ma'}
          onChange={onChange}
          inputStyle={{
            background: '#FAF2FF',
            width: '100%',
            border: '2px solid #FAF2FF'
          }}
        />
      </div>
    </>
  )
}