import React, { useCallback, useContext, useEffect, useState } from "react";
import { StartupList } from "../components/StartupList";
import { ProgrammeContext } from "../contexts/UserContext";
import { USER_ROLE } from "../modules/components/utils/contantHelpers";
import useAxiosInterceptors from "../hooks/httpConfig";
import { Loader } from "../modules/components";

function Manager () {
  const apiInstance = useAxiosInterceptors();
  const [projet] = useContext(ProgrammeContext)
  const [startups, setStartups] = useState([]);
  const [pilotes, setPilotes] = useState([]);
  const [managers, setManagers] = useState([]);
  const [loading, setLoading] = useState(true)

  const handleDisplayAll = useCallback(() => {
    setStartups(pilotes.concat(managers));
    setLoading(false);
  }, [managers, pilotes])

  const handleDisplayPilotes = useCallback(() => {
    setStartups(pilotes);
  }, [pilotes])

  const handleDisplayBailleurs = useCallback(() => {
    setStartups(managers)
  }, [managers])

  useEffect(() => {
    const programme = projet?.programme?._id
    if(programme) {
      apiInstance.post(`/startup/adhesions`, {role: USER_ROLE?.PILOTE, programme}).then(({data}) => {
        if(data) {
          setPilotes(data)
        }
      })
    }
  }, [apiInstance, projet?.programme?._id])

  useEffect(() => {
    const programme = projet?.programme?._id
    if(programme) {
      apiInstance.post(`/startup/adhesions`, {role: USER_ROLE?.BAILLEUR, programme}).then(({data}) => {
        if(data) {
          setManagers(data)
        }
      })
    }
  }, [apiInstance, projet?.programme?._id])

  useEffect(() => {
    handleDisplayAll()
  },[handleDisplayAll])

  if (loading) {
    return <Loader />;
    }

  return (
    <div className="Content-Container">
      <div className="PageHeader">
        <div className="row">
          <div className="col-8 col-md-4">
            <h3 className="PageHeader-Title">Pilotes / Bailleurs</h3>
          </div>
          <div className="col-4 col-md-8">
            <div className="FilterForm">
              <button className="MobileFilter" type="button" name="button"><i className="uil uil-filter"></i> Filter</button>
              <div className="FilterRow">
                <button className="CloseMobileFilter" type="button" name="button"><i className="uil uil-times"></i></button>
                {/* START INPUT ROW */}
                <div className="Input-Row">
                  <input type="text" name="" value="" className="InputField" placeholder="Nom" onChange={(event) => console.log(event.target)}/>
                </div>
                {/* END INPUT ROW */}

                {/* START INPUT ROW */}
                <div className="Input-Row Input-Select">
                  <select className="SelectFilter" name="" defaultValue={"DEFAULT"}>
                    <option value="DEFAULT" disabled>Status</option>
                    <option vlaue="">Status 1</option>
                    <option vlaue="">Status 2</option>
                    <option vlaue="">Status 3</option>
                  </select>
                </div>
                {/* END INPUT ROW */}

                {/* START INPUT ROW */}
                <div className="Input-Row Filter-Row">
                  <button type="button" className="Filter-BTN" onClick={handleDisplayAll}>Tous</button>
                  <button type="button" className="Filter-BTN" onClick={handleDisplayPilotes}>Pilotes</button>
                  <button type="button" className="Filter-BTN" onClick={handleDisplayBailleurs}>Bailleurs</button>
                </div>
                {/* END INPUT ROW */}

              </div>
            </div>
          </div>
        </div>
      </div>
      <StartupList startups={startups} role={USER_ROLE?.PILOTE}/>
    </div>
  )
}


export default Manager;