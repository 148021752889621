import {createContext, useEffect, useMemo, useState} from 'react';
import useAxiosInterceptors from '../hooks/httpConfig';
import { Loader } from '../modules/components';
export const AuthContext = createContext({
  user: {},
  setUser: ()=>{},
  isAuthenticated: false,
  setIsAuthenticated: ()=>{}
});
export const UserContext = createContext();
export const ProgrammeContext = createContext();
export const DeviseContext = createContext();
export const NotificationContext = createContext();
export const AuthProvider = ({children})=>{
  const token = JSON.parse(localStorage.getItem("tokens"))?.accessToken;
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [user, setUser] = useState({})
  
  return(
    <> 
      <AuthContext.Provider value={{isAuthenticated, setIsAuthenticated, user, setUser}} >
          {children}
      </AuthContext.Provider>
    </>
  )
}

export const UserProvider = ({children}) => {
  const apiInstance = useAxiosInterceptors();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false)

  const token = JSON.parse(localStorage.getItem("tokens"));

  const isAuthenticated = useMemo(() => !!token, [token]);

  useEffect(() => {
    if(!!JSON.parse(localStorage.getItem("tokens"))) {
      setLoading(true)
      apiInstance.get(`profile`)
        .then(({data}) => {
          setUser(data);
          setLoading(false)
        }).catch(err => {
          setLoading(false)
        })
    }
  }, [apiInstance])

  if (loading) {
    return <Loader />;
  }

  return (
    <UserContext.Provider value={[user, setUser, isAuthenticated]}>{children}</UserContext.Provider>
  )
}