/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext, useEffect, useState } from "react";
// import { NewProgram } from "./components/NewProgram";
import { ProgrammeContext, UserContext } from "../../../contexts/UserContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CustomDatePicker } from "../../components";
import { Alert } from "react-bootstrap";
import { USER_ROLE } from "../../components/utils/contantHelpers";
import useAxiosInterceptors from "../../../hooks/httpConfig";

const CreateSchema = Yup.object().shape({
  nom: Yup.string().required("Le nom du programme est obligatoire"),
  objet: Yup.string().required("L'objet du programme est obligatoire"),
  debut: Yup.date().required("La date de debut est obligatoire"),
  fin: Yup.date().required("La date de fin est obligatoire"),
});

const InviteSchema = Yup.object().shape({
  programme: Yup.string().required("L'id programme est obligatoire"),
  role: Yup.string().required("Le role dans programme est obligatoire"),
});

const defaultSchema = Yup.object().shape({});

export function Programme() {
  const token = localStorage.getItem("tokens");
  const minDate = new Date();
  const [projet, setProjet] = useContext(ProgrammeContext);
  const [user, setUser] = useContext(UserContext);
  const [roles, setRoles] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [isCreateForm, setIsCreateForm] = useState(false);
  const [isInviteForm, setIsInviteForm] = useState(false);
  const [alert, setAlert] = useState(null);
  const axiosInstance = useAxiosInterceptors();

  const loadActiveForm = useCallback((isCreateForm, isInviteForm) => {
    setIsCreateForm(isCreateForm);
    setIsInviteForm(isInviteForm);
  }, []);

  const relaodUserData = useCallback(() => {
    const findUser = async () => {
      try {
        const res = await axiosInstance.get(`profile`);
        if (res) {
          setTimeout(() => {
            setUser(res?.data);
            setAlert(null);
          }, 1000);
        }
      } catch (error) {
        return;
      }
    };
    if (!token) return;
    findUser();
  }, [setUser, token, axiosInstance]);

  const relaodProgramData = useCallback(
    (programme_id) => {
      const { programmes } = user;
      const program =
        (programmes && programmes.find((item) => item?._id === programme_id)) ||
        null;
      if (program) {
        setProjet(program);
      }
    },
    [setProjet, user]
  );

  const joinProgramme = useCallback(
    (values) => {
      const join = async (program) => {
        try {
          const res = axiosInstance.post(
            `adhesions/programme/rejoindre`,
            program
          );
          if (res) {
            const { user, programme_id } = res?.data;
            if (user && programme_id) {
              setAlert({
                message: `Vous etes membre du programme ${user?.nom} en tant que ${role}`,
                variant: "success",
              });
              relaodUserData();
              relaodProgramData(programme_id);
            }
          }
        } catch (error) {
          return;
        }
      };
      const { utilisateur, role, programme } = values;
      join({ utilisateur, role, programme }).catch((error) => {
        console.log(error);
        setAlert({
          message: `Impossible de rejoindre ce programme`,
          variant: "danger",
        });
      });
    },
    [relaodUserData, relaodProgramData, axiosInstance]
  );

  const createProgramme = useCallback(
    (values) => {
      const create = async (program) => {
        try {
          const res = await axiosInstance.post(`programmes`, program);
          if (res) {
            setAlert({
              message: `Le programme '${res?.data?.nom}' a bien été créé`,
              variant: "success",
            });
            const utilisateur = user?._id;
            const role = USER_ROLE.PILOTE;
            const programme = res?.data?.identifiant;
            joinProgramme({ utilisateur, role, programme });
          }
        } catch (error) {
          return;
        }
      };
      console.log("createProgramme", values);
      create(values).catch(() => {
        setAlert({
          message: "Impossible de creer le programme",
          variant: "danger",
        });
      });
    },
    [user, joinProgramme, axiosInstance]
  );

  const handleCreate = useCallback(
    (values) => {
      loadActiveForm(false, false);
      const { nom, objet, debut, fin, role, programme } = values;
      if (isCreateForm) {
        createProgramme({ nom, objet, debut, fin });
      } else if (isInviteForm) {
        const utilisateur = user?._id;
        joinProgramme({ utilisateur, role, programme });
      }
      console.log("handle create ", values);
    },
    [
      user,
      isCreateForm,
      isInviteForm,
      loadActiveForm,
      createProgramme,
      joinProgramme,
    ]
  );
  const handleCreateButtonClick = useCallback(
    (event) => {
      loadActiveForm(true, false);
    },
    [loadActiveForm]
  );
  const handleInviteButtonClick = useCallback(
    (event) => {
      loadActiveForm(false, true);
    },
    [loadActiveForm]
  );
  const handleCancelButtonClick = useCallback(
    (event) => {
      loadActiveForm(false, false);
    },
    [loadActiveForm]
  );

  useEffect(() => {
    const findRoles = async()=>{
      try {
        const res = await axiosInstance.get(`roles`)
        if (res) {
          setRoles(res?.data)
        }
      } catch (error) {
        return;
      }
    }
    findRoles()
  }, [axiosInstance, token]);

  useEffect(() => {
    setInitialValues({
      nom: "",
      objet: "",
      debut: new Date(),
      fin: new Date(),
      programme: "",
      role: "",
    });
  }, [isCreateForm, isInviteForm]);

  useState(() => {
    console.log("projet ", projet);
  }, [projet]);

  useEffect(() => {
    console.log("Programme rendering ...");
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={
          isCreateForm
            ? CreateSchema
            : isInviteForm
            ? InviteSchema
            : defaultSchema
        }
        onSubmit={handleCreate}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form id="LoginForm" className="LoginForm">
            {/* LOGIN INPUTS */}
            <div className="Create-Programme">
              {/* FIELDSET TITLE */}
              {!(isCreateForm || isInviteForm) && (
                <>
                  <h3 className="LoginForm-Title">
                    Créer ou rejoindre un programme
                  </h3>
                  <button
                    className="Create-BTN"
                    type="button"
                    name="button"
                    onClick={handleCreateButtonClick}
                  >
                    <i className="uil uil-plus"></i>
                    <label>
                      Créer <br />
                      un programme
                    </label>
                  </button>
                  <button
                    className="Create-BTN"
                    type="button"
                    name="button"
                    onClick={handleInviteButtonClick}
                  >
                    <i className="uil uil-users-alt"></i>
                    <label>
                      Rejoindre <br />
                      un programme
                    </label>
                  </button>
                </>
              )}
            </div>

            <div className="Create-Programme">
              {isCreateForm && (
                <>
                  <h3 className="LoginForm-Title">Nouveau programme</h3>
                  <div className="mb-3">
                    <div className="Input-Row">
                      <i className="uil uil-subject"></i>
                      <Field
                        name="nom"
                        placeholder="Nom du programme"
                        className="DRInput-Class"
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger text-right"
                        name="nom"
                      />
                    </div>
                    <div className="Input-Row">
                      <i className="uil uil-layers"></i>
                      <Field
                        as="textarea"
                        name="objet"
                        placeholder="Objet du programme"
                        className="DRInput-Class DRTextarea-Class"
                      />
                      <ErrorMessage
                        component="div"
                        className="text-danger text-right"
                        name="objet"
                      />
                    </div>
                    <div
                      id="StartDate"
                      className="Input-Row Input-Date date"
                      data-date-format="dd-mm-yyyy"
                    >
                      <i style={{ zIndex: 1 }} className="uil uil-calender"></i>
                      <CustomDatePicker
                        wrapperClassName="datepicker"
                        className="DateField DRInput-Class"
                        dateFormat="dd/MM/yyyy"
                        placeholder="Date de lancement du programme"
                        name="debut"
                        minDate={minDate}
                        selected={values?.debut}
                        onChange={(date) => setFieldValue("debut", date)}
                      />
                      <span className="input-group-addon">
                        <i className="uil uil-calendar-alt"></i>
                      </span>
                      <ErrorMessage
                        component="div"
                        className="text-danger text-right"
                        name="debut"
                      />
                    </div>
                    <div
                      id="EndDate"
                      className="Input-Row Input-Date date"
                      data-date-format="dd-mm-yyyy"
                    >
                      <i style={{ zIndex: 1 }} className="uil uil-calender"></i>
                      <CustomDatePicker
                        wrapperClassName="datepicker"
                        className="DateField DRInput-Class"
                        dateFormat="dd/MM/yyyy"
                        name="fin"
                        placeholder="Date de fin du programme"
                        minDate={values?.debut}
                        selected={values?.fin}
                        onChange={(date) => setFieldValue("fin", date)}
                      />
                      <span className="input-group-addon">
                        <i className="uil uil-calendar-alt"></i>
                      </span>
                      <ErrorMessage
                        component="div"
                        className="text-danger text-right"
                        name="fin"
                      />
                    </div>
                    {/* ACTIONS */}
                    {/* <button type="submit" className="ProgrameADD-BTN">Ajouter</button> */}
                    <button
                      type="button"
                      className="Prev-BTN"
                      onClick={handleCancelButtonClick}
                    >
                      Etape précédente
                    </button>
                    <button type="submit" className="Next-BTN">
                      Créer <i className="uil uil-arrow-right"></i>
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="Join-Programme">
              {isInviteForm && (
                <>
                  {/* FIELDSET TITLE */}
                  <h3 className="LoginForm-Title">Rejoindre un programme</h3>
                  <div className="Input-Row">
                    <i className="uil uil-user"></i>
                    {/* <input type="text" name="user-name" id="user-name" placeholder="Identifiant du programme" className="DRInput-Class" required/> */}
                    {/* <i className="uil uil-subject"></i> */}
                    <Field
                      name="programme"
                      placeholder="Identifiant du programme"
                      className="DRInput-Class"
                    />
                    <ErrorMessage
                      component="div"
                      className="text-danger text-right"
                      name="programme"
                    />
                  </div>
                  <div className="Input-Row">
                    <i className="uil uil-lock-alt"></i>
                    <Field className="DRInput-Class" as="select" name="role">
                      <option value="">Role</option>
                      {roles.map(({ libelle }) => (
                        <option key={libelle} value={libelle}>
                          {libelle}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      className="text-danger text-right"
                      name="role"
                    />
                    {/* <i className="uil uil-lock-alt"></i>
                  roles
                  <input type="password" name="user-password" id="user-password" placeholder="Mot de passe" className="DRInput-Class" required/>
                  <span toggle="#user-password" className="uil uil-eye field-icon toggle-password"></span> */}
                  </div>
                  {/* LOGIN SUBMIT */}
                  {/* <button type="submit" name="FormLogin-Submit" className="Form-Submit"><i className="uil uil-user-check"></i> Rejoindre</button> */}
                  <button
                    type="button"
                    className="Prev-BTN"
                    onClick={handleCancelButtonClick}
                  >
                    Etape précédente
                  </button>
                  <button type="submit" className="Next-BTN">
                    <i className="uil uil-user-check"></i> Rejoindre
                  </button>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
      {alert && (
        <div className="mt-3">
          <Alert mt={3} variant={alert.variant}>
            <div className="text-center">{alert.message}</div>
          </Alert>
        </div>
      )}
    </>
  );
}
