import React, { useCallback, useContext, useState } from "react";
import { DeviseContext, ProgrammeContext } from "../../contexts/UserContext";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Alert } from "react-bootstrap";
import useAxiosInterceptors from "../../hooks/httpConfig";
const DeviseSchema = Yup.object().shape({
  symbole: Yup.string().required("La devise est obligatoire")
});
const DEVISE_LIST = [
  {
    name: "Dirham marocain",
    symbole: "DH",
  },
  {
    name: "Dollar Américain",
    symbole: "$",
  },
  {
    name: "Euro",
    symbole: "€",
  },
  {
    name: "Livre sterling",
    symbole: "£",
  }
]

function Interface () {
  const apiInstance = useAxiosInterceptors();
  const [projet] = useContext(ProgrammeContext)
  const [devise, setDevise] = useContext(DeviseContext)
  const [showButtonEdit, setShowButtonEdit] = useState(false)
  const [alert, setAlert] = useState(null)
  const handleEdit = useCallback(() => {
    if(projet) {
      const programme_id = projet?.programme?._id;
      console.log("programme_id ", projet);
      if(programme_id) {
        apiInstance.put(`/devise/programme/${programme_id}`, devise).then(({data}) => {
          if(data) {
            setAlert({
              message: `La devise de votre programme a bien été modifié`,
              variant: "success"
            });
            setTimeout(function(){
              setAlert(null);
              setShowButtonEdit(false)
            }, 1000);
          }
        })
      }

    }
  }, [projet, apiInstance, devise])
  const handleSymboleChange = useCallback((event) => {
    setDevise(DEVISE_LIST.find(item => item?.symbole === event?.target.value))
    setShowButtonEdit(true)
  }, [setDevise])
  return (
    <>
      <div className="Settings-Container">
        <div className="row">
          <div className="SettingsRow">
            <div className="SettingsContent">
              <div className="PageHeader">
                <div className="d-flex">
                  <div className="mr-auto p-2"><h3 className="SettingsContent-Title">Interface</h3></div>
                  { showButtonEdit && (
                    <div className="p-2"><button type="button" className="Filter-BTN mixitup-control-active"
                      style={{ fontSize:"1.5em" }}
                      onClick={handleEdit}
                    ><i className="uil uil-save"></i></button></div>
                  )}
                </div>
              </div>

              <div className="SettingsContent-Wrap">
                <div className="SettingsContent-Section">
                  <h4 className="SettingsContent-SectionTitle">Apparence</h4>
                  <div className="SettingsContent-SectionFields FieldsRow">
                    <div className="Settings-Field Settings-Field-3">
                      <div className="Custom-FieldRow Settings-FieldRow LightBG">
                        <label htmlFor="">Langue</label>
                        <select className="FieldRow-Select" name="">
                          <option selected value="Français">Français</option>
                          <option value="Anglais">Anglais</option>
                        </select>
                      </div>
                    </div>
                    <div className="Settings-Field Settings-Field-3">
                      <div className="Settings-FieldRow Custom-FieldRow LightBG">
                        <label htmlFor="">Langue</label>
                        <select className="FieldRow-Select" name="">
                          <option selected value="Work Sans">Work Sans</option>
                          <option value="Roboto">Roboto</option>
                        </select>
                      </div>
                    </div>
                    <div className="Settings-Field Settings-Field-3">
                      <div className="Settings-FieldRow Custom-FieldRow LightBG">
                        <label htmlFor="">Police</label>
                        <select className="FieldRow-Select" name="">
                          <option selected value="Work Sans">Work Sans</option>
                          <option value="Roboto">Roboto</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="SettingsContent-Section">
                  <h4 className="SettingsContent-SectionTitle">Données par défaut</h4>
                  <div className="SettingsContent-SectionFields FieldsRow MarginBottom-15">
                    <div className="Settings-Field Settings-Field-1">
                      <div className="Settings-FieldRow Custom-FieldRow LightBG">
                        <label htmlFor="">Dashboard Startup</label>
                        <select className="FieldRow-Select text-right" name="">
                          <option selected value="">Chiffre d'affaire</option>
                          <option value="">Chiffre d'affaire</option>
                          <option value="">Chiffre d'affaire</option>
                          <option value="">Chiffre d'affaire</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="SettingsContent-SectionFields FieldsRow MarginBottom-15">
                    <div className="Settings-Field Settings-Field-1">
                      <div className="Settings-FieldRow Custom-FieldRow LightBG">
                        <label htmlFor="">Utilisateurs</label>
                        <select className="FieldRow-Select text-right" name="">
                          <option selected value="">Par nom</option>
                          <option value="">Par Prénom</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="SettingsContent-SectionFields FieldsRow MarginBottom-15">
                    <div className="Settings-Field Settings-Field-1">
                      <div className="Settings-FieldRow Custom-FieldRow LightBG">
                        <label htmlFor="">Période</label>
                        <select className="FieldRow-Select text-right" name="">
                          <option selected value="">Year</option>
                          <option value="">Month</option>
                          <option value="">Day</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <Formik
                  enableReinitialize
                  initialValues={{
                    symbole: (devise && devise?.symbole) || ''
                  }}
                  validationSchema={DeviseSchema}
                  onSubmit={handleSymboleChange}>
                  {({ values }) => (
                    <Form>
                      <div className="SettingsContent-Section">
                        <h4 className="SettingsContent-SectionTitle">Devise</h4>
                        <div className="SettingsContent-SectionFields FieldsRow MarginBottom-15">
                          <div className="Settings-Field Settings-Field-1">
                            <div className="Settings-FieldRow Custom-FieldRow LightBG">
                              <label htmlFor="symbole">{devise?.name}</label>
                              <Field as="select" name="symbole" className="FieldRow-Select text-right"
                                onChange={handleSymboleChange}
                              >
                                {
                                  DEVISE_LIST.map((item, index) => <option key={index} value={item?.symbole}>{item?.symbole}</option>)
                                }
                              </Field>
                            </div>
                            <ErrorMessage component="div" className="text-danger text-right" name="symbole" />
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {
                alert &&
                  <Alert mt={3} variant={alert.variant}>
                    <div className="text-center">{alert.message}</div>                    
                  </Alert>
              }
            </div>
          </div>
        </div>
          
      </div>
    </>
  )
}

export default Interface;