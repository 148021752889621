import React from "react";

class Flux extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      types: ['Type', 'Type 1', 'Type 2', 'Type 3']
    };
  }

  componentDidMount(){

  }

  render () {
    return (
      <div className="Content-Container">

        <div className="PageHeader">
          <div className="row">
            <div className="col-md-4">
              <h3 className="PageHeader-Title">Flux d’activités</h3>
            </div>
            <div className="col-md-8">
              <div className="FilterForm">
                <div className="FilterRow">

                  <div className="Input-Row Input-Tags">
                    <input type="text" name="" value="" onChange={() => { console.log("name");
                    }} className="InputField" placeholder="Tags" data-role="tagsinput"/>
                  </div>

                    {/* START INPUT ROW */}
                  <div className="Input-Row Input-Select">
                    <select className="SelectFilter" name=""  defaultValue={'DEFAULT'}>
                      <option value="DEFAULT" disabled>Type</option>
                      <option value="1">Type 1</option>
                      <option value="2">Type 2</option>
                      <option value="3">Type 3</option>
                    </select>
                  </div>
                  {/*  END INPUT ROW  */}

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Flux-Activities_List">
          <div className="NotificationItem">
            <div className="NotificationItem-Thumb"></div>
            <div className="NotificationItem-Content">
              <span className="NotificationItem-Text">Abbass a réalisé 20 000 MAD de ventes cette semaine</span>
              <span className="NotificationItem-Time">Il ya une heure</span>
            </div>
            <a href="/my-missions" className="NotificationLink">Voir plus</a>
          </div>
          <div className="NotificationItem">
            <div className="NotificationItem-Thumb"></div>
            <div className="NotificationItem-Content">
              <span className="NotificationItem-Text">Houdane a programmé un atelier avec Abbass et 3 autres startups</span>
              <span className="NotificationItem-Time">Il ya 12 heures</span>
            </div>
            <a href="/my-missions" className="NotificationLink">Voir plus</a>
          </div>
          <div className="NotificationItem">
            <div className="NotificationItem-Thumb"></div>
            <div className="NotificationItem-Content">
              <span className="NotificationItem-Text">Abbass a reçu 100 000 DH de financement</span>
              <span className="NotificationItem-Time">Il a 5 Jours</span>
            </div>
            <a href="/my-missions" className="NotificationLink">Voir plus</a>
          </div>
        </div>

      </div>
    )
  }
}

export default Flux;