import React from "react";
import { LogoutButton } from "../../../components";

export function LoginAside() {
  return (
    <div className="CreateAccount-BTN">
			<label>Pas encore inscrit ?</label>
			<a href="/register">Inscrivez-vous</a>
		</div>
  );
}

export function RegisterAside() {
  return (
   <div className="CreateAccount-BTN">
			<label>Déja inscrit ? </label>
			<a href="/login">Connectez-vous</a>
		</div>
  );
}

export function LogoutAside() {
	return (
		<div className="CreateAccount-BTN">
			<LogoutButton />
		</div>
	)
}

export function SocialOption() {
	return (
		<div className="SocialConnect-Options">
			<div className="SocialConnect-Col">
				<label>Ou connectez-vous avec :</label>
			</div>
			<div className="SocialConnect-Col SocialConnect-Buttons">
				<button type="button" className="facebook" data-toggle="tooltip" data-placement="bottom" title="Connexion avec Facebook"><i className="uim uim-facebook-f"></i></button>
				<button type="button" className="gmail" data-toggle="tooltip" data-placement="bottom" title="Connexion avec Gmail"><i className="uim uim-google"></i></button>
				<button type="button" className="linkedin" data-toggle="tooltip" data-placement="bottom" title="Connexion avec LinkedIn"><i className="uim uim-linkedin-alt"></i></button>
			</div>
		</div>
	);
}
