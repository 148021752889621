/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useContext, useEffect, useCallback, useState} from "react";
import {UserContext, ProgrammeContext, DeviseContext} from '../../contexts/UserContext';
import { MenuLink } from "../../modules/components";
// import { USER_ROLE } from "../../modules/components/utils/contantHelpers";
import useAxiosInterceptors from "../../hooks/httpConfig";
import { USER_ROLE } from "../../modules/components/utils/contantHelpers";
// import { Link } from "react-router-dom";
const MenuLeft = () => {
  const apiInstance = useAxiosInterceptors();
  const [user] = useContext(UserContext);
  const [projet, setProjet] = useContext(ProgrammeContext);
  const [, setDevise] = useContext(DeviseContext)

  const [role, setRole] = useState('')

  const handleClick = useCallback((event, data) => {
    event.preventDefault();
    setProjet(data);
  }, [setProjet])
  
  useEffect(() => {
    if (user && !projet) {
      const {programmes} = user;
      if (programmes) {
        setProjet(programmes[0])
      }
    }
    // console.log(projet);
  }, [user, setProjet, projet]);

  useEffect(() => {
    if(projet?.role?.libelle) {
      console.log("projet : ", projet);
      setRole(projet.role.libelle);
    }
  }, [projet]);

  useEffect(() => {
    if(projet) {
      const{ programme: { devise } } = projet
      if(devise) {
        apiInstance.get(`/devise/programmes/${devise}`).then(({data}) => {
          if(data) {
            setDevise(data?.devise);
          }
        })
      } else {
        setDevise({
          name: 'Dirham marocain',
          symbole: 'DH'
        });
      }
    }
  }, [apiInstance, projet, setDevise])  
 
  // useEffect(() => {
  //   console.log("user ", user);
  //   console.log('devise ', devise);
  // }, [devise, user])

  // useEffect(() => {
  //   console.log('USER_ROLE : ', USER_ROLE);
  //   console.log("role ", role);
  // }, [role])
  
  // const storedDataFLG = localStorage.getItem("userFLG");

  return (
    <div className="Menu-Side fixed-left">
      <div className="">
        <button className="CloseMenu-Side" type="button" name="button"><i className="uil uil-times"></i></button>
      </div>
      <div className="DigiReporing-LOGO">
        <a href="dashboard"><img src={window.location.origin + "/assets/media/brand/DigiReprting-BrandTEXT.svg"} alt=""/></a>
      </div>

      <div className="Projects-List">
        <div className="DigiReporting-DropDown">
          <a id="Selected-Programme" className="Selected-Programme">Programmes</a>
          <div className="DigiReporting-DropDownMenu">
            {
              user && projet && user?.programmes.map((item, index) => 
                <a style={{cursor:"pointer"}} key={index} onClick={(e) => handleClick(e, item)}
                  className={projet.programme?._id === item?.programme?._id ? "dropdown-item project-item" :"dropdown-item"}
                >
                  <div className="Project-Thumb"></div><span className="Project-Name">{item?.programme?.nom}</span>
                </a>
              )
            }
            <div className="dropdown-divider"></div>
            {
              projet &&
              <a className="dropdown-item" href="/program">
                <span className="Project-Name"><i className="uil uil-setting"></i> Paramètres du programme</span>
              </a>
            }
            <a className="dropdown-item" href="/program/new">
              <span className="Project-Name"><i className="uil uil-plus"></i> Nouveau programme </span>
            </a>
            <a className="dropdown-item" href="/program/join">
              <span className="Project-Name"><i className="uil uil-channel-add"></i> Rejoindre un autre projet</span>
            </a>
            <a className="dropdown-item" href="/"><span className="Project-Name"><i className="uil uil-sign-out-alt"></i> Quitter programme</span></a>
          </div>
        </div>
      </div>

      <div id="DigiReporting-Menu" className="DigiReporting-Menu">
        <div className="DigiReporting-Menu-Item">
          <MenuLink className="Menu-Link-Active" to="dashboard"><i className="uil uil-dashboard"></i> <span>Dashboard</span></MenuLink>
        </div>
        <div className="DigiReporting-Menu-Item">
          <MenuLink to="flux"><i className="uil uil-bell"></i> <span>Flux d’activités</span></MenuLink>
        </div>
        <div className="DigiReporting-Menu-Item">
          <MenuLink to="startups"><i className="uil uil-rocket"></i> <span>Startups</span></MenuLink>
        </div>
        <div className="DigiReporting-Menu-Item">
          <MenuLink to="consultants"><i className="uil uil-user-md"></i> <span>Consultants</span></MenuLink>
        </div>
        <div className="DigiReporting-Menu-Item">
          <MenuLink to="managers"><i className="uil uil-briefcase-alt"></i> <span>Pilotes / Bailleurs</span></MenuLink>
        </div>
        { user && user?.entreprise &&
          <div className="DigiReporting-Menu-Item HasDropDown">
            <MenuLink to={`startup/missions`}>
              <i className="uil uil-sliders-v-alt"></i> <span>Outils</span><i className="uil uil-angle-right"></i>
            </MenuLink>
            <ul className="DropDown-Items" id="DropDown-Items">
              {role !== USER_ROLE.CONSULTANT ? <li className="DropDown-Item">
                <MenuLink to={`startup/business`}><i className="uil uil-university"></i> <span>Ma startup</span></MenuLink>
              </li> : null}
              <li className="DropDown-Item">
                <MenuLink to="startup/missions"><i className="uil uil-bag"></i> <span>Mes missions</span></MenuLink>
              </li>
              {role === USER_ROLE.PILOTE || role === USER_ROLE.ADMIN ?<li className="DropDown-Item">
                <MenuLink to="startup/budget"><i className="uil uil-coins"></i> <span>Mon budget</span></MenuLink>
              </li>:null}
              <li className="DropDown-Item">
                <MenuLink to="startup/follow-up"><i className="uil uil-file-search-alt"></i> <span>Follow Up</span></MenuLink>
              </li>
              <li className="DropDown-Item">
                <MenuLink to="startup/exporter"><i className="uil uil-export"></i> <span>Exporter</span></MenuLink>
              </li>
            </ul>
          </div>
        }
        <div className="DigiReporting-Menu-Item HasDropDown">
          {/* <a className="Menu-Link"
            onClick={handleItemClick}
            ref={itemRef}
          ><i className="uil uil-cog"></i> <span>Réglages</span><i className="uil uil-angle-right"></i>
          </a> */}
          <MenuLink to="user/profile">
            <i className="uil uil-cog"></i> <span>Réglages</span><i className="uil uil-angle-right"></i>
          </MenuLink>
          <ul className="DropDown-Items" id="DropDown-Items">
            <li className="DropDown-Item">
              <MenuLink to="user/profile"><i className="uil uil-user"></i> <span>Mon Profile</span></MenuLink>
            </li>
            <li className="DropDown-Item">
              <MenuLink to="startup"><i className="uil uil-briefcase"></i> <span>Mon business</span></MenuLink>
            </li>
            {role === USER_ROLE.PILOTE || role === USER_ROLE.ADMIN ?<li className="DropDown-Item">
              <MenuLink to="user"><i className="uil uil-users-alt"></i> <span>Utilisateurs</span></MenuLink>
            </li>:null}
            <li className="DropDown-Item">
              <MenuLink to="interfaces"><i className="uil uil-window-maximize"></i> <span>Interface</span></MenuLink>
            </li>
            {role === USER_ROLE.PILOTE || role === USER_ROLE.ADMIN ? <li className="DropDown-Item">
              <MenuLink to="billing"><i className="uil uil-invoice"></i> <span>Facturation</span></MenuLink>
            </li> : null}
            {role === USER_ROLE.PILOTE || role === USER_ROLE.ADMIN ?<li className="DropDown-Item">
              <MenuLink to="categories"><i className="uil uil-apps"></i> <span>Catégories</span></MenuLink>
            </li>:null}
            {role === USER_ROLE.PILOTE || role === USER_ROLE.ADMIN ?<li className="DropDown-Item">
              <MenuLink to="program/list"><i className="uil uil-export"></i> <span>Programme</span></MenuLink>
            </li>:null}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default MenuLeft

