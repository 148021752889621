import React, { useEffect } from "react";
import moment from 'moment';
import { USER_ROLE } from "../modules/components/utils/contantHelpers";
import { CustomLoader } from "../modules/components";
const digitalOceanStorageUrl = "https://storage.digireporting.app/";

export function StartupList ({startups, role}) {

  useEffect(() => {
    console.log('StartupList role : ', role);
  },[role])
  if(!startups) {
    return <CustomLoader />
  }

  return (
    <div className="PageContent">
      <div id="Filter-Active_Inactif" className="row Filter-Active_Inactif">
        {startups.map((startup) => (
          <div key={startup?._id} className={startup.status? "col-lg-3 col-md-4 col-12 mix Active" :"col-lg-3 col-md-4 col-12 mix Inactif"}>
            <div className="StartUPBOX">
              <div className="StartUPBOX-Thumb">
                <div className={startup?.status? "StartUPBOX-Active" :"StartUPBOX-Inactive"}></div>
                <a href={`/startup/${(role === USER_ROLE.PILOTE || role === USER_ROLE.BAILLEUR) ? 'pilote/' : (role === USER_ROLE.CONSULTANT ? 'consultant/' : '')}${startup?._id}`}><img src={`${digitalOceanStorageUrl}${startup?.couverture || "cover.png"}`} alt={startup?.nom}/></a>
              </div>
              <div className="StartUPBOX-Details">
                <span className="StartUPBOX-DetailsSector">{startup?.secteur}</span>
                <h4 className="StartUPBOX-DetailsName"><a className="StartUPBOX-DetailsLink" href={`/startup/${(role === USER_ROLE.PILOTE || role === USER_ROLE.BAILLEUR) ? 'pilote/' : (role === USER_ROLE.CONSULTANT ? 'consultant/' : '')}${startup?._id}`}>{startup?.nom}</a></h4>
                {
                  role === USER_ROLE.STARTUP ? (
                  <>
                    <span className="StartUPBOX-DetailsBudget">{startup?.ca}</span>
                    <span className="StartUPBOX-DetailsDate"><i className="uil uil-calendar-alt"></i> {moment(new Date(startup?.creation)).format("DD/MM/YYYY")}</span>
                  </> ) : (
                    <span className="StartUPBOX-DetailsBudget">{startup.activite}</span>
                  )
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}