import React from "react";
import { Datatable } from "../../modules/components";

class FollowUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      user: {
        id: "1",
        nom: "Houdane Consulting",
        couverture: "Houdane_Consulting-Preview.jpg",
        secteur: "Education",
        ca: "300 000$",
        status: true,
        annee: "2010"
      },
      rows: [
        {
            "id": "1",
            "date": "04.01.2020",
            "auteur": "Association Kods ",
            "type": "Rapport ",
            "intitule": "Rapport trimestriel",
            "tags": "-",
            "sharedWith": "Jonathan, Xavier ",
            "statut": "Archivé"
        },
        {
            "id": "2",
            "date": "12.01.2020",
            "auteur": "MS Food ",
            "type": "Demande ",
            "intitule": "Financement 2eme tranche ",
            "tags": "Bintia",
            "sharedWith": "Jonathan, Xavier ",
            "statut": "Archivé"
        },
        {
            "id": "3",
            "date": "18.01.2020",
            "auteur": "IYA Consulting ",
            "type": "Compte rendu ",
            "intitule": "Atelier Marketing ",
            "tags": "Sagno,Tamafo, ",
            "sharedWith": "Jonathan, Xavier",
            "statut": "Archivé"
        },
        {
            "id": "4",
            "date": "25.01.2020",
            "auteur": "Tamaf Center ",
            "type": "Suivi administratif et financier ",
            "intitule": "Declaration trimestrielle ",
            "tags": "Imran ",
            "sharedWith": "Jonathan, Xavier, Abbass",
            "statut": "Archivé"
        },
        {
            "id": "5",
            "date": "28.01.2020",
            "auteur": "Lobe Incub ",
            "type": "Evenement ",
            "intitule": "Networking ",
            "tags": "Xavier ",
            "sharedWith": "Sagno, Tamafo,Abbass",
            "statut": "Publié "
        }
      ],
      columns: [
        {
          name: "Date",
          selector: "date",
          sortable: true
        },
        {
          name: "Auteur",
          selector: "auteur",
          sortable: true
        },
        {
          name: "Type",
          selector: "type",
          sortable: true
        },
        {
          name: "Intitulé",
          selector: "intitule",
          sortable: true
        },
        {
          name: "Tags",
          selector: "tags",
          sortable: true,
          right: true
        },
        {
          name: "Partagé avec",
          selector: "sharedWith",
          sortable: true,
          right: true
        },
        {
          name: "Statut",
          selector: "statut",
          sortable: true,
          right: true
        }
      ]
    };
  }

  componentDidMount(){

  }

  render () {
     const { rows, columns } = this.state;
    return (
      <>
        <div className="Settings-Container">
             <div className="row">
               <div className="SettingsRow">

                 <div className="SettingsContent">
                   <div className="PageHeader">
                     <h3 className="SettingsContent-Title">Follow UP</h3>
                   </div>

                   <div id="FollowUP-Section" className="table-responsive">
                     <div className="TableActions">
                       <div className="TableActions-Form">
                         <div className="TableActions-FormRowLeft">
                           <a href="new-follow-up" className="dt-button buttons-create Porpal-BTN"><span>Ajouter</span></a>
                         </div>
                         <div className="TableActions-FormRowRight">

                         </div>
                       </div>
                     </div>

                     {/* <table id="FollowUP-Table" className="table table-striped table-bordered" style={{width: "100%"}}>
                       <thead>
                         <tr>
                           <th scope="col"></th>
                           <th scope="col">Date</th>
                           <th scope="col">Auteur</th>
                           <th scope="col">Type</th>
                           <th scope="col">Intitulé</th>
                           <th scope="col">Tags</th>
                           <th scope="col">Partagé avec</th>
                           <th scope="col">Statut</th>
                         </tr>
                       </thead>
                       <tfoot>
                         <tr>
                           <th scope="col"></th>
                           <th scope="col">Date</th>
                           <th scope="col">Auteur</th>
                           <th scope="col">Type</th>
                           <th scope="col">Intitulé</th>
                           <th scope="col">Tags</th>
                           <th scope="col">Partagé avec</th>
                           <th scope="col">Statut</th>
                         </tr>
                       </tfoot>
                     </table> */}
                     {/* <Datatable rows={rows} columns={columns} /> */}
                     <div className="TableWrap">
                      <div className="table-responsive" style={{ marginTop: "12px"}}>
                        <Datatable rows={rows} columns={columns} />
                      </div>
                    </div>

                 </div>

                 </div>
               </div>
             </div>
           </div>
      </>
    )
  }
}

export default FollowUp;