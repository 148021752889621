import React from "react";
import { Datatable } from "../../modules/components";

class Utilisateur extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      users: [
        {
          id: "1",
          utilisateur: "Sagno Mohamed",
          role: "Consultant",
          entite: "Houdane Consulting",
          email: "mo.sagno@digireporting.com",
          telephone: "0675899871"
        },
        {
          id: "2",
          utilisateur: "Sagno Mohamed",
          role: "Consultant",
          entite: "Houdane Consulting",
          email: "mo.sagno@digireporting.com",
          telephone: "0675899871"
        }
      ],
      columns: [
        {
          name: "Utilisateur",
          selector: "utilisateur",
          sortable: true
        },
        {
          name: "Rôle",
          selector: "role",
          sortable: true
        },
        {
          name: "Entité",
          selector: "entite",
          sortable: true
        },
        {
          name: "Email",
          selector: "email",
          sortable: true
        },
        {
          name: "Téléphone",
          selector: "telephone",
          sortable: true
        }
      ]
    };
  }

  componentDidMount(){

  }

  render () {

    const { users, columns } = this.state;
    return (
      <>
        <div className="Settings-Container">
          <div className="row">
            <div className="SettingsRow">
              <div className="SettingsContent">
                <div className="PageHeader">
                  <h3 className="SettingsContent-Title">Utilisateurs(5)</h3>
                </div>
                <div className="UsersProfiles-ROW">
                  <div className="UserProfile-BOX">
                    <div className="UserProfile-BOXWrap">
                      <div className="UserProfile-COUNT Pink_Color">2</div>
                      <div className="UserProfile-LABEL">Consultants</div>
                    </div>
                  </div>
                  <div className="UserProfile-BOX">
                    <div className="UserProfile-BOXWrap">
                      <div className="UserProfile-COUNT Orange_Color">3</div>
                      <div className="UserProfile-LABEL">Startups</div>
                    </div>
                  </div>
                  <div className="UserProfile-BOX">
                    <div className="UserProfile-BOXWrap">
                      <div className="UserProfile-COUNT Yellow_Color">1</div>
                      <div className="UserProfile-LABEL">Pilote</div>
                    </div>
                  </div>
                  <div className="UserProfile-BOX">
                    <div className="UserProfile-BOXWrap">
                      <div className="UserProfile-COUNT Propal_Color">2</div>
                      <div className="UserProfile-LABEL">Bailleurs de fonds</div>
                    </div>
                  </div>
                </div>

                <div className="TableActions">
                  <div className="TableActions-Form">
                    <div className="TableActions-FormRowLeft">
                      <div className="TableActions-FormRow">
                        <button type="button" className="Porpal-BTN" data-toggle="modal" data-target="#AddUser">ADD NEW</button>
                        <button type="button" className="White-BTN" data-toggle="modal" data-target="#EditUser"><i className="uil uil-pen"></i></button>
                        <button type="button" className="Red-BTN" data-toggle="modal" data-target="#DeleteAlert"><i className="uil uil-trash-alt"></i></button>
                      </div>
                    </div>
                    <div className="TableActions-FormRowRight">
                      <div className="TableActions-FormRow">

                        <div className="Input-Row">
                          <button type="button" className="Transparent-BTN" name="button" data-toggle="modal" data-target="#InviteUser"><i className="uil uil-plus"></i> <span>Inviter</span></button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive" style={{ paddingTop: "1.5rem" }}>
                  <Datatable rows={users} columns={columns} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="AddUser" tabindex="-1" role="dialog" aria-labelledby="AddUserTitle" aria-hidden="true">
          <div className="DigiReprting-Modal modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">

              <div className="modal-body">
                <div className="SettingCol-Header">
                  <h4 className="SettingCol-HeaderTitle">Nouveau Utilisateur</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i className="uil uil-times"></i>
                  </button>
                </div>

                <div className="SettingCol-Row">
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Nom" name="date" value="" />
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Prénom" name="periode" value="" />
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="email" className="FieldText" placeholder="Email" name="periode" value="" />
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="tel" className="FieldText" placeholder="Téléphone" name="periode" value="" />
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Rôle" name="periode" value="" />
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Entité" name="periode" value="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="ButtonCancel"><button type="button" className="Cancel-BTN" data-dismiss="modal">Annuler</button></div>
                <div className="ButtonSave"><button type="button" className="Save-BTN">Enregistrer</button></div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="EditUser" tabindex="-1" role="dialog" aria-labelledby="EditUserTitle" aria-hidden="true">
          <div className="DigiReprting-Modal modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">

              <div className="modal-body">
                <div className="SettingCol-Header">
                  <h4 className="SettingCol-HeaderTitle">Nouveau Utilisateur</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i className="uil uil-times"></i>
                  </button>
                </div>

                <div className="SettingCol-Row">
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Nom" name="date" value="" />
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Prénom" name="periode" value="" />
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="email" className="FieldText" placeholder="Email" name="periode" value="" />
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="tel" className="FieldText" placeholder="Téléphone" name="periode" value="" />
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Rôle" name="periode" value="" />
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Entité" name="periode" value="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="ButtonCancel"><button type="button" className="Cancel-BTN" data-dismiss="modal">Annuler</button></div>
                <div className="ButtonSave"><button type="button" className="Save-BTN">Enregistrer</button></div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="DeleteAlert" tabindex="-1" role="dialog" aria-labelledby="EditElementTitle" aria-hidden="true">
          <div className="DigiReprting-Modal modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">

              <div className="modal-body">
                <div className="SettingCol-Header">
                  <h4 className="SettingCol-HeaderTitle">Delete</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i className="uil uil-times"></i>
                  </button>
                </div>

                <p>Are you sure you wish to delete 1 row?</p>

              </div>
              <div className="modal-footer">
                <div className="ButtonCancel"><button type="button" className="Cancel-BTN" data-dismiss="modal">Annuler</button></div>
                <div className="ButtonSave"><button type="button" className="Save-BTN">Delete</button></div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="InviteUser" tabindex="-1" role="dialog" aria-labelledby="InviteUserTitle" aria-hidden="true">
        <div className="ProfileUpdateModal modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">

            <div className="modal-body">
              <div className="SettingCol-Header">
                <h4 className="SettingCol-HeaderTitle">Équipe</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <i className="uil uil-times"></i>
                </button>
              </div>

              <div className="SettingCol-Row">
                <div className="Settings-Field Settings-Field-1">
                  <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                    <input type="text" className="FieldText" placeholder="User Name" name="user_name" value="" />
                  </div>
                </div>
                <div className="Settings-Field Settings-Field-1">
                  <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                    <input type="email" className="FieldText" placeholder="User Email" name="user_email" value="" />
                  </div>
                </div>
                <div className="Settings-Field Settings-Field-1">
                  <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                    <input type="text" className="FieldText" placeholder="User Code" name="user_code" value="" />
                  </div>
                </div>
                <div className="Settings-Field Settings-Field-1">
                  <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                    <input type="text" className="FieldText" placeholder="Generate a link" name="periode" value="" />
                    <button className="Generate-BTN" type="button" name="button">Get it !</button>
                  </div>
                </div>

              </div>
            </div>
            <div className="modal-footer">
              <div className="ButtonCancel"><button type="button" className="Cancel-BTN" data-dismiss="modal">Annuler</button></div>
              <div className="ButtonSave"><button type="button" className="Save-BTN">Enregistrer</button></div>
            </div>
          </div>
        </div>
        </div>

      </>
    )
  }
}

export default Utilisateur;