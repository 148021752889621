import React, { useEffect, useRef } from 'react'
import Chart from "chart.js";

export function LineChart({
  data= ["90", "100", "300", "456", "300"],
  title="Evolution du chiffre d'affaire",
  height=80, // height => desktop 80, mobile 150 and tablet 130
  width=200
}) {
  const chartRef = useRef(null)
  useEffect(() => {
    if(chartRef){
      const myChartRef = chartRef.current.getContext("2d");
      new Chart(myChartRef, {
        type: 'line',
        data: {
          labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
          datasets: [{
              label: '# of Votes',
              data,
              backgroundColor: 'transparent',
              borderColor: 'rgb(106, 12, 172)',
              borderWidth: 3
          }]
        },
        options: {
          title: {
            display: true,
            text: title
          },
          responsive: true,
          legend: {
            display: false,
            // position: 'bottom',
          },
          hover: {
            mode: 'index'
          },
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          }
        }
      });
    }
  }, [chartRef, data, title])
  return (
    <div className="ChartBOX">
      <canvas ref={chartRef} height={height} width={width}/>
    </div>
  )
}
