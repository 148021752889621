import React from "react";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import useAxiosInterceptors from "../../../../hooks/httpConfig";
const SigninSchema = Yup.object().shape({
	email: Yup.string().email('Email invalide').required("L'adresse e-mail est obligatoire")
});

const ForgotPasswordForm = () => {
	const axiosInstance = useAxiosInterceptors();
   const formik = useFormik({
     initialValues: {
       email: '',
     },
		 validationSchema: SigninSchema,
     onSubmit: values => {
		axiosInstance.post(`connexion`, values).then((response) => {
				 console.log(response);
				//  const {accessToken} = response.data
				//  if (accessToken) {
				// 	 localStorage.setItem('token', accessToken);
				//  }
			 })
     },
   });
   return (
		 <>
				{/* LOGIN TITLE */}
				<h3 className="LoginForm-Title">Reinitialisation !</h3>
				<form id="LoginForm" className="LoginForm" onSubmit={formik.handleSubmit}>
					{/* LOGIN INPUTS */}
					<div className="Input-Row">
						<i className="uil uil-envelope"></i>
						<input
							type="text"
							name="email"
							id="email"
							placeholder="Adresse e-mail"
							className="DRInput-Class"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.email}
				 		/>
						 {formik.touched.email && formik.errors.email ? (
								<div className="text-danger text-right">{formik.errors.email}</div>
							) : null}
					</div>
					{/* LOGIN SUBMIT */}
					<button type="submit" name="FormLogin-Submit" className="Form-Submit"><i className="uil uil-user-check"></i> Reinitialiser</button>
				</form>
      </>
   );
 };



export default ForgotPasswordForm;