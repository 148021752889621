import React from "react";
import ReactLoading from "react-loading";
export function Loader({
    width = 100,
    height = 100,
    color = '#4b007f',
    type='spinningBubbles'
  }) {
  return (
    <div className="Settings-Container">
      <div className="row">
        <div className="SettingsRow">
          <div className="SettingsContent">
            <ReactLoading
              className="loader-page custom-loader"
              type={type}
              color={color}
              height={height}
              width={width}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export function CustomLoader({
  width = 100,
  height = 100,
  color = '#4b007f',
  type='spinningBubbles'
}) {
return (
  <>
    <ReactLoading
      className="loader-page custom-loader"
      type={type}
      color={color}
      height={height}
      width={width}
    />
    <div style={{display: 'flex', marginTop: '10px', justifyContent: 'center'}}>Loading ...</div>
  </>
)
}


export function LoaderButton({
  width = 100,
  height = 100,
  color = '#4b007f',
  type = 'spinningBubbles',
  className='loader-button'
}) {
  return (
    <ReactLoading
      type={type}
      color={color}
      height={height}
      width={width}
      className={className}
    />
  )
}
  