import axios from "axios";
import { useEffect } from 'react';

const baseURL =
  process.env?.REACT_APP_API_URL || "http://localhost:8080/api/v1/";
const apiInstance = axios.create({
  baseURL, // Remplacez par l'URL de base de votre API
  headers: {
    'Content-Type': 'application/json',
  }
});

const useAxiosInterceptors = () => {
  useEffect(() => {
    const source = axios.CancelToken.source();

  const requestInterceptor =
  apiInstance.interceptors.request.use(
    (config) => {
      if (!config.headers['Authorization']) {
        const token = JSON.parse(localStorage.getItem("tokens"))?.accessToken;
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
          // console.log('HEADERS => ', config);
        }
      }
      // console.log('HEADERS2 => ', config);
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const responseInterceptor =
  apiInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const prevRequest = error?.config
      if (error.response && (error.response.status === 401 || error.response.status === 403) && !prevRequest?.sent) {
        // Logique de rafraîchissement du token
        prevRequest.sent = true

        try {
          const refreshedToken = await axios.post(`${baseURL}auth/refresh`, {
            refreshToken: JSON.parse(localStorage.getItem("tokens"))?.refreshToken,
          });

          localStorage.setItem("tokens", JSON.stringify(refreshedToken.data));
          // apiInstance.defaults.headers.common[
          //   "Authorization"
          // ] = `Bearer ${refreshedToken.data.accessToken}`;

          // error.config.headers[
          //   "Authorization"
          // ] = `Bearer ${refreshedToken.data.accessToken}`;
          prevRequest.headers['Authorization'] = `Bearer ${refreshedToken.data.accessToken}`;

          return apiInstance(prevRequest);
        } catch (refreshError) {
          // Gérer l'erreur de rafraîchissement du token
          // localStorage.removeItem('tokens');
          // localStorage.clear();
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

      return () => {
        apiInstance.interceptors.request.eject(requestInterceptor);
        apiInstance.interceptors.response.eject(responseInterceptor);
        source.cancel();
      };
    }, []);

    return apiInstance;
};

export default useAxiosInterceptors;

// import axios from 'axios';
// // import { useEffect } from 'react';

// const baseURL = process.env?.REACT_APP_API_URL || "http://localhost:8080/api/v1/";

// // Configuration de l'instance Axios
// const apiInstance = axios.create({
//   baseURL, // Remplacez par l'URL de base de votre API
//   timeout: 2000,
//   headers: {
//     'Content-Type': 'application/json'
//   }
// });

// // const useAxiosInterceptors = () => {
// //   useEffect(() => {
//     const source = axios.CancelToken.source();

// //     // Configuration de l'instance Axios
// //     apiInstance.defaults.cancelToken = source.token;

//     // Intercepteur de requêtes
//     // const requestInterceptor =
//     apiInstance.interceptors.request.use(
//       (config) => {
//         console.log("config1 => ", config);
//       apiInstance.defaults.cancelToken = source.token;
//       // Ajoutez le Bearer token aux en-têtes de la requête si disponible
//       if (!config.url === "/connexion" || !config.url === "/inscription" ) {
//         const token = JSON.parse(localStorage.getItem('tokens'))?.accessToken;
//         if (token) {
//           config.headers['Authorization'] = `Bearer ${token}`;
//         }
//         //

//       }
//       console.log("config => ", config);
//       return config;
//     });

//     // Intercepteur de réponses
//     // const responseInterceptor =
//     apiInstance.interceptors.response.use(
//       (response) => {
//         return response;
//       },
//       async (error) => {
//         // Gérez les erreurs de réponse, par exemple, le rafraîchissement du token
//         if (error.response && error.response.status === 401) {
//           // Logique de rafraîchissement du token

//           // Exemple de rafraîchissement du token
//           const refreshedToken = await axios.post(`${baseURL}auth/refresh`, {
//             refreshToken: JSON.parse(localStorage.getItem('tokens'))?.refreshToken,
//           });

//           // Mettez à jour le token dans le local storage et dans les en-têtes de la requête
//           localStorage.setItem('tokens', JSON.stringify(refreshedToken.data));
//           apiInstance.defaults.headers.common['Authorization'] = `Bearer ${refreshedToken.data.accessToken}`;

//           // Réessayez la requête d'origine
//           return apiInstance(error.config);
//         }

//         return Promise.reject(error);
//       }
//     );

//     // return () => {
//     //   axios.interceptors.request.eject(requestInterceptor);
//     //   axios.interceptors.response.eject(responseInterceptor);
//     //   source.cancel();
//     // };
// //   }, []);

// //   return apiInstance;
// // };
// export default apiInstance

// // export default useAxiosInterceptors;
