import React, { Fragment } from "react";
import { LineChart } from "../components";

function Dashboard () {
  const startups= [
    {
      Id: "1",
      Nom: "MS Food",
      Activite: "Restauration",
      Secteur: "Alimentation",
      Telephone: "212669124912",
      Email: "ms.food@gmail.com	"
    },
    {
      Id: "2",
      Nom: "Tamaf Center",
      Activite: "Cours de langue",
      Secteur: "Education",
      Telephone: "(212) 698187721",
      Email: "tamaf.center@hotmail.com"
    },
    {
      Id: "3",
      Nom: "Coop houdawé",
      Activite: "Agrobussiness",
      Secteur: "Industrie alimentaire",
      Telephone: "(212) 669124912",
      Email: "coop.houdawé@gmail.com"
    },
    {
      Id: "4",
      Nom: "RanTech",
      Activite: "Developpement",
      Secteur: "Technologie",
      Telephone: "(212) 605140068",
      Email: "rantech@gmail.com"
    },
    {		
      Id: "5",
      Nom: "SB Fintech",
      Activite: "Finance",
      Secteur: "Service",
      Telephone: "(212) 669124912",
      Email: "sb.fintech@gmail.com",
    }
  ];
  const consultants= [
    {
      Id: "60241760f336870e265560b0",
      Nom: "IYA Consulting",
      Activite: "Accompagnement",
      Secteur: "Consulting",
      lieu: "Casablanca",
      Creation: "01/06/2017",
      NumRC: "110997023079",
      NumIF: "9023085363",
      Telephone: "(212) 657794432",
      Email: "iya.consulting@gmail.com",
      Reseaux: "",
      Site: "https://iya.consulting.com",          
      Logo: "iyaconsulting-logo.jpg",
      Couverture: "iyaconsulting.jpg",
      status: true,
    },
    {
      Id: "60241760f336870e265990b1",
      Nom: "Armand Corporate",
      Activite: "Coaching Financier",
      Secteur: "Service",
      lieu: "Casablanca",
      Creation: "21/01/2009",
      NumRC: "11099702",
      NumIF: "3085363",
      Telephone: "(212) 698187721",
      Email: "Armand.Corp@gmail.com",
      Reseaux: "",
      Site: "https://armand.corporate.com",          
      Logo: "ARMAND CORPORATE.jpg",
      Couverture: "ARMAND CORPORATE COVER.jpg",
      status: true,
    },
    {
      Id: "69941760f336870e265990b1",
      Nom: "Jonath-vie",
      Activite: "Coaching et developpement personnel",
      Secteur: "Consulting",
      lieu: "Kenitra",
      Creation: "15/08/2016",
      NumRC: "97023079",
      NumIF: "85363",
      Telephone: "(212) 681992210",
      Email: "jonath-vie@gmail.com",
      Reseaux: "",
      Site: "https://jonath-vie.com",          
      Logo: "JONATHAN VIE.jpg",
      Couverture: "JONATHAN VIE COVER.jpg",
      status: true,
    },
    {
      Id: "78941760f336870e265990b1",
      Nom: "SSD Consulting",
      Activite: "Bureau d'etude",
      Secteur: "Service",
      lieu: "Rabat",
      Creation: "12/02/2005",
      NumRC: "110997023079",
      NumIF: "9023085363",
      Telephone: "(212) 657794432",
      Email: "ssd.consulting@gmail.com",
      Reseaux: "",
      Site: "https://ssd.consulting.com",          
      Logo: "SSD CONSULTING.jpg",
      Couverture: "SSD CONSULTING COVER.jpg",
      status: true,
    }			
  ];
  const pilotes= [
    {
      Id: "60241760f336870e265560b0",
      Nom: "Lobe Incub",
      Activite: "Incubation",
      Secteur: "Service",
      lieu: "Casablanca",
      Creation: "18/02/2016",
      NumRC: "11000003070",
      NumIF: "1000085363",
      Telephone: "(212) 681992210",
      Email: "lobe.incub@gmail.com",
      Reseaux: "Facebook/LinkedIn",
      Site: "http://lobe.incub.ma",          
      Logo: "lobe-logo.jpg",
      Couverture: "lobe.jpg",
      status: true,
    },
    {
        Id: "60241760f336870e265560a8",
        Nom: "MHS ",
        Activite: "Developpement/cooperation ",
        Secteur: "Service ",
        lieu: "Rabat",
        Creation: "01/06/0202",
        NumRC: "2000422",
        NumIF: "354000",
        Telephone: "(212) 681992210",
        Email: "mhs@gmail.com",
        Reseaux: "Facebook/LinkedIn ",
        Site: "mhs.com",         
        Logo: "MHS.jpg",
        Couverture: "MHS COVER.jpg",
        status: true
    },
    {
        Id: "60241760f336870e26556h30",
        Nom: "Houdane Invest ",
        Activite: "Investissement/accompagnement",
        Secteur: "Service ",
        lieu: "France ",
        Creation: "26/03/2010",
        NumRC: "1100025",
        NumIF: "300045",
        Telephone: "(212) 669124912",
        Email: "houdane.invest@gmail.com",
        Reseaux: "Facebook/LinkedIn ",
        Site: "houdane.invest.fr",                     
        Logo: "HOUDANE INVEST.jpg",
        Couverture: "HOUDANE INVEST COVER.jpg",
        status: true
    }
  ];
  return (
    <Fragment>
      <div className="Content-Container">
      <div className="PageHeader">
          <div className="row">
          <div className="col-md-4">
              <h3 className="PageHeader-Title">Dashboard</h3>
          </div>
          <div className="col-md-8">
              <div className="FilterForm">
              <div className="FilterRow">
                  {/* START INPUT ROW */}
                  <div className="Input-Row Input-Select">
                  <select className="SelectFilter" name="" defaultValue={'DEFAULT'}>
                      <option value="DEFAULT" disabled>Chiffre d’affaires</option>
                      <option vlaue="1">Chiffre d’affaire 1</option>
                      <option vlaue="2">Chiffre d’affaire 2</option>
                      <option vlaue="3">Chiffre d’affaire 3</option>
                  </select>
                  </div>
                  {/* END INPUT ROW */}
              </div>
              </div>
          </div>
          </div>
      </div>
      <LineChart />
      <div className="StatsBox">
          <div className="row">

          <div className="col-md-4">
              <div className="DashboardStats-BOX">
              <div className="BOX-Header">
                  <h4 className="BOX-Title">Startups</h4>
                  <div className="StartupsBOX-List">
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb ShowMore" data-toggle="modal" data-target="#ModalStartups">+5</div>
                  </div>
              </div>
              <div className="BOX-Content">
                  <div className="BOX-Side-2">
                  <div className="BOX-Side-Content">
                      <div className="BOX-Side-Icon"><i className="uil uil-briefcase-alt"></i></div>
                      <span>15</span>
                      <label>Secteurs d’activité</label>
                  </div>
                  </div>
                  <div className="BOX-Side-2">
                  <div className="BOX-Side-Content">
                      <div className="BOX-Side-Icon"><i className="uil uil-parcel"></i></div>
                      <span>54</span>
                      <label>Biens</label>
                  </div>
                  </div>
              </div>
              <div className="BOX-Footer">
                  <div className="BOX-Side-2">
                  <label className="BOX-Label">Financement reçu</label>
                  </div>
                  <div className="BOX-Side-2">
                  <span className="Investi">$50 000</span>
                  <span className="Total">$80 000</span>
                  </div>
              </div>

              </div>
          </div>

          <div className="col-md-4">
              <div className="DashboardStats-BOX">
              <div className="BOX-Header">
                  <h4 className="BOX-Title">Consultants</h4>
                  <div className="StartupsBOX-List">
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb ShowMore" data-toggle="modal" data-target="#ModalConsultants">+5</div>
                  </div>
              </div>
              <div className="BOX-Content">
                  <div className="BOX-Side-1">
                  <div className="BOX-Side-Content">
                      <div className="BOX-Side-Icon"><i className="uil uil-briefcase-alt"></i></div>
                      <span>40</span>
                      <label>Missions effectuées</label>
                  </div>
                  </div>
              </div>
              <div className="BOX-Footer">
                  <div className="BOX-Side-2">
                  <label className="BOX-Label">Dépenses</label>
                  </div>
                  <div className="BOX-Side-2">
                  <span className="Investi">$4000</span>
                  <span className="Total">$10 000</span>
                  </div>
              </div>
              </div>
          </div>

          <div className="col-md-4">
              <div className="Purple-BOX DashboardStats-BOX">
              <div className="BOX-Header">
                  <h4 className="BOX-Title">Pilotes / Bailleurs</h4>
                  <div className="StartupsBOX-List">
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb"></div>
                  <div className="StartupBOX-Thumb ShowMore" data-toggle="modal" data-target="#ModalManagers">+5</div>
                  </div>
              </div>
              <div className="BOX-Content">
                  <div className="BOX-Side-1">
                  <div className="BOX-Side-Content">
                      <label>Budget prévu</label>
                      <span>$200 000</span>
                  </div>
                  </div>
                  <div className="BOX-Side-1">
                  <div className="BOX-Side-Content">
                      <label>Budget investi</label>
                      <span>$120 000</span>
                  </div>
                  </div>
              </div>

              </div>
          </div>

          </div>
      </div>
      </div>
      <div className="modal fade" id="ModalStartups" tabIndex="-1" role="dialog" aria-labelledby="ModalStartupsTitle" aria-hidden="true">
        <div className="ModalUsers-Dialog modal-dialog modal-dialog-centered" role="document">
          <div className="ModalUsers-Content modal-content">
            <div className="modal-body">
              <div className="ModelUsers-Header">
                <h4>Startups</h4>
                <button type="button" className="Close-BTN" data-dismiss="modal"><i className="uil uil-multiply"></i></button>
              </div>
              <div className="table-responsive">
                <table id="Startups-Table" className="table table-striped table-bordered dt-responsive nowrap" style={{ width: "100%"  }}>
                  <thead>
                    <tr>
                        <th scope="col">Startup</th>
                        <th scope="col">Activité</th>
                        <th scope="col">Secteur d’activité</th>
                        <th scope="col">Adresse e-mail</th>
                        <th scope="col">Téléphone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {startups.map((startup, index) => (
                      <tr key={startup.Id} id={`row_${startup.Id}`} role="row" className={ index % 2 === 0 ? "odd" : "even"}>
                        <td className="sorting_1">
                          <div className="D-RowUserInfo"><span className="MembersList-RowUserInfoThumb"></span>
                            <a href={"/startup/" + startup.Id} data-toggle="tooltip" data-placement="bottom" title="Voir le profile de la startup">{startup.Nom}</a>
                          </div>                        
                        </td>
                        <td>{startup.Nom}</td>
                        <td>{startup.Activite}</td>
                        <td> {startup.Email}</td>
                        <td> {startup.Telephone}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                        <th scope="col">StartUp</th>
                        <th scope="col">Activité</th>
                        <th scope="col">Secteur d’activité</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Phone</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="ModalManagers" tabIndex="-1" role="dialog" aria-labelledby="ModalManagersTitle" aria-hidden="true">
        <div className="ModalUsers-Dialog modal-dialog modal-dialog-centered" role="document">
          <div className="ModalUsers-Content modal-content">
            <div className="modal-body">
              <div className="ModelUsers-Header">
                <h4>Pilotes / Bailleurs</h4>
                <button type="button" className="Close-BTN" data-dismiss="modal"><i className="uil uil-multiply"></i></button>
              </div>
              <div className="table-responsive">

                <table id="Pilotes-Bailleurs-Table" className="table table-striped table-bordered dt-responsive nowrap" style={{ width: "100%"  }}>
                    <thead>
                    <tr>
                        <th scope="col">StartUp</th>
                        <th scope="col">Activité</th>
                        <th scope="col">Secteur d’activité</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Phone</th>
                    </tr>
                    </thead>
                    <tbody>
                      {pilotes.map((startup, index) => (
                        <tr key={startup.Id} id={`row_${startup.Id}`} role="row" className={ index % 2 === 0 ? "odd" : "even"}>
                          <td className="sorting_1">
                            <div className="D-RowUserInfo"><span className="MembersList-RowUserInfoThumb"></span>
                              <a href={"/bailleur/" + startup.Id} data-toggle="tooltip" data-placement="bottom" title="Voir le profile de la startup">{startup.Nom}</a>
                            </div>                        
                          </td>
                          <td>{startup.Nom}</td>
                          <td>{startup.Activite}</td>
                          <td> {startup.Email}</td>
                          <td> {startup.Telephone}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th scope="col">StartUp</th>
                        <th scope="col">Activité</th>
                        <th scope="col">Secteur d’activité</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Phone</th>
                    </tr>
                    </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="ModalConsultants" tabIndex="-1" role="dialog" aria-labelledby="ModalConsultantsTitle" aria-hidden="true">
        <div className="ModalUsers-Dialog modal-dialog modal-dialog-centered" role="document">
          <div className="ModalUsers-Content modal-content">
            <div className="modal-body">
              <div className="ModelUsers-Header">
                <h4>Consultants</h4>
                <button type="button" className="Close-BTN" data-dismiss="modal"><i className="uil uil-multiply"></i></button>
              </div>
              <div className="table-responsive">
                <table id="Consultants-Table" className="table table-striped table-bordered dt-responsive nowrap" style={{ width: "100%"  }}>
                    <thead>
                    <tr>
                        <th scope="col">StartUp</th>
                        <th scope="col">Activité</th>
                        <th scope="col">Secteur d’activité</th>
                        <th scope="col">Adresse e-mail</th>
                        <th scope="col">Telephone</th>
                    </tr>
                    </thead>
                    <tbody>
                      {consultants.map((startup, index) => (
                        <tr key={startup.Id} id={`row_${startup.Id}`} role="row" className={ index % 2 === 0 ? "odd" : "even"}>
                          <td className="sorting_1">
                            <div className="D-RowUserInfo"><span className="MembersList-RowUserInfoThumb"></span>
                              <a href={"/consultant/" + startup.Id} data-toggle="tooltip" data-placement="bottom" title="Voir le profile de la startup">{startup.Nom}</a>
                            </div>                        
                          </td>
                          <td>{startup.Nom}</td>
                          <td>{startup.Activite}</td>
                          <td> {startup.Email}</td>
                          <td> {startup.Telephone}</td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th scope="col">StartUp</th>
                        <th scope="col">Activité</th>
                        <th scope="col">Secteur d’activité</th>
                        <th scope="col">Email Address</th>
                        <th scope="col">Phone</th>
                    </tr>
                    </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}


export default Dashboard;