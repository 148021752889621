import React, {useContext, useEffect} from "react";
import {UserContext, ProgrammeContext } from '../../contexts/UserContext';
import { Image, LogoutButton } from "../../modules/components";
const digitalOceanStorageUrl = "https://storage.digireporting.app/"

const HeaderTop = () => {
  
  const [user] = useContext(UserContext);
  const [projet] = useContext(ProgrammeContext);

  // const name = user ? `${user.nom} ${user.prenom}`: ``;
  // const profileImg = user.profile ? `` : `/assets/media/images/default_pic.jpg`;
  // const programme = projet ? `${projet?.programme?.nom} - ${projet?.role?.libelle}` : ``;

  // useEffect(() => {
  //   if(!!user) {
  //     console.log('USER ', user);
  //     console.log('JSON USER ', JSON.stringify(user));
  //   }
  // }, [user])
  
  return (
    <>
    {/* CONTENT SIDE */}
    {/* { user && projet && */}
      <div className="HeaderTop">
        <div className="HeaderLeft-Side">

        </div>
        <div className="HeaderRight-Side">
          <div className="UserBox">
            <div id="User-DropDown">
              <div className="UserBox-Infos">
                <label>{user?.nom} {user?.prenom}</label>
                <span>{projet ? `${projet?.programme?.nom} - ${projet?.role?.libelle}` : ``} </span>
              </div>
              <div className="UserBox-Thumb">
                <Image fileName={`${digitalOceanStorageUrl}${user?.profile || "profile.jpeg"}`} className="thumbnail-profile" />  
              </div>
              
      
              
            </div>
            <div className="User-DropDownP">
              <ul>
                <li><a href="/profile"><i className="uil uil-user-md"></i> <span>Mon Profil</span></a></li>
                <li><LogoutButton hasIcon={true} /></li>
              </ul>
            </div>
          </div>
          <div className="AlertBox">
            <button className="AlertBox-BTN" type="button" name="button" data-toggle="tooltip" data-placement="bottom" title="Notifications"><i className="uil uil-bell"></i></button>
            <div className="NotificationsContainer">
              <div className="NotificationsContent-BOX">
                <h4>Notifications</h4>
                <div className="NotificationsItems">
                  <div className="NotificationItem">
                    <div className="NotificationItem-Thumb"></div>
                    <div className="NotificationItem-Content">
                      <span className="NotificationItem-Text">Notifcation text goes here...</span>
                      <span className="NotificationItem-Time">5 min ago</span>
                    </div>
                  </div>
                  <div className="NotificationItem">
                    <div className="NotificationItem-Thumb"></div>
                    <div className="NotificationItem-Content">
                      <span className="NotificationItem-Text">Notifcation text goes here...</span>
                      <span className="NotificationItem-Time">5 min ago</span>
                    </div>
                  </div>
                  <div className="NotificationItem">
                    <div className="NotificationItem-Thumb"></div>
                    <div className="NotificationItem-Content">
                      <span className="NotificationItem-Text">Notifcation text goes here...</span>
                      <span className="NotificationItem-Time">5 min ago</span>
                    </div>
                  </div>
                  <div className="NotificationItem">
                    <div className="NotificationItem-Thumb"></div>
                    <div className="NotificationItem-Content">
                      <span className="NotificationItem-Text">Notifcation text goes here...</span>
                      <span className="NotificationItem-Time">5 min ago</span>
                    </div>
                  </div>
                  <div className="NotificationItem">
                    <div className="NotificationItem-Thumb"></div>
                    <div className="NotificationItem-Content">
                      <span className="NotificationItem-Text">Notifcation text goes here...</span>
                      <span className="NotificationItem-Time">5 min ago</span>
                    </div>
                  </div>
                </div>
                <a className="Notifications-ShowMore" href="/flux">Afficher tout</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/* } */}
  </>
  )
}

export default HeaderTop
