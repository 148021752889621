import React from "react";
import { Datatable } from "../../modules/components";

class Operation extends React.Component
{
  constructor(props) {
    super(props);
    this.state = { 
      isMount: false,
      operations: [
        {
            date:  "01.01.2020",

            type: "(60±603) Achats - Coûts des produits vendus",
            biens: "Riz,poulet,legume",
            quantite: "10kg,5p,3kg",
            montant: "150"
        },
        {
            date:  "04.01.2020",

            type: "(60±603) Achats - Coûts des produits vendus",
            biens: "manioc, thon ",
            quantite: "1kg",
            montant: "90"
        },
        {
            date:  "07.01.2020",

            type: "(70±71) Ventes - Chiffre d&apos;affaires",
            biens: "Tacos ",
            quantite: "150",
            montant: "6 000 "
        },
        {
            date:  "10.01.2020",

            type: "(60±603) Achats - Coûts des produits vendus",
            biens: "Banane platin ",
            quantite: "30kg",
            montant: "2 500 "
        },
        {
            date:  "13.01.2020",

            type: "(60±603) Achats - Coûts des produits vendus",
            biens: "Poisson ",
            quantite: "12kg",
            montant: "360"
        },
        {
            date:  "16.01.2020",

            type: "(70±71) Ventes - Chiffre d&apos;affaires",
            biens: "Alloko poisson ",
            quantite: "100 plats",
            montant: "7 000 "
        },
        {
            date:  "19.01.2020",

            type: "(60±603) Achats - Coûts des produits vendus",
            biens: "Thièpe ",
            quantite: "30",
            montant: "1 800 "
        },
        {
            date:  "22.01.2020",

            type: "(61+62) Autres charges externes",
            biens: "Locations",
            quantite: "1",
            montant: "1 500 "
        },
        {
            date:  "25.01.2020",

            type: "(61+62) Autres charges externes",
            biens: "Assurance",
            quantite: "1",
            montant: "200"
        },
        {
            date:  "28.01.2020",

            type: "(61+62) Autres charges externes",
            biens: "Publicité",
            quantite: "1",
            montant: "150"
        },
        {
            date:  "31.01.2020",

            type: "(74) Subventions d&apos;exploitation",
            biens: "Aide de l&apos;état",
            quantite: "1",
            montant: "800"
        },
        {
            date:  "03.02.2020",

            type: "(63) Impôts, taxes et versements assimilés",
            biens: "Impots du trimestre",
            quantite: "1",
            montant: "300"
        },
        {
            date:  "06.02.2020",

            type: "(64) Charges de personnel",
            biens: "Salaires de l&apos;équipe",
            quantite: "1",
            montant: "1 000 "
        },
        {
            date:  "09.02.2020",

            type: "(75 sauf 755) Autres produits de gestion courante",
            biens: "Droits d&apos;auteur",
            quantite: "1",
            montant: "3 000 "
        },
        {
            date:  "12.02.2020",

            type: "(781) Reprises sur amortissements, dépréciations et provisions",
            biens: "",
            quantite: "1",
            montant: ""
        },
        {
            date:  "15.02.2020",

            type: "(791) Transferts de charges d&apos;exploitation",
            biens: "",
            quantite: "1",
            montant: ""
        },
        {
            date:  "18.02.2020",

            type: "(65 sauf 655) Autres charges de gestion courante",
            biens: "Pertes sur créances clients",
            quantite: "1",
            montant: "800"
        },
        {
            date:  "21.02.2020",

            type: "(681) Dotations aux amortissements, dépréciations et provisions",
            biens: "",
            quantite: "1",
            montant: ""
        },
        {
            date:  "24.02.2020",

            type: "(755) Quote-part de résultat sur opérations faites en commun",
            biens: "Coorganisation de buffet",
            quantite: "1",
            montant: "450"
        },
        {
            date:  "27.02.2020",

            type: "(76) Produits financiers",
            biens: "Gain de change",
            quantite: "1",
            montant: "350"
        },
        {
            date:  "01.03.2020",

            type: "(786) Reprises sur dépréciations et provisions",
            biens: "",
            quantite: "1",
            montant: ""
        },
        {
            date:  "04.03.2020",

            type: "(796) Transferts de charges financières",
            biens: "",
            quantite: "1",
            montant: ""
        },
        {
            date:  "07.03.2020",

            type: "(66) Charges financières",
            biens: "Perte de change",
            quantite: "1",
            montant: "250"
        },
        {
            date:  "10.03.2020",

            type: "(686) Dotations aux amortissements, dépréciations et provisions",
            biens: "",
            quantite: "1",
            montant: ""
        },
        {
            date:  "13.03.2020",

            type: "(77) Produits exceptionnels",
            biens: "Vente de matériel de cuisine",
            quantite: "1",
            montant: "1 500 "
        },
        {
            date:  "16.03.2020",

            type: "(787) Reprises sur dépréciations et provisions",
            biens: "",
            quantite: "1",
            montant: ""
        },
        {
            date:  "19.03.2020",

            type: "(797) Transferts de charges exceptionnelles",
            biens: "",
            quantite: "1",
            montant: ""
        },
        {
            date:  "22.03.2020",

            type: "(67) Charges exceptionnelles",
            biens: "Amendes et pénalités",
            quantite: "1",
            montant: "670"
        },
        {
            date:  "25.03.2020",

            type: "(687) Dotations aux amortissements, dépréciations et provisions",
            biens: "",
            quantite: "1",
            montant: ""
        },
        {
            date:  "28.03.2020",

            type: "",
            biens: "",
            quantite: "1",
            montant: ""
        },
        {
            date:  "31.03.2020",

            biens: "",
            quantite: "1",
            montant: ""
        }
      ],
      columns: [
        {
          name: "Date",
          selector: "date",
          sortable: true
        },
        {
          name: "Type",
          selector: "type",
          sortable: true
        },
        {
          name: "Biens",
          selector: "biens",
          sortable: true
        },
        {
          name: "Qté",
          selector: "quantite",
          sortable: true,
          right: true
        },
        {
          name: "Montant",
          selector: "montant",
          sortable: true,
          right: true
        }
      ]
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){    
    const { operations } = this.state;
    console.log(operations);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    alert('Le nom a été soumis : ' + this.state.value);
    event.preventDefault();
  }

  render() {
    const { operations, columns } = this.state;
    return (
      <>
        <div className="TableWrap">
          <div className="table-responsive">
            <Datatable rows={operations} columns={columns} />
          </div>
        </div>

        <div className="modal fade" id="AddNewElement" tabIndex="-1" role="dialog" aria-labelledby="AddNewElementTitle" aria-hidden="true">
          <div className="DigiReprting-Modal modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">

              <div className="modal-body">
                <div className="SettingCol-Header">
                  <h4 className="SettingCol-HeaderTitle">Nouveau Éléments</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i className="uil uil-times"></i>
                  </button>
                </div>

                <div className="SettingCol-Row">
                  <form onSubmit={this.handleSubmit}>
                    <label>
                      Nom :
                      <input type="text" value={this.state.value} onChange={this.handleChange} />
                    </label>
                    <input type="submit" value="Envoyer" />
                  
                    <div className="Settings-Field Digi-Col-12">
                      <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                        <input type="text" className="FieldText" placeholder="Nom" name="Nom" onChange={this.handleChange}/>
                      </div>
                    </div>
                    <div className="Settings-Field Digi-Col-12">
                      <div className="Settings-FieldRow Settings-FieldRow_Select LightBG">
                        <select className="FieldRow-Select" defaultValue={"DEFAULT"} name="" onChange={this.handleChange}>
                          <option value="DEFAULT" disabled>Type</option>
                          <option value="Produit">Produit</option>
                          <option value="Service">Service</option>
                        </select>
                      </div>
                    </div>
                    <div className="Settings-Field Digi-Col-6">
                      <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                        <input type="text" className="FieldText" placeholder="Prix d’achat" name="periode" onChange={this.handleChange}/>
                      </div>
                    </div>
                    <div className="Settings-Field Digi-Col-6">
                      <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                        <input type="text" className="FieldText" placeholder="Prix de vente" name="periode" onChange={this.handleChange}/>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <div className="ButtonCancel"><button type="button" className="Cancel-BTN" data-dismiss="modal">Annuler</button></div>
                <div className="ButtonSave"><button type="submit" className="Save-BTN">Enregistrer</button></div>
              </div>
            </div>
          </div>
        </div>

        
        <div className="modal fade" id="EditElement" tabIndex="-1" role="dialog" aria-labelledby="EditElementTitle" aria-hidden="true">
          <div className="DigiReprting-Modal modal-dialog modal-dialog-centered" role="document">
            {/* <div className="modal-content">

              <div className="modal-body">
                <div className="SettingCol-Header">
                  <h4 className="SettingCol-HeaderTitle">Nouveau Éléments</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <i className="uil uil-times"></i>
                  </button>
                </div>

                <div className="SettingCol-Row">
                  <div className="Settings-Field Digi-Col-12">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Nom" name="Nom" value=""/>
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-12">
                    <div className="Settings-FieldRow Settings-FieldRow_Select LightBG">
                      <select className="FieldRow-Select" name="">
                        <option selected disabled>Type</option>
                        <option value="Produit">Produit</option>
                        <option value="Service">Service</option>
                      </select>
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Prix d’achat" name="periode" value=""/>
                    </div>
                  </div>
                  <div className="Settings-Field Digi-Col-6">
                    <div className="Settings-FieldRow Settings-FieldRow_Text LightBG">
                      <input type="text" className="FieldText" placeholder="Prix de vente" name="periode" value=""/>
                    </div>
                  </div>

                </div>
              </div>
              <div className="modal-footer">
                <div className="ButtonCancel"><button type="button" className="Cancel-BTN" data-dismiss="modal">Annuler</button></div>
                <div className="ButtonSave"><button type="button" className="Save-BTN">Update</button></div>
              </div>
            </div> */}
          </div>
        </div>


      </>
    )
  }
}

export default Operation;