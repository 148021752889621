import React from 'react';
// import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import {Routes} from './Routes'
import { UserProvider } from './contexts/UserContext';
// import { Provider } from "react-redux";
export default function App() {
  return (
    /* Provide Redux store */
    <BrowserRouter>
      <UserProvider>
        <Routes />
      </UserProvider>
    </BrowserRouter>
  );
}
