import React from 'react'
import {Pie} from 'react-chartjs-2';

export function PieChart({
  title='type de missions',
  labels=['January', 'February', 'March', 'April', 'May'],
  backgroundColor= [
    'rgba(85, 55, 102, 1)',
    'rgba(231, 53, 125, 1)',
    'rgba(236, 105, 41, 1)',
    'rgba(247, 169, 49, 1)',
    '#6800B4'
  ],
  hoverBackgroundColor= [
    '#501800',
    '#4B5000',
    '#175000',
    '#003350',
    '#35014F'
  ],
  data= [65, 59, 80, 81, 56],
  height=630,
  width=600
}) {

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Rainfall',
        backgroundColor,
        hoverBackgroundColor,
        data: data
      }
    ]
  }

  return (
    <>
      {
        data ?
        <Pie
          height={height}
          width={width}
          id="ReportPie"
          data={chartData}
          options={{
            responsive: true,
            title:{
              display:true,
              text:title,
              fontSize:20
            },
            legend:{
              display:true,
              position:'right',
            },
            tooltip: {
              enabled:false,
              position:'left',
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || '';

                  if (label) {
                      label += ': ';
                  }
                  return label;
                }
              }
            },
          }}
        />
        : null
      }
    </>
  );
}

